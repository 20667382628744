import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SingleCardFace from './SingleCardFace';

function UploadedCardImage() {
  const { studentIdTracker } = useSelector((state) => state.service);
  return (
    <>
      <Card.Header className="py-2 px-3 bg-primary mb-2">
        <span className="font500 text-uppercase text-white">
          UPLOADED ID CARD IMAGES
        </span>
      </Card.Header>
      <Row
        className=" g-2 justify-content-center align-items-center mt-2"
        style={{ minHeight: 210 }}
      >
        <Col md={6}>
          <SingleCardFace src={studentIdTracker?.id_card_front} />
        </Col>
        <Col md={6}>
          <SingleCardFace usage="back" src={studentIdTracker?.id_card_back} />
        </Col>
      </Row>
    </>
  );
}

export default UploadedCardImage;
