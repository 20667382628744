import { map, parseInt } from 'lodash';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { InputText } from '../../components/common';

function FinanceClearanceDetails() {
  const { financeClearance } = useSelector((state) => state.myTransaction);

  const noValue = '---';
  const formatNumber = (value) => `${parseInt(value, 10).toLocaleString()} UGX`;

  const dataTemplate = {
    templateA: [
      {
        label: 'DEBIT NOTES',
        defaultValue: formatNumber(financeClearance?.debit_notes) || noValue,
      },
      {
        label: 'CREDIT NOTES',
        defaultValue: formatNumber(financeClearance?.credit_notes) || noValue,
      },
      {
        label: 'OTHER BILL',
        defaultValue:
          formatNumber(financeClearance?.manual_and_other_bill) || noValue,
      },
      {
        label: 'FEES BILL',
        defaultValue:
          formatNumber(financeClearance?.fees_structure_bill) || noValue,
      },
      {
        label: 'GRADUATION BILL',
        defaultValue:
          formatNumber(financeClearance?.graduation_bill) || noValue,
      },
      {
        label: 'GRADUATION PAYMENTS',
        defaultValue:
          formatNumber(financeClearance?.graduation_payments) || noValue,
      },
    ],
    templateB: [
      {
        label: 'CURRENT PAYMENTS',
        defaultValue:
          formatNumber(financeClearance?.current_payments) || noValue,
      },
      {
        label: 'PREVIOUS PAYMENTS',
        defaultValue:
          formatNumber(financeClearance?.previous_payments) || noValue,
      },
      {
        label: 'AMOUNT DUE',
        defaultValue: formatNumber(financeClearance?.amount_due) || noValue,
      },
      {
        label: 'AMOUNT PAID',
        defaultValue: formatNumber(financeClearance?.amount_paid) || noValue,
      },
      {
        label: 'AMOUNT BILLED',
        defaultValue: formatNumber(financeClearance?.amount_billed) || noValue,
      },
    ],
  };
  return (
    <div>
      <Card.Header className="my-1 px- bg-primary text-white fw-bold border-0">
        FINANCE CLEARANCE DETAILS
      </Card.Header>
      <Card
        className="mt-1 p-2"
        style={{ border: 'dashed 1px var(--bs-primary)' }}
      >
        <Row>
          <Col md={6}>
            {map(dataTemplate?.templateA, (item) => (
              <InputText
                key={item.label}
                label={item.label}
                defaultValue={item.defaultValue}
                disabled
                inline
              />
            ))}
          </Col>
          <Col md={6}>
            {map(dataTemplate?.templateB, (item) => (
              <InputText
                key={item.label}
                label={item.label}
                defaultValue={item.defaultValue}
                disabled
                inline
              />
            ))}
            {financeClearance?.is_administrative && (
              <InputText
                name="reason"
                label="REASON"
                type="textarea"
                defaultValue={financeClearance?.reason}
                disabled
                inline
                rows={2}
              />
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default FinanceClearanceDetails;
