import React from 'react';
import PropTypes, { object } from 'prop-types';
import { Button } from 'antd';

function SubmitButton({
  loading,
  disabled,
  text,
  loadingText,
  iconBefore,
  iconAfter,
  block,
  className,
  variant,
  danger,
  ...props
}) {
  return (
    <Button
      htmlType="submit"
      disabled={disabled}
      type="primary"
      block={block}
      loading={loading}
      danger={props.danger}
      size="middle"
      className={`text-uppercase fw-bold text-sm ${className}`}
      icon={iconBefore}
      {...props}
    >
      {loading ? loadingText : text}
      {!loading ? iconAfter : null}
    </Button>
  );
}

SubmitButton.defaultProps = {
  loading: false,
  disabled: false,
  danger: false,
  block: false,
  text: 'Save',
  loadingText: 'Saving...',
  iconBefore: null,
  iconAfter: null,
  variant: null,
  className: '',
};

SubmitButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  danger: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  loadingText: PropTypes.string,
  variant: PropTypes.string,
  iconBefore: PropTypes.oneOfType([object]),
  iconAfter: PropTypes.oneOfType([object]),
};

export default SubmitButton;
