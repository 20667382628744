/* eslint-disable jsx-a11y/control-has-associated-label */
import { Image } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React, { useCallback, useRef } from 'react';
import {
  Button,
  Card,
  Col,
  ModalBody,
  ModalFooter,
  Row,
  Table,
} from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import DefaultLogo from '../../../assets/img/default.png';
import { CustomModal } from '../../../components/common';
import { formatName, whichInstitution } from '../../../helpers/dataFormatter';

function PaymentReceiptModal({ selectedReceipt, showModal, setShowModal }) {
  const { institutionStructure } = useSelector((state) => state.app);
  const { authUser, currentStudentProgramme } = useSelector(
    (state) => state.auth
  );
  const isMubs = whichInstitution('MUBS', institutionStructure);
  const closeModal = () => setShowModal(false);
  const componentRef = useRef();

  const actionButton = useCallback(
    () => (
      <Button type="primary" size="sm" className="font600 text-sm">
        <FaPrint className="me-1 " />
        Print Receipt
      </Button>
    ),
    []
  );

  return (
    <CustomModal
      show={showModal}
      size="xl"
      title="STUDENT PAYMENT RECEIPT"
      onCloseModal={closeModal}
      backdrop="static"
      keyboard={false}
    >
      <ModalBody ref={componentRef} className="px-4">
        <>
          <Card className="mb-4 border-0">
            <Card.Body className="p-0">
              <Row className="my-2">
                <Col xs={4}>
                  <Card.Title
                    className="text-left fs-1 p-2 "
                    style={{ fontSize: '50px !important' }}
                  >
                    RECEIPT
                  </Card.Title>
                  <div className="text-muted h6 text-sm ms-2 text-nowrap">
                    Date:
                    <span className="ms-2 text-muted  text-sm">
                      {moment(selectedReceipt?.created_at).format('LLLL')}
                    </span>
                  </div>
                </Col>
                <Col
                  xs={4}
                  className="d-flex align-self-center justify-content-end px-5"
                >
                  <Image
                    src={`${process.env.REACT_APP_INSTITUTION_LOGO_PATH}/${institutionStructure?.institution_logo}`}
                    fallback={DefaultLogo}
                    alt="University Logo"
                    height={100}
                    width={100}
                  />
                </Col>
                <Col xs={4}>
                  <div className="block align-left mt-2 font600 fs-2 h1 text-uppercase">
                    {institutionStructure?.institution_name ||
                      process.env.REACT_APP_NAME}
                  </div>
                  <h5 className="fs-4 font600">OFFICE OF THE BURSAR</h5>
                  <h6 className="text-xl font600">STUDENT PAYMENT RECEIPT</h6>
                </Col>
              </Row>

              <Row className="my-4">
                <Col xs={6}>
                  <Card.Title className="text-center m-0 p-4">
                    <span>TOTAL AMOUNT PAID:</span>
                    <span className="ms-2 fw-bold">{`${parseInt(
                      selectedReceipt?.amount || '',
                      10
                    ).toLocaleString()} ${
                      selectedReceipt?.currency || ''
                    }`}</span>
                  </Card.Title>
                </Col>
                <Col xs={6}>
                  <Card.Title className="text-center fs-5 m-0 p-4">
                    <span>PAYMENT DATE:</span>
                    <span className="ms-2 fw-bold">{`${
                      selectedReceipt?.payment_date
                        ? moment(selectedReceipt?.payment_date).format(
                            'Do MMM YYYY'
                          )
                        : '-- -- ----'
                    }`}</span>
                  </Card.Title>
                </Col>
              </Row>

              <Row>
                <Col xs={6}>
                  <Card.Header className="text-center fw-bolder border-0 fs-5 mt-5">
                    CLIENT DETAILS
                  </Card.Header>
                  <Card.Body>
                    <Table hover size="xs" className="m-0 table-borderless">
                      <tbody>
                        <tr>
                          <td className="fs-5">Full Name</td>
                          <td className="fs-5">
                            {selectedReceipt?.full_name || ''}
                          </td>
                        </tr>
                        <tr>
                          <td className="fs-5">Student Number</td>
                          <td className="fs-5">
                            {isMubs &&
                            !isEmpty(
                              currentStudentProgramme?.old_student_number
                            )
                              ? currentStudentProgramme?.old_student_number
                              : currentStudentProgramme?.student_number}
                          </td>
                        </tr>

                        <tr>
                          <td className="fs-5">Email</td>
                          <td className="fs-5">
                            {selectedReceipt?.email || ''}
                          </td>
                        </tr>
                        <tr>
                          <td className="fs-5">Phone</td>
                          <td className="fs-5">
                            {selectedReceipt?.phone_number || ''}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Col>
                <Col>
                  <Card.Header className="text-center fw-bolder border-0 fs-5 mt-5">
                    Description
                  </Card.Header>
                  <Card.Title className=" fs-5 m-0 p-4 text-uppercase">
                    {selectedReceipt?.usage
                      ? selectedReceipt.usage
                      : 'STUDENT PAYMENT'}
                  </Card.Title>
                </Col>
              </Row>
            </Card.Body>
            <Card.Header className="text-center fw-bolder border-0 fs-5 mt-5">
              PAYMENT DETAILS
            </Card.Header>
            <Card.Body>
              <Table hover size="md" responsive className="m-0 border-0">
                <thead>
                  <th className="fs-5">Invoice Date</th>
                  <th className="fs-5">Invoice Number</th>
                  <th className="fs-5">Payment Reference</th>
                  <th className="fs-5">Invoice Amount</th>
                  <th className="fs-5">Amount Paid</th>
                  <th className="fs-5">Amount Due</th>
                </thead>
                <tbody>
                  <tr className="border-bottom">
                    <td className="fs-5">
                      {selectedReceipt?.created_at
                        ? `${moment(selectedReceipt?.created_at || '').format(
                            'Do MMM YYYY'
                          )}`
                        : '---'}
                    </td>
                    <td className="fs-5">
                      {selectedReceipt?.invoice_number || '----'}
                    </td>
                    <td>
                      <p className="fs-5">{`${
                        selectedReceipt?.ura_prn || 'NO REFERENCE GENERATED'
                      }`}</p>
                      {selectedReceipt?.usage ? (
                        ''
                      ) : (
                        <p className="fs-5">{`${
                          selectedReceipt?.bank || ''
                        } | ${selectedReceipt?.branch || ''}`}</p>
                      )}
                    </td>

                    <td className="fs-5">
                      {selectedReceipt?.amount_billed
                        ? `${parseInt(
                            selectedReceipt?.amount_billed,
                            10
                          ).toLocaleString()} ${
                            selectedReceipt?.currency || ''
                          }`
                        : '----'}
                    </td>
                    <td className="fs-5">{`${parseInt(
                      selectedReceipt?.amount,
                      10
                    ).toLocaleString()} ${
                      selectedReceipt?.currency || ''
                    }`}</td>
                    <td className="fs-5">
                      {selectedReceipt?.amount_billed
                        ? `${(
                            parseInt(selectedReceipt?.amount_billed, 10) -
                            parseInt(selectedReceipt?.amount, 10)
                          ).toLocaleString()} ${
                            selectedReceipt?.currency || ''
                          }`
                        : '----'}
                    </td>
                  </tr>
                  <tr className="fw-bold mt-5">
                    <td colSpan={3} className="border-0" />
                    <td className="fs-4 pt-5">TOTAL</td>
                    <td className="fs-4 pt-5">{`${parseInt(
                      selectedReceipt.amount,
                      10
                    ).toLocaleString()} ${
                      selectedReceipt?.currency || ''
                    }`}</td>
                    <td className="fs-4 pt-5">
                      {selectedReceipt?.amount_billed
                        ? `${(
                            parseInt(selectedReceipt?.amount_billed || 0, 10) -
                            parseInt(selectedReceipt?.amount || 0, 10)
                          ).toLocaleString()} ${
                            selectedReceipt?.currency || ''
                          }`
                        : '----'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
            {selectedReceipt?.usage && (
              <>
                <Card.Header className="text-center fw-bolder border-0 fs-5 mt-5">
                  BILLED ELEMENTS
                </Card.Header>
                <Card.Body>
                  <Table hover size="md" className="m-0">
                    <thead>
                      <tr>
                        <th className="fs-5">#</th>
                        <th className="fs-5">Code</th>
                        <th className="fs-5">Element</th>
                        <th className="fs-5">Currency</th>
                        <th className="fs-5">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedReceipt?.elements?.map((element, index) => (
                        <tr key={element.id}>
                          <td className="border-0">{index + 1}</td>
                          <td className="fs-5 border-0">
                            {element?.fees_element_code || '--------'}
                          </td>
                          <td className="fs-5 border-0">
                            {element?.fees_element_name || '--------'}
                          </td>
                          <td className="fs-5 border-0">
                            {element?.currency || '---'}
                          </td>
                          <td width={250} className="fs-5 border-0">
                            {`${parseInt(
                              element?.amount,
                              10
                            ).toLocaleString()}`}
                          </td>
                        </tr>
                      ))}
                      <tr className="fw-bold mt-5 ">
                        <td colSpan={3} className="border-0 fw-bold" />
                        <td
                          style={{ borderBottom: '1px solid #000000' }}
                          className="fs-5 pt-4"
                        >
                          TOTAL AMOUNT BILLED
                        </td>
                        <td
                          style={{ borderBottom: '1px solid #000000' }}
                          className="fs-5 pt-4"
                        >
                          {`${parseInt(
                            selectedReceipt?.amount_billed,
                            10
                          ).toLocaleString()} ${
                            selectedReceipt?.currency || ''
                          }`}
                        </td>
                      </tr>
                      <tr className="fw-bold mt-5">
                        <td colSpan={3} className="border-0" />
                        <td
                          style={{ borderBottom: '2px solid #000000' }}
                          className="fs-4 pt-5 "
                        >
                          AMOUNT PAID
                        </td>
                        <td
                          style={{ borderBottom: '2px solid #000000' }}
                          className="fs-4 pt-5"
                        >
                          {`${parseInt(
                            selectedReceipt?.amount,
                            10
                          ).toLocaleString()} ${
                            selectedReceipt?.currency || ''
                          }`}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </>
            )}
            <Card.Body className="text-start text-xl my-4 d-flex justify-content-end">
              <div>
                <p>{`Bursar's Signature: `}</p>
                <div className="mt-3 pt-5">
                  {`............................................................................. `}
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="text-center text-sm">
              Printed By:
              <span className="text-muted ms-1">{formatName(authUser)}</span>
            </Card.Footer>
          </Card>
        </>
      </ModalBody>
      <ModalFooter>
        <ReactToPrint
          trigger={actionButton}
          content={() => componentRef.current}
        />
      </ModalFooter>
    </CustomModal>
  );
}

PaymentReceiptModal.propTypes = {
  selectedReceipt: PropTypes.oneOfType([object]).isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default PaymentReceiptModal;
