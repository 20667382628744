import { Button, Image } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import defaultCard from '../../../assets/img/default_id_face.png';
import NoCardImage from './NoCardImage';

function SingleCardFace({ src, usage, ...props }) {
  return (
    <>
      {!isEmpty(src) && (
        <Button type="default" size="small" className="text-uppercase">
          {usage}
        </Button>
      )}

      {isEmpty(src) ? (
        <NoCardImage side={usage} />
      ) : (
        <Card className="border-0 mt-3">
          <Image
            style={{
              maxHeight: 210,
              display: 'flex',
              margin: '0 auto',
              borderRadius: 15,
            }}
            alt="student card Photo"
            fallback={defaultCard}
            preview={false}
            src={`${process.env.REACT_APP_STUDENTS_ID_IMAGE_URL}/${usage}/${src}`}
            {...props}
          />
        </Card>
      )}
    </>
  );
}

SingleCardFace.defaultProps = {
  src: null,
  usage: 'face',
};

SingleCardFace.propTypes = {
  src: PropTypes.string,
  usage: PropTypes.string,
};

export default SingleCardFace;
