import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FaReply } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { DataSpinner } from '../../../components/common';
import { serviceActions } from '../../../config/actions';
import ApplicationForm from './ApplicationForm';
import ApplicationStatus from './ApplicationStatus';

function ApplyForID({ setCurrentTab, navigateUser }) {
  const dispatch = useDispatch();
  const { pendingApplications, fetchingPendingApplications } = useSelector(
    (state) => state.service
  );
  const [currentApplication, setCurrentApplication] = useState(null);

  const getPendingApplications = () => {
    dispatch(serviceActions.getPendingApplications('id-application'));
  };

  useEffect(() => {
    getPendingApplications();
  }, []);

  useEffect(() => {
    if (pendingApplications) {
      const findService = pendingApplications.find(
        (app) => app.service === 'id-application'
      );
      setCurrentApplication(findService?.application || null);
    }
  }, [pendingApplications]);

  const handleClick = () => {
    setCurrentTab('service-dashboard');
    navigateUser();
  };

  return (
    <Card>
      <Card.Header className="py-2 d-flex justify-content-between">
        <div className="text-uppercase font600 text-primary">
          {isEmpty(currentApplication)
            ? 'APPLY FOR ID'
            : 'PENDING ID APPLICATION'}
        </div>
        <div className="card-options">
          <Button
            size="sm"
            onClick={handleClick}
            className="text-sm font600 text-uppercase"
            variant="warning"
          >
            <FaReply className="me-1" />
            Back
          </Button>
        </div>
      </Card.Header>

      <Card.Body>
        {fetchingPendingApplications ? (
          <DataSpinner />
        ) : (
          <>
            {isEmpty(currentApplication) ? (
              <ApplicationForm />
            ) : (
              <ApplicationStatus application={currentApplication} />
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

ApplyForID.propTypes = {
  setCurrentTab: PropTypes.func.isRequired,
  navigateUser: PropTypes.func.isRequired,
};

export default ApplyForID;
