import {
  LoadingOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Space } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { authActions, settingActions } from '../../config/actions';
import MenuList from '../../config/routes/MenuList';
import Avatar from '../../containers/MyProfile/Avatar';
import ToggleMenu from './ToggleMenu';

const { Header } = Layout;
const { SubMenu } = Menu;

function MainHeader() {
  const dispatch = useDispatch();
  const { shouldAllocate } = useSelector((state) => state.myInvoice);
  const { forceEnrollment } = useSelector((state) => state.enrollment);
  const { authUser, loggingOut, gettingAuthUser, activeProgramme } =
    useSelector((state) => state.auth);
  const { selectedMenu } = useSelector((state) => state.setting);
  const isMobileDevice = useMediaQuery({ maxWidth: 767 });

  const setSelectedMenu = (menu) => {
    dispatch(settingActions.setSelectedMenu(menu));
  };

  const actionButtons = [
    {
      title: 'VIEW BIO DATA',
      action: MenuList.BioData,
      id: 1,
    },
    {
      title: 'VIEW RESULTS',
      action: MenuList.MyResults,
      id: 2,
    },
    {
      title: 'VIEW INVOICES',
      action: MenuList.MyInvoice,
      id: 3,
    },
    {
      title: 'VIEW FEES STRUCTURE',
      action: MenuList.myFeesStructure,
      id: 4,
    },
    {
      title: 'Generate PRN',
      action: MenuList.PaymentReference,
      id: 5,
    },
  ];

  return (
    <>
      <Header theme="light" className="bg-white border-bottom ps-0 py-0 pe-3">
        <ToggleMenu />
        {!isMobileDevice && (
          <ButtonGroup>
            {!isEmpty(activeProgramme) &&
              actionButtons.map((button) => (
                <Button
                  size="sm"
                  variant={
                    selectedMenu.action === button.action.action
                      ? 'primary'
                      : 'outline-primary'
                  }
                  className={`${
                    button.id === 5 ? 'ms-5' : 'me-1'
                  } text-sm font500`}
                  onClick={() => setSelectedMenu(button.action)}
                  key={button.id}
                  disabled={
                    forceEnrollment ? true : button.id !== 3 && shouldAllocate
                  }
                >
                  {button.title}
                </Button>
              ))}
          </ButtonGroup>
        )}
        <Space size="middle">
          <div className="rightContainer d-inline">
            <Menu key={MenuList.BioData.action} mode="horizontal">
              <SubMenu
                title={
                  loggingOut || (gettingAuthUser && isEmpty(authUser)) ? (
                    <LoadingOutlined />
                  ) : (
                    <span className="my-auto">
                      <span className="me-2">Hi,</span>
                      <span className="text-uppercase text-primary text-sm font600 me-2">
                        {authUser.surname}
                      </span>
                      <Avatar
                        width={24}
                        height={24}
                        className="my-auto d-inline rounded-circle"
                        preview={false}
                      />
                    </span>
                  )
                }
                key="profile"
                className="px-0"
              >
                <Menu.Item
                  key="my-profile"
                  icon={<UserOutlined />}
                  onClick={() => setSelectedMenu(MenuList.BioData)}
                >
                  My Profile
                </Menu.Item>
                <Menu.Item
                  key="settings"
                  icon={<SettingOutlined />}
                  onClick={() => dispatch(settingActions.setThemeModal(true))}
                >
                  Theme Settings
                </Menu.Item>
                <Menu.Item
                  key="SignOut"
                  icon={loggingOut ? <LoadingOutlined /> : <LogoutOutlined />}
                  onClick={() => dispatch(authActions.logoutUser())}
                  className="font600"
                  danger
                >
                  Log out
                </Menu.Item>
              </SubMenu>
              <Menu.Item
                key="LogOut"
                icon={loggingOut ? <LoadingOutlined /> : <LogoutOutlined />}
                onClick={() => dispatch(authActions.logoutUser())}
                className="font600 px-2"
                danger
              >
                Log out
              </Menu.Item>
            </Menu>
          </div>
        </Space>
      </Header>
    </>
  );
}

export default MainHeader;
