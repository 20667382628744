const app = {
  GET_INSTITUTION_STRUCTURE_REQUEST: 'GET_INSTITUTION_STRUCTURE_REQUEST',
  GET_INSTITUTION_STRUCTURE_SUCCESS: 'GET_INSTITUTION_STRUCTURE_SUCCESS',
  GET_INSTITUTION_STRUCTURE_ERROR: 'GET_INSTITUTION_STRUCTURE_ERROR',

  GET_META_DATA_REQUEST: 'GET_META_DATA_REQUEST',
  GET_META_DATA_SUCCESS: 'GET_META_DATA_SUCCESS',
  GET_META_DATA_ERROR: 'GET_META_DATA_ERROR',

  GET_CURRENT_SEMESTER_REQUEST: 'GET_CURRENT_SEMESTER_REQUEST',
  GET_CURRENT_SEMESTER_SUCCESS: 'GET_CURRENT_SEMESTER_SUCCESS',
  GET_CURRENT_SEMESTER_ERROR: 'GET_CURRENT_SEMESTER_ERROR',

  getMetadata: () => ({
    type: app.GET_META_DATA_REQUEST,
  }),

  getInstitutionStructure: () => ({
    type: app.GET_INSTITUTION_STRUCTURE_REQUEST,
  }),

  getCurrentSemester: () => ({
    type: app.GET_CURRENT_SEMESTER_REQUEST,
  }),
};

export default app;
