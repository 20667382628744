const payment = {
  GET_MY_INVOICES_REQUEST: 'GET_MY_INVOICES_REQUEST',
  GET_MY_INVOICES_SUCCESS: 'GET_MY_INVOICES_SUCCESS',
  GET_MY_INVOICES_ERROR: 'GET_MY_INVOICES_ERROR',

  GET_MY_GRADUATION_INVOICES_REQUEST: 'GET_MY_GRADUATION_INVOICES_REQUEST',
  GET_MY_GRADUATION_INVOICES_SUCCESS: 'GET_MY_GRADUATION_INVOICES_SUCCESS',
  GET_MY_GRADUATION_INVOICES_ERROR: 'GET_MY_GRADUATION_INVOICES_ERROR',

  GET_MY_TRANSACTIONS_REQUEST: 'GET_MY_TRANSACTIONS_REQUEST',
  GET_MY_TRANSACTIONS_SUCCESS: 'GET_MY_TRANSACTIONS_SUCCESS',
  GET_MY_TRANSACTIONS_ERROR: 'GET_MY_TRANSACTIONS_ERROR',

  GET_PREVIOUS_TRANSACTIONS_REQUEST: 'GET_PREVIOUS_TRANSACTIONS_REQUEST',
  GET_PREVIOUS_TRANSACTIONS_SUCCESS: 'GET_PREVIOUS_TRANSACTIONS_SUCCESS',
  GET_PREVIOUS_TRANSACTIONS_ERROR: 'GET_PREVIOUS_TRANSACTIONS_ERROR',

  ALLOCATE_TRANSACTION_REQUEST: 'ALLOCATE_TRANSACTION_REQUEST',
  ALLOCATE_TRANSACTION_SUCCESS: 'ALLOCATE_TRANSACTION_SUCCESS',
  ALLOCATE_TRANSACTION_ERROR: 'ALLOCATE_TRANSACTION_ERROR',

  OFFSET_STUDENT_INVOICE_REQUEST: 'OFFSET_STUDENT_INVOICE_REQUEST',
  OFFSET_STUDENT_INVOICE_SUCCESS: 'OFFSET_STUDENT_INVOICE_SUCCESS',
  OFFSET_STUDENT_INVOICE_ERROR: 'OFFSET_STUDENT_INVOICE_ERROR',

  SEARCH_PAYMENT_REQUEST: 'SEARCH_PAYMENT_REQUEST',
  SEARCH_PAYMENT_SUCCESS: 'SEARCH_PAYMENT_SUCCESS',
  SEARCH_PAYMENT_ERROR: 'SEARCH_PAYMENT_ERROR',

  GET_MY_LEDGER_REQUEST: 'GET_MY_LEDGER_REQUEST',
  GET_MY_LEDGER_SUCCESS: 'GET_MY_LEDGER_SUCCESS',
  GET_MY_LEDGER_ERROR: 'GET_MY_LEDGER_ERROR',

  GET_FEES_STRUCTURE_REQUEST: 'GET_FEES_STRUCTURE_REQUEST',
  GET_FEES_STRUCTURE_SUCCESS: 'GET_FEES_STRUCTURE_SUCCESS',
  GET_FEES_STRUCTURE_ERROR: 'GET_FEES_STRUCTURE_ERROR',

  OFFSET_INVOICE_REQUEST: 'OFFSET_INVOICE_REQUEST',
  OFFSET_INVOICE_SUCCESS: 'OFFSET_INVOICE_SUCCESS',
  OFFSET_INVOICE_ERROR: 'OFFSET_INVOICE_ERROR',

  GENERATE_GRADUATION_INVOICE_REQUEST: 'GENERATE_GRADUATION_INVOICE_REQUEST',
  GENERATE_GRADUATION_INVOICE_SUCCESS: 'GENERATE_GRADUATION_INVOICE_SUCCESS',
  GENERATE_GRADUATION_INVOICE_ERROR: 'GENERATE_GRADUATION_INVOICE_ERROR',

  SET_INVOICE_TOTAL_DUE: 'SET_INVOICE_TOTAL_DUE',
  SET_SHOULD_ALLOCATE_FUNDS: 'SET_SHOULD_ALLOCATE_FUNDS',

  GET_MY_FINANCE_CLEARANCE_REQUEST: 'GET_MY_FINANCE_CLEARANCE_REQUEST',
  GET_MY_FINANCE_CLEARANCE_SUCCESS: 'GET_MY_FINANCE_CLEARANCE_SUCCESS',
  GET_MY_FINANCE_CLEARANCE_ERROR: 'GET_MY_FINANCE_CLEARANCE_ERROR',

  getMyInvoices: (data) => ({
    type: payment.GET_MY_INVOICES_REQUEST,
    data,
  }),

  setInvoiceTotalDue: (data) => ({
    type: payment.SET_INVOICE_TOTAL_DUE,
    data,
  }),

  setShouldAllocateFunds: (payload) => ({
    type: payment.SET_SHOULD_ALLOCATE_FUNDS,
    payload,
  }),

  getMyGraduationInvoices: (studentProgrammeId) => ({
    type: payment.GET_MY_GRADUATION_INVOICES_REQUEST,
    studentProgrammeId,
  }),

  offsetInvoice2: (invoiceId, data) => ({
    type: payment.OFFSET_STUDENT_INVOICE_REQUEST,
    invoiceId,
    data,
  }),

  getMyTransactions: (data) => ({
    type: payment.GET_MY_TRANSACTIONS_REQUEST,
    data,
  }),

  getPreviousTransactions: (category, studentProgrammeId) => ({
    type: payment.GET_PREVIOUS_TRANSACTIONS_REQUEST,
    studentProgrammeId,
    category,
  }),

  searchPayment: (prn) => ({
    type: payment.SEARCH_PAYMENT_REQUEST,
    prn,
  }),

  allocateTransactions: (transactionId, data) => ({
    type: payment.ALLOCATE_TRANSACTION_REQUEST,
    transactionId,
    data,
  }),

  getStudentLedger: (studentProgrammeId) => ({
    type: payment.GET_MY_LEDGER_REQUEST,
    studentProgrammeId,
  }),

  getFeesStructure: (studentProgrammeId) => ({
    type: payment.GET_FEES_STRUCTURE_REQUEST,
    studentProgrammeId,
  }),

  offsetInvoice: (invoiceId, data) => ({
    type: payment.OFFSET_INVOICE_REQUEST,
    invoiceId,
    data,
  }),

  generateGraduationInvoice: (studentProgrammeId) => ({
    type: payment.GENERATE_GRADUATION_INVOICE_REQUEST,
    studentProgrammeId,
  }),

  getStudentFinanceClearance: (studentProgrammeId) => ({
    type: payment.GET_MY_FINANCE_CLEARANCE_REQUEST,
    studentProgrammeId,
  }),
};

export default payment;
