const auth = {
  LOGIN_STUDENT_REQUEST: 'LOGIN_STUDENT_REQUEST',
  LOGIN_STUDENT_SUCCESS: 'LOGIN_STUDENT_SUCCESS',
  LOGIN_STUDENT_ERROR: 'LOGIN_STUDENT_ERROR',

  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_ERROR: 'LOGOUT_ERROR',

  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

  REQUEST_TOKEN_SUCCESS: 'REQUEST_TOKEN_SUCCESS',
  REQUEST_TOKEN_REQUEST: 'REQUEST_TOKEN_REQUEST',
  REQUEST_TOKEN_ERROR: 'REQUEST_TOKEN_ERROR',

  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

  GET_AUTH_USER_SUCCESS: 'GET_AUTH_USER_SUCCESS',
  GET_AUTH_USER_REQUEST: 'GET_AUTH_USER_REQUEST',
  GET_AUTH_USER_ERROR: 'GET_AUTH_USER_ERROR',

  GET_AUTH_USER_ACCOUNT_BALANCE_SUCCESS:
    'GET_AUTH_USER_ACCOUNT_BALANCE_SUCCESS',
  GET_AUTH_USER_ACCOUNT_BALANCE_REQUEST:
    'GET_AUTH_USER_ACCOUNT_BALANCE_REQUEST',
  GET_AUTH_USER_ACCOUNT_BALANCE_ERROR: 'GET_AUTH_USER_ACCOUNT_BALANCE_ERROR',

  GET_STUDENT_PROGRAMMES_SUCCESS: 'GET_STUDENT_PROGRAMMES_SUCCESS',
  GET_STUDENT_PROGRAMMES_REQUEST: 'GET_STUDENT_PROGRAMMES_REQUEST',
  GET_STUDENT_PROGRAMMES_ERROR: 'GET_STUDENT_PROGRAMMES_ERROR',

  GET_ADMISSION_LETTER_SUCCESS: 'GET_ADMISSION_LETTER_SUCCESS',
  GET_ADMISSION_LETTER_REQUEST: 'GET_ADMISSION_LETTER_REQUEST',
  GET_ADMISSION_LETTER_ERROR: 'GET_ADMISSION_LETTER_ERROR',

  DOWNLOAD_ADMISSION_LETTER_SUCCESS: 'DOWNLOAD_ADMISSION_LETTER_SUCCESS',
  DOWNLOAD_ADMISSION_LETTER_REQUEST: 'DOWNLOAD_ADMISSION_LETTER_REQUEST',
  DOWNLOAD_ADMISSION_LETTER_ERROR: 'DOWNLOAD_ADMISSION_LETTER_ERROR',

  UPDATE_STUDENT_BIODATA_REQUEST: 'UPDATE_STUDENT_BIODATA_REQUEST',
  UPDATE_STUDENT_BIODATA_SUCCESS: 'UPDATE_STUDENT_BIODATA_SUCCESS',
  UPDATE_STUDENT_BIODATA_ERROR: 'UPDATE_STUDENT_BIODATA_ERROR',

  SET_CURRENT_STUDENT_PROGRAMME: 'SET_CURRENT_STUDENT_PROGRAMME',

  SET_ACTIVE_STUDENT_PROGRAMME: 'SET_ACTIVE_STUDENT_PROGRAMME',

  SET_AUTH_USER: 'SET_AUTH_USER',
  REMOVE_AUTH_USER: 'REMOVE_AUTH_USER',
  SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',

  loginStudent: (data) => ({
    type: auth.LOGIN_STUDENT_REQUEST,
    data,
  }),

  getAuthUser: () => ({
    type: auth.GET_AUTH_USER_REQUEST,
  }),

  logoutUser: () => ({
    type: auth.LOGOUT_REQUEST,
  }),

  changePassword: (data) => ({
    type: auth.CHANGE_PASSWORD_REQUEST,
    data,
  }),

  updateBioData: (data) => ({
    type: auth.UPDATE_STUDENT_BIODATA_REQUEST,
    data,
  }),

  requestToken: (data) => ({
    type: auth.REQUEST_TOKEN_REQUEST,
    data,
  }),

  resetPassword: (data) => ({
    type: auth.RESET_PASSWORD_REQUEST,
    data,
  }),

  setCurrentStudentProgramme: (currentStudentProgramme) => ({
    type: auth.SET_CURRENT_STUDENT_PROGRAMME,
    currentStudentProgramme,
  }),

  setActiveProgramme: (activeProgramme) => ({
    type: auth.SET_ACTIVE_STUDENT_PROGRAMME,
    activeProgramme,
  }),

  getStudentProgrammes: () => ({
    type: auth.GET_STUDENT_PROGRAMMES_REQUEST,
  }),

  getAdmissionLetter: (studentNumber) => ({
    type: auth.GET_ADMISSION_LETTER_REQUEST,
    studentNumber,
  }),

  downloadAdmissionLetter: (studentNumber) => ({
    type: auth.DOWNLOAD_ADMISSION_LETTER_REQUEST,
    studentNumber,
  }),

  setAuthUser: (user) => ({
    type: auth.SET_AUTH_USER,
    user,
  }),

  setIsAuthenticated: (payload) => ({
    type: auth.SET_IS_AUTHENTICATED,
    payload,
  }),

  removeAuthUser: () => ({
    type: auth.REMOVE_AUTH_USER,
  }),
};

export default auth;
