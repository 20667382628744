import { findIndex } from 'lodash';
import update from 'immutability-helper';
import { timetableActions } from '../../actions';
import initialStates from '../../initialState';

export default (state = initialStates.teachingTimetable, action) => {
  switch (action.type) {
    case timetableActions.GET_PROGRAMME_TEACHING_TIMETABLE_REQUEST:
      return {
        ...state,
        gettingProgrammeTeachingTimetable: true,
        programmeTeachingTimetableError: {},
      };
    case timetableActions.GET_PROGRAMME_TEACHING_TIMETABLE_SUCCESS: {
      const { context, data } = action;
      const { programmeTeachingTimetable } = state;

      const findContextIndex = findIndex(
        programmeTeachingTimetable,
        (list) =>
          list.programme_id === context.programme_id &&
          list.semester_id === context.semester_id &&
          list.department_id === context.department_id &&
          list.academic_year_id === context.academic_year_id &&
          list.study_year_id === context.study_year_id &&
          list.campus_id === context.campus_id
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          programmeTeachingTimetable: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          programmeTeachingTimetable: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }
      return {
        ...newState,
        gettingProgrammeTeachingTimetable: false,
      };
    }
    case timetableActions.GET_PROGRAMME_TEACHING_TIMETABLE_ERROR:
      return {
        ...state,
        gettingProgrammeTeachingTimetable: false,
        programmeTeachingTimetableError: action.error,
      };

    case timetableActions.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_REQUEST:
      return {
        ...state,
        downloadingProgrammeTimetable: true,
        downloadProgrammeTimetableError: {},
      };
    case timetableActions.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_SUCCESS:
      return {
        ...state,
        downloadingProgrammeTimetable: false,
      };
    case timetableActions.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_ERROR:
      return {
        ...state,
        downloadingProgrammeTimetable: false,
        downloadProgrammeTimetableError: action.error,
      };

    default:
      return state;
  }
};
