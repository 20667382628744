import { isEmpty } from 'lodash';
import PropTypes, { any, object } from 'prop-types';
import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { FaCheckSquare } from 'react-icons/fa';
import CourseUnitsTable from './CourseUnitsTable';

function StudyYearCourseUnits({
  selectedCourseUnits,
  studyYearCourse,
  setDataAndOpenPopUp,
  setSelectedRowData,
}) {
  const conditionalRowStyles = [
    {
      when: (row) =>
        selectedCourseUnits.find(
          (courseUnit) => courseUnit.course_unit_id === row.course_unit_id
        ),
      style: {
        backgroundColor: '#b2bec3',
        fontWeight: 'bold !important',
        color: '#2d3436',
        '&:hover': {
          backgroundColor: '#636e72',
          cursor: 'pointer',
        },
      },
    },
  ];

  const onSelectCourse = (rowData) => {
    if (rowData) {
      setSelectedRowData({
        ...rowData,
        study_year: studyYearCourse.study_year,
      });
    }
  };

  const actionButtons = useCallback((rowData) => {
    const { course_unit_id: id } = rowData;
    return (
      <Form.Check
        type="checkbox"
        checked={
          !isEmpty(
            selectedCourseUnits.find(
              (courseUnit) => courseUnit.course_unit_id === id
            )
          )
        }
        id={`custom-${id}`}
        onChange={() => onSelectCourse(rowData)}
      />
    );
  }, []);

  return (
    <CourseUnitsTable
      courseUnits={studyYearCourse.course_units}
      onRowClicked={(rowData) =>
        setDataAndOpenPopUp({
          ...rowData,
          study_year: studyYearCourse.study_year,
        })
      }
      conditionalRowStyles={conditionalRowStyles}
      compact
      columnsBefore={[
        {
          name: <FaCheckSquare />,
          width: '30px',
          cell: actionButtons,
        },
      ]}
    />
  );
}

StudyYearCourseUnits.defaultProps = {
  selectedCourseUnits: [],
  studyYearCourse: {},
};

StudyYearCourseUnits.propTypes = {
  selectedCourseUnits: PropTypes.oneOfType([any]),
  studyYearCourse: PropTypes.oneOfType([object]),
  setDataAndOpenPopUp: PropTypes.func.isRequired,
  setSelectedRowData: PropTypes.func.isRequired,
};

export default StudyYearCourseUnits;
