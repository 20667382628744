import { map, sumBy, toNumber } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Card, Table } from 'react-bootstrap';

function SemesterFees({ fees, semester }) {
  return (
    <Card className="border-top-0 mb-3">
      <Card.Header className="p-0 mb-2">
        <span className="px-2 py-1 bg-primary text-white text-sm fw-bold">
          {semester}
        </span>
      </Card.Header>
      <Card.Body className="p-0">
        <Table borderless striped className="m-0" size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>ITEM</th>
              <th>CATEGORY</th>
              <th>AMOUNT</th>
              <th>TO PAY</th>
              <th className="text-center">EXEMPTION</th>
              <th>CURR</th>
            </tr>
          </thead>
          <tbody>
            {map(
              [...fees.FunctionalFees, ...fees.tuitionFees],
              (functionalFee, index) => (
                <tr key={functionalFee.fees_element_id}>
                  <td>{`${index + 1}`}</td>
                  <td>{functionalFee.fees_element_name}</td>
                  <td>{functionalFee.fees_element_category}</td>
                  <td>
                    {toNumber(
                      functionalFee.oldAmount || functionalFee.amount
                    ).toLocaleString()}
                  </td>
                  <td>{toNumber(functionalFee.amount).toLocaleString()}</td>
                  <td className="text-center">{`${
                    functionalFee.percentageDiscount || 0
                  } %`}</td>
                  <td width={20}>{functionalFee.currency}</td>
                </tr>
              )
            )}

            <tr className="table-secondary text-danger">
              <td colSpan={4}>TOTAL</td>
              <td colSpan={3}>
                <span>
                  {`${sumBy(
                    [...fees.FunctionalFees, ...fees.tuitionFees],
                    'amount'
                  ).toLocaleString()} UGX`}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

SemesterFees.propTypes = {
  fees: PropTypes.oneOfType([object]).isRequired,
  semester: PropTypes.string.isRequired,
};

export default SemesterFees;
