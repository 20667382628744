import { Card as AntDCard } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { formatName, whichInstitution } from '../../helpers/dataFormatter';
import Avatar from '../MyProfile/Avatar';

function StudentAcademicDetails() {
  const { institutionStructure } = useSelector((state) => state.app);
  const { authUser, currentStudentProgramme } = useSelector(
    (state) => state.auth
  );

  const isMubs = whichInstitution('MUBS', institutionStructure);

  return (
    <>
      <Card.Header className="border mb-1 fw-bold bg-primary text-white">
        <div>MY STUDENT DETAILS</div>
      </Card.Header>
      <Card className="p-0" style={{ border: 'dashed 1px var(--bs-primary)' }}>
        <Row className="g-1 p-1">
          <Col className="d-flex justify-content-center">
            <AntDCard
              className="border-0"
              style={{ width: 'fit-content' }}
              bodyStyle={{ padding: 5 }}
            >
              <Avatar
                alt="Profile Photo"
                width={120}
                height={120}
                className=""
              />
            </AntDCard>
          </Col>
          <Col md={5}>
            <Table responsive="md" borderless>
              <tbody>
                <tr>
                  <td width="40%" className="fw-bold">
                    FULL NAME:
                  </td>
                  <td className="fw-bold">
                    {formatName(
                      `${authUser?.surname} ${authUser?.other_names}`
                    )}
                  </td>
                </tr>
                <tr>
                  <td width="40%" className="fw-bold">
                    STUDENT NO:
                  </td>
                  <td className="fw-bold">
                    {isMubs && !isEmpty(currentStudentProgramme?.student_number)
                      ? currentStudentProgramme?.old_student_number
                      : currentStudentProgramme?.student_number}
                  </td>
                </tr>
                <tr>
                  <td width="40%" className="fw-bold">
                    REG NO:
                  </td>
                  <td className="fw-bold">
                    {currentStudentProgramme.registration_number}
                  </td>
                </tr>
                <tr>
                  <td width="40%" className="fw-bold">
                    EMAIL:
                  </td>
                  <td className="fw-bold">{authUser?.email}</td>
                </tr>
                <tr>
                  <td width="40%" className="fw-bold">
                    TEL. N0:
                  </td>
                  <td className="fw-bold">{`+${authUser?.phone}`}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md={5}>
            <Table responsive="md" borderless>
              <tbody>
                <tr>
                  <td width="30%" className="fw-bold">
                    PROGRAMME:
                  </td>
                  <td className="fw-bold">
                    {currentStudentProgramme?.programme_title}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default StudentAcademicDetails;
