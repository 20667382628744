import { Menu } from 'antd';
import { find, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { settingActions } from '../../config/actions';
import MenuList from '../../config/routes/MenuList';
import Avatar from '../../containers/MyProfile/Avatar';
import { formatName, whichInstitution } from '../../helpers/dataFormatter';
import OpenSidebarMenu from './OpenSidebarMenu';
import sideBarMenu from './SidebarMenu';

function SideBar() {
  const dispatch = useDispatch();
  const {
    authUser,
    currentStudentProgramme,
    gettingAuthUser,
    activeProgramme,
  } = useSelector((state) => state.auth);
  const { institutionStructure } = useSelector((state) => state.app);
  const { shouldAllocate } = useSelector((state) => state.myInvoice);
  const { forceEnrollment } = useSelector((state) => state.enrollment);
  const { submenuKeys, selectedMenu } = useSelector((state) => state.setting);
  const isMobileDevice = useMediaQuery({ maxWidth: 767 });
  const [openKeys, setOpenKeys] = useState(['self-enrollment']);

  const isMubs = whichInstitution('MUBS', institutionStructure);

  useEffect(() => {
    const sideMenuItems = isEmpty(activeProgramme)
      ? OpenSidebarMenu
      : sideBarMenu;
    const isMatch = sideMenuItems.find((item) =>
      item?.children?.some((child) => child.label === selectedMenu.label)
    );

    dispatch(
      settingActions.setNavigation({
        menuLevel_1: isMatch ? isMatch.label : null,
        menuLevel_2: selectedMenu?.label,
        menuLevel_3: null,
      })
    );
  }, [selectedMenu]);

  const setSelectedMenu = (content) => {
    dispatch(settingActions.setSelectedMenu(content));
    if (isMobileDevice) dispatch(settingActions.setIsToggled(false));
  };

  const onSelectMenu = ({ selectedKeys }) => {
    const [firstKey] = selectedKeys;

    const findMenu = find(
      Object.keys(MenuList),
      (menuKey) => MenuList[menuKey].action === firstKey
    );

    setSelectedMenu(findMenu ? MenuList[findMenu] : {});
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    if (submenuKeys.includes(latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };

  return (
    <>
      <div
        className={`student-profile mx-auto text-sm text-center ${
          isMobileDevice ? 'py-2' : 'py-3'
        } border-bottom`}
      >
        <Avatar shape="square" size={76} draggable={false} />

        {gettingAuthUser && isEmpty(authUser) ? (
          <div className="d-block text-center">
            <Spinner
              variant="primary"
              size="sm"
              animation="border"
              className="d-block"
            />
          </div>
        ) : (
          <>
            <div className="font500 mt-2 text-light">
              {formatName(authUser)}
            </div>
            <div className="font600 text-uppercase mt-1 text-light">
              {`STUDENT NO.: ${
                isMubs && !isEmpty(currentStudentProgramme?.old_student_number)
                  ? currentStudentProgramme?.old_student_number
                  : currentStudentProgramme?.student_number
              }`}
            </div>
          </>
        )}
      </div>

      <Menu
        mode="inline"
        defaultSelectedKeys={[
          shouldAllocate
            ? MenuList.MyInvoice.action
            : MenuList.SelfEnrollment.action,
        ]}
        defaultOpenKeys={[MenuList.SelfEnrollment.action]}
        theme="light"
        items={isEmpty(activeProgramme) ? OpenSidebarMenu : sideBarMenu}
        triggerSubMenuAction="click"
        onSelect={onSelectMenu}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        inlineIndent={10}
        disabled={shouldAllocate || forceEnrollment}
        className="text-uppercase fw-bold text-sm border-0"
      />
    </>
  );
}

export default SideBar;
