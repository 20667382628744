const timetable = {
  teachingTimetable: {
    gettingProgrammeTeachingTimetable: false,
    programmeTeachingTimetable: [],
    programmeTeachingTimetableError: {},

    downloadingProgrammeTimetable: false,
    downloadProgrammeTimetableError: {},
  },
};

export default timetable;
