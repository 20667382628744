import React from 'react';
import PropTypes, { any } from 'prop-types';
import { Empty } from 'antd';

function DataNotFound({ message, children, ...props }) {
  return (
    <div className="p-4 text-danger font600 text-uppercase">
      <Empty
        description={<span className="text-danger">{message}</span>}
        className="text-sm"
        {...props}
      />
      {children}
    </div>
  );
}
DataNotFound.defaultProps = {
  children: null,
  message: 'Data Not Found',
};

DataNotFound.propTypes = {
  message: PropTypes.string,
  children: PropTypes.oneOfType([any]),
};

export default DataNotFound;
