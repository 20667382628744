import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, toUpper } from 'lodash';
import { useSelector } from 'react-redux';
import { Collapse } from 'antd';
import { DataNotFound, DataSpinner } from '../../../components/common';
import TransactionsTable from './TransactionsTable';

function Transactions({ currentMenu }) {
  const { transactions, loading } = useSelector((state) => state.myTransaction);
  const [activeTransaction, setActiveTransaction] = useState('');

  const firstItem = transactions[currentMenu] && transactions[currentMenu][0];

  useEffect(() => {
    if (!isEmpty(transactions[currentMenu])) {
      setActiveTransaction(firstItem?.transaction_date);
    }
  }, [currentMenu, transactions]);

  return (
    <>
      {loading && isEmpty(transactions[currentMenu]) ? (
        <DataSpinner />
      ) : (
        <>
          {isEmpty(transactions[currentMenu]) ? (
            <DataNotFound />
          ) : (
            <Collapse
              accordion
              className="w-100"
              size="small"
              defaultActiveKey={
                transactions[currentMenu] &&
                transactions[currentMenu][0]?.transaction_date
              }
              activeKey={activeTransaction}
              onChange={([e]) => setActiveTransaction(e)}
              items={map(transactions[currentMenu], (transaction) => ({
                key: transaction?.transaction_date,
                label: (
                  <div
                    className={`fw-bold ${
                      activeTransaction === transaction?.transaction_date
                        ? 'text-danger'
                        : 'text-dark'
                    }`}
                  >
                    {currentMenu === 'deposits'
                      ? toUpper(
                          `${transaction?.transaction_date} Fees Deposits`
                        )
                      : toUpper(`${transaction?.programme_study_years} - 
                        ${transaction?.semester} - ${transaction?.academic_year}`)}
                  </div>
                ),
                children: <TransactionsTable data={transaction} />,
              }))}
            />
          )}
        </>
      )}
    </>
  );
}

Transactions.defaultProps = {
  currentMenu: 'data',
};
Transactions.propTypes = {
  currentMenu: PropTypes.string,
};

export default Transactions;
