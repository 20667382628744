import { get, isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlertMessage,
  CustomModal,
  DataNotFound,
  ReloadButton,
  SelectInput,
  SubmitButton,
} from '../../components/common';
import { appActions, enrollmentActions } from '../../config/actions';
import { formatMetadata, generalFormatter } from '../../helpers/dataFormatter';
import usePrevious from '../Hooks/usePrevious';
import RetakeEnrollment from './RetakeEnrollment';

function EnrollForm({
  enrollmentEvent,
  activeProgramme,
  reloadEnrollmentEvent,
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const { enrolling, enrollError, gettingCurrentEvents } = useSelector(
    (state) => state.enrollment
  );
  const { metadata } = useSelector((state) => state.app);

  const [enrollmentStatusOptions, setEnrollmentOptions] = useState([]);
  const [studyYearOptions, setStudyYearOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const prevState = usePrevious({ enrollError });
  const [showRetakesModal, setShowRetakesModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [hasRetakes, setHasRetakes] = useState('0');

  useEffect(() => {
    if (isEmpty(metadata)) dispatch(appActions.getMetadata());
  }, []);

  useEffect(() => {
    if (
      prevState &&
      !isEmpty(enrollError) &&
      enrollError !== prevState.enrollError
    ) {
      setErrorMessage(enrollError?.server?.message);
    }
  }, [enrollError]);

  useEffect(() => {
    if (!isEmpty(metadata)) {
      setEnrollmentOptions(formatMetadata(metadata, 'ENROLLMENT STATUSES'));
    }
    if (!isEmpty(activeProgramme)) {
      setStudyYearOptions(
        generalFormatter(
          activeProgramme.programme_study_years || [],
          'programme_study_years',
          'id'
        )
      );
    }
  }, [metadata]);

  const handleCloseModal = () => {
    if (isEmpty(selectedRows)) {
      setHasRetakes('0');
    }
    setShowRetakesModal(false);
  };

  const onClickEnrollButton = (data) => {
    setErrorMessage(null);
    if (
      !isEmpty(data) &&
      !isEmpty(enrollmentEvent) &&
      !isEmpty(activeProgramme)
    ) {
      data.event_id = enrollmentEvent?.id;
      const { retakeOption, ...newData } = data;

      const retakes = selectedRows?.map((retake) => ({
        course_unit_id: retake.course_unit_id,
        course_unit_status_id: retake.registrationStatus?.value,
      }));

      dispatch(
        enrollmentActions.enrollStudent({
          ...newData,
          student_programme_id: activeProgramme.id,
          retakes: hasRetakes === '1' ? retakes : [],
        })
      );
    }
  };

  const onSelectHasRetakes = (e) => {
    const { value } = e.target;

    if (value === '1') {
      setShowRetakesModal(true);
    }
    setHasRetakes(value);
  };

  const removePaperHandler = (courseId) => {
    setSelectedRows(
      selectedRows.filter((paper) => paper.course_unit_code !== courseId)
    );
  };

  useEffect(() => {
    if (isEmpty(selectedRows)) {
      setHasRetakes('0');
    }
  }, [selectedRows]);

  return (
    <div>
      {isEmpty(enrollmentEvent) ? (
        <>
          <DataNotFound message="There is currently No open Enrollment Event." />
          <div className="text-center">
            <ReloadButton
              loading={gettingCurrentEvents}
              onClick={reloadEnrollmentEvent}
            />
          </div>
        </>
      ) : (
        <Card>
          <Card.Header className="font600 text-muted text-sm py-3">
            ENROLL FOR
            <strong className="mx-1">{`${enrollmentEvent?.semester}, `}</strong>
            {enrollmentEvent?.academic_year}
            {activeProgramme && (
              <div className="card-options">
                <div className="text-xs">
                  {`PROG: ${activeProgramme?.programme_title} - (${activeProgramme?.programme_code})`}
                </div>
              </div>
            )}
          </Card.Header>
          <Form onSubmit={handleSubmit(onClickEnrollButton)}>
            <Card.Body>
              {errorMessage && (
                <AlertMessage
                  message={errorMessage}
                  show
                  className="py-1 text-sm"
                />
              )}
              <Row>
                <Col md={4}>
                  <SelectInput
                    name="study_year_id"
                    register={register({
                      required:
                        'Select the year of study you are enrolling for',
                    })}
                    label="YEAR OF STUDY"
                    inline
                    selectOptions={studyYearOptions}
                    requiredField
                    error={get(errors, 'study_year_id.message')}
                  />
                </Col>
                <Col md={4}>
                  <SelectInput
                    name="enrollment_status_id"
                    register={register({
                      required: 'Select enrollment type',
                    })}
                    label="ENROLLING AS?"
                    inline
                    selectOptions={enrollmentStatusOptions}
                    requiredField
                    error={get(errors, 'enrollment_status_id.message')}
                  />
                </Col>
                <Col md={4}>
                  <SelectInput
                    label="HAVE RETAKES?"
                    inline
                    selectOptions={[
                      { label: 'Yes', value: '1' },
                      { label: 'No', value: '0' },
                    ]}
                    onChange={onSelectHasRetakes}
                    value={hasRetakes}
                    register={register({
                      required: 'Select Retakes option',
                    })}
                    name="retakeOption"
                    requiredField
                    error={get(errors, 'retakeOption.message')}
                  />
                </Col>
              </Row>
              {hasRetakes === '1' && !isEmpty(selectedRows) && (
                <Card className="mt-4">
                  <Card.Header className="py-2 fw-bold text-primary">
                    SELECTED RETAKES/MISSING PAPERS
                    <div className="card-options">
                      <Button
                        variant="primary"
                        size="sm"
                        className="fw-bold text-sm text-uppercase"
                        onClick={() => {
                          setErrorMessage(null);
                          setShowRetakesModal(true);
                        }}
                      >
                        Add More Retake
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <Table responsive striped size="sm">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>CODE</th>
                          <th>COURSE</th>
                          <th>C.Us</th>
                          <th>CATEGORY</th>
                          <th>STATUS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedRows.map((retake, index) => (
                          <tr key={retake.course_unit_code}>
                            <td>{index + 1}</td>
                            <td>{retake.course_unit_code}</td>
                            <td>{retake.course_unit_name}</td>
                            <td>{retake.credit_unit}</td>
                            <td>{retake.category}</td>
                            <td>{retake.registrationStatus?.label}</td>
                            <td>
                              <Button
                                danger
                                onClick={() => {
                                  removePaperHandler(retake.course_unit_code);
                                }}
                              >
                                Remove
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              )}
            </Card.Body>
            <Card.Footer className="text-end">
              <SubmitButton
                text="Enroll Now"
                loading={enrolling}
                loadingText="Enrolling..."
              />
            </Card.Footer>
          </Form>

          <CustomModal
            show={showRetakesModal}
            size="xl"
            title="SELECT COURSES TO RETAKE"
            onCloseModal={handleCloseModal}
            backdrop="static"
            keyboard={false}
          >
            <RetakeEnrollment
              enrollWithRetakes={hasRetakes === '1'}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              setShowRetakesModal={setShowRetakesModal}
            />
          </CustomModal>
        </Card>
      )}
    </div>
  );
}

EnrollForm.propTypes = {
  reloadEnrollmentEvent: PropTypes.func.isRequired,
  activeProgramme: PropTypes.oneOfType([object]).isRequired,
  enrollmentEvent: PropTypes.oneOfType([object]).isRequired,
};

export default EnrollForm;
