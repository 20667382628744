import { Tag } from 'antd';
import { filter, isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { FaUserEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { DataNotFound, ReloadButton } from '../../components/common';
import AccordionHeader from '../../components/common/AccordionHeader';
import { calendarActions, registrationActions } from '../../config/actions';
import RegistrationCard from './RegistrationCard';
import SingleRegistrationHistory from './SingleRegistrationHistory';

function RegistrationHistory() {
  const dispatch = useDispatch();
  const { registrationHistories, gettingRegistrationHistory } = useSelector(
    (state) => state.registration
  );
  const { calendarYearEvents } = useSelector((state) => state.calendar);
  const { showModal } = useSelector((state) => state.setting);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { currentSemester } = useSelector((state) => state.app);

  const [activeKey, setActiveKey] = useState(1);
  const [selectedHistory, setSelectedHistory] = useState({});
  const [registrationData, setRegistrationData] = useState([]);

  const reloadRegistrationHistory = () => {
    dispatch(
      registrationActions.getRegistrationHistory(currentStudentProgramme?.id)
    );
  };

  useEffect(() => {
    if (isEmpty(registrationHistories)) reloadRegistrationHistory();
  }, []);

  useEffect(() => {
    if (isEmpty(calendarYearEvents)) {
      dispatch(
        calendarActions.getAcademicYearEvents(currentStudentProgramme.id)
      );
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(registrationHistories)) {
      const filteredRegistrations = filter(
        registrationHistories,
        (reg) => reg.is_active
      );

      setRegistrationData(filteredRegistrations || []);
    }
  }, [registrationHistories]);

  return (
    <>
      <Card.Header className="py-2 px-3 border text-primary text-sm mb-2 font600">
        MY REGISTRATION HISTORY
        <div className="card-options">
          <ReloadButton
            loading={gettingRegistrationHistory}
            onClick={reloadRegistrationHistory}
          />
        </div>
      </Card.Header>
      {isEmpty(registrationHistories) ? (
        <>
          <DataNotFound message="You have No Registration History." />
          <div className="text-center">
            <ReloadButton
              loading={gettingRegistrationHistory}
              onClick={reloadRegistrationHistory}
            />
          </div>
        </>
      ) : (
        <Accordion defaultActiveKey="1" activeKey={activeKey}>
          {registrationData.map((registrationHistory, index) => (
            <Accordion.Item
              eventKey={index + 1}
              className="mb-2 border"
              key={`${registrationHistory.event_id}_${registrationHistory.is_active}`}
            >
              <AccordionHeader
                activeKey={activeKey}
                eventKey={index + 1}
                setActiveKey={setActiveKey}
                className={
                  registrationHistory.is_active === false
                    ? 'text-danger font600'
                    : 'font600'
                }
              >
                <FaUserEdit className="me-2" />
                {`REGISTRATION FOR ${registrationHistory?.registration_study_year}, ${registrationHistory.event_semester} - ${registrationHistory.academic_year}`}
                {isEqual(
                  registrationHistory?.academic_year,
                  calendarYearEvents[0]?.academic_year
                ) &&
                  isEqual(
                    registrationHistory?.event_semester,
                    calendarYearEvents[0]?.semester
                  ) &&
                  isEqual(
                    currentSemester?.semester,
                    registrationHistory?.event_semester
                  ) && (
                    <span className="mx-3">
                      <Tag color="green">CURRENT</Tag>
                    </span>
                  )}
              </AccordionHeader>
              <Accordion.Body>
                <div
                  className={
                    registrationHistory.is_active === false ? 'text-danger' : ''
                  }
                >
                  <SingleRegistrationHistory
                    registrationHistory={registrationHistory}
                    setSelectedHistory={setSelectedHistory}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}

      {showModal && <RegistrationCard selectedRegistration={selectedHistory} />}
    </>
  );
}

export default RegistrationHistory;
