import { isEmpty, map, sumBy } from 'lodash';
import PropTypes, { any } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AntDTable } from '../../../components/common';
import { settingActions } from '../../../config/actions';
import OffsetInvoice from './OffsetInvoice';

function Invoice({ data }) {
  const dispatch = useDispatch();
  const [affectedInvoices, setAffectedInvoices] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const { showOffsetInvoiceModal } = useSelector((state) => state.setting);

  useEffect(() => {
    let average = 0;
    if (!isEmpty(data.tuition_invoices)) average += 1;
    if (!isEmpty(data.functional_fees_invoices)) average += 1;
    if (!isEmpty(data.manual_invoices)) average += 1;
    if (!isEmpty(data.other_fees_invoices)) average += 1;
    setAffectedInvoices(average);

    if (!isEmpty(data)) {
      const invoiceData = [
        ...map(data.tuition_invoices, (invoice) => ({
          ...invoice,
          invoice_group: 'Tuition Invoice',
        })),
        ...map(data.functional_fees_invoices, (invoice) => ({
          ...invoice,
          invoice_group: 'Functional Fees Invoice',
        })),
        ...map(data.manual_invoices, (invoice) => ({
          ...invoice,
          invoice_group: 'Manual Invoice',
        })),
        ...map(data.other_fees_invoices, (invoice) => ({
          ...invoice,
          invoice_group: 'Other Fees Invoice',
        })),
      ];
      setInvoices(invoiceData);
    }
  }, [data]);

  const invoiceGrandTotalAmount =
    sumBy(data.tuition_invoices, 'invoice_amount') +
    sumBy(data.functional_fees_invoices, 'invoice_amount') +
    sumBy(data.manual_invoices, 'invoice_amount') +
    sumBy(data.other_fees_invoices, 'invoice_amount');

  const invoiceGrandAmountDue =
    sumBy(data.tuition_invoices, 'amount_due') +
    sumBy(data.functional_fees_invoices, 'amount_due') +
    sumBy(data.manual_invoices, 'amount_due') +
    sumBy(data.other_fees_invoices, 'amount_due');

  const invoiceGrandAmountPaid =
    sumBy(data.tuition_invoices, 'amount_paid') +
    sumBy(data.functional_fees_invoices, 'amount_paid') +
    sumBy(data.manual_invoices, 'amount_paid') +
    sumBy(data.other_fees_invoices, 'amount_paid');

  const invoiceGrandPercentage =
    sumBy(data.tuition_invoices, 'percentage_completion') +
    sumBy(data.functional_fees_invoices, 'percentage_completion') +
    sumBy(data.manual_invoices, 'percentage_completion') +
    sumBy(data.other_fees_invoices, 'percentage_completion');

  const showOffsetInvoice = (rowData) =>
    !isEmpty(rowData) &&
    dispatch(settingActions.showOffsetInvoiceModal(true, rowData));

  const offsetButton = useCallback(
    (text, row) => (
      <>
        {row.amount_due > 0 ? (
          <Button
            variant="link"
            className="text-danger font500"
            size="sm"
            onClick={() => showOffsetInvoice(row)}
          >
            Offset
          </Button>
        ) : (
          <span className="text-success">
            <FaCheckCircle className="me-1" />
            Cleared
          </span>
        )}
      </>
    ),
    []
  );

  const columns = useMemo(
    () => [
      {
        title: 'Invoice No.',
        dataIndex: 'invoice_number',
        width: 130,
      },
      {
        title: 'Category',
        dataIndex: 'invoice_group',
        width: 130,
      },
      {
        title: 'INV. AMOUNT',
        width: 120,
        align: 'center',
        dataIndex: 'invoice_amount',
        render: (invoiceAmount) => parseInt(invoiceAmount, 10).toLocaleString(),
      },
      {
        title: 'PAID',
        width: 120,
        align: 'center',
        dataIndex: 'amount_paid',
        wrap: true,
        render: (amountPaid) => parseInt(amountPaid, 10).toLocaleString(),
      },
      {
        title: 'AMOUNT DUE',
        width: 120,
        align: 'center',
        dataIndex: 'amount_due',
        wrap: true,
        render: (amountDue) => parseInt(amountDue, 10).toLocaleString(),
      },
      {
        title: 'CURR',
        width: 70,
        render: (row) => row.currency,
      },
      {
        title: 'NARRATION',
        width: 130,
        render: (row) => row.description,
      },
      {
        title: 'TYPE',
        width: 130,
        render: (row) => row.invoice_type,
      },
      {
        title: 'STATUS',
        width: 90,
        render: (row) => row.invoice_status,
      },
      {
        title: 'COMPLETION %',
        align: 'center',
        width: 120,
        render: (row) => `${parseFloat(row.percentage_completion).toFixed(2)}%`,
      },
      {
        title: 'ALLOCATION',
        key: 'allocate',
        width: 100,
        fixed: 'right',
        render: offsetButton,
      },
    ],
    []
  );

  return (
    <div>
      <AntDTable
        data={invoices}
        columns={columns}
        rowKey="invoice_number"
        bordered
        xScroll="100%"
        pagination={false}
      />

      <Row className="g-1 my-2 row-deck">
        <Col md={3} className="my-1">
          <Card body className="border bg-light ">
            <Card.Text className="text-xs font600">
              TOTAL AMOUNT:
              <span className="text-info mx-1">
                {invoiceGrandTotalAmount.toLocaleString()}
              </span>
            </Card.Text>
          </Card>
        </Col>
        <Col md={3} className="my-1">
          <Card body className="border bg-light ">
            <Card.Text className="text-xs font600">
              TOTAL AMOUNT PAID:
              <span className="text-success mx-1">
                {invoiceGrandAmountPaid.toLocaleString()}
              </span>
            </Card.Text>
          </Card>
        </Col>
        <Col md={3} className="my-1">
          <Card body className="border bg-light ">
            <Card.Text className="text-xs font600">
              TOTAL AMOUNT DUE:
              <span className="text-danger mx-1">
                {invoiceGrandAmountDue.toLocaleString()}
              </span>
            </Card.Text>
          </Card>
        </Col>
        <Col md={3} className="my-1">
          <Card body className="border bg-light ">
            <Card.Text className="text-xs font600">
              COMPLETION:
              <span
                className={
                  invoiceGrandPercentage > 70
                    ? 'mx-1 text-success'
                    : 'mx-1 text-danger'
                }
              >
                {`${(
                  parseInt(invoiceGrandPercentage, 10) / affectedInvoices
                ).toFixed(2)} %`}
              </span>
            </Card.Text>
          </Card>
        </Col>
      </Row>
      {showOffsetInvoiceModal?.show === true && <OffsetInvoice />}
    </div>
  );
}

Invoice.defaultProps = {
  data: {},
};

Invoice.propTypes = {
  data: PropTypes.oneOfType([any]),
};

export default Invoice;
