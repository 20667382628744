const service = {
  service: {
    fetchingPendingApplications: false,
    pendingApplications: [],
    pendingRegistrationApplications: [],
    pendingApplicationError: {},

    gettingServiceEvent: false,
    serviceEvents: [],
    serviceEventError: {},

    deletingService: false,
    deletedService: {},
    deleteServiceError: {},

    submittingApplication: false,
    submittedApplications: {},
    submitApplicationError: {},

    updatingApplication: false,
    updatedApplication: {},
    updateApplicationError: {},

    gettingProgrammes: false,
    programmes: [],
    getProgrammesError: {},

    generatingPRN: false,
    generatePRNError: {},
    generatedPRN: {},

    gettingServiceHistory: false,
    serviceHistories: [],
    serviceHistoryError: {},

    downloadingServiceLetter: false,
    downloadServiceLetterSuccess: {},
    downloadServiceLetterError: {},

    gettingStudentIdTracker: false,
    studentIdTracker: {},
    studentIdTrackerError: {},

    gettingIdCardData: false,
    idCardData: {},
    idCardDataError: {},

    verifyingIdCardData: false,
    verifySuccess: {},
    verifyError: {},
  },
};

export default service;
