const calendar = {
  academicCalendar: {
    gettingCalendarEvents: false,
    calendarYearEvents: [],
    allCalendarEvents: [],
    calendarEventsError: {},
  },
};

export default calendar;
