import axios from 'axios';
import { split } from 'lodash';
import * as FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { getObjectValues } from '../../../helpers/dataFormatter';
import { serviceActions } from '../../actions';

function* submitApplications(actions) {
  const { data, category, studentProgrammeId } = actions;
  try {
    const response = yield axios({
      url: `/student-portal/services/${category}`,
      method: 'POST',
      data: {
        ...getObjectValues(data),
        service: split(data.service, '-').join(' ').toUpperCase(),
      },
    });
    yield put({
      type: serviceActions.SUBMIT_APPLICATION_SUCCESS,
      data: response.data,
    });
    yield put({
      type: serviceActions.GET_PENDING_APPLICATIONS_REQUEST,
      category,
      studentProgrammeId,
      serviceType: data.service,
    });
  } catch (error) {
    yield put({
      type: serviceActions.SUBMIT_APPLICATION_ERROR,
      error: error.data,
    });
  }
}

function* submitRegistrationApplication(actions) {
  try {
    const response = yield axios({
      url: `/student-portal/services/apply-for-administrative-registration`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: serviceActions.SUBMIT_REGISTRATION_APPLICATION_SUCCESS,
      data: response.data,
    });
    yield put({
      type: serviceActions.GET_PENDING_REGISTRATION_SERVICE_REQUEST,
    });
  } catch (error) {
    yield put({
      type: serviceActions.SUBMIT_REGISTRATION_APPLICATION_ERROR,
      error: error.data,
    });
  }
}

function* deletePendingService(actions) {
  const { category, serviceId, studentProgrammeId, serviceType } = actions;
  try {
    const response = yield axios({
      url: `/student-portal/services/${category}/${serviceId}`,
      method: 'DELETE',
      params: { serviceType },
    });
    yield put({
      type: serviceActions.DELETE_PENDING_APPLICATIONS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: serviceActions.GET_PENDING_APPLICATIONS_REQUEST,
      category,
      studentProgrammeId,
      serviceType,
    });
  } catch (error) {
    yield put({
      type: serviceActions.DELETE_PENDING_APPLICATIONS_ERROR,
      error: error.data,
    });
  }
}

function* updateApplications(actions) {
  const { data, category, studentProgrammeId, applicationId } = actions;
  try {
    const response = yield axios({
      url: `/student-portal/services/${category}/${applicationId}`,
      method: 'PUT',
      data: getObjectValues(data),
      params: { serviceType: data.service },
    });
    yield put({
      type: serviceActions.UPDATE_APPLICATION_SUCCESS,
      data: response.data,
    });
    yield put({
      type: serviceActions.GET_PENDING_APPLICATIONS_REQUEST,
      category,
      studentProgrammeId,
      serviceType: data.service,
    });
  } catch (error) {
    yield put({
      type: serviceActions.UPDATE_APPLICATION_ERROR,
      error: error.data,
    });
  }
}

function* getPendingApplications(actions) {
  try {
    const { category, studentProgrammeId, serviceType } = actions;
    const response = yield axios({
      url: `/student-portal/services/pending/${category}`,
      method: 'GET',
      params: { service: serviceType },
    });
    yield put({
      type: serviceActions.GET_PENDING_APPLICATIONS_SUCCESS,
      category: serviceType,
      studentProgrammeId,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: serviceActions.GET_PENDING_APPLICATIONS_ERROR,
      error: error.data,
    });
  }
}

function* getPendingRegistrationApplications() {
  try {
    const response = yield axios({
      url: `/student-portal/services/my-administrative-registration-requests`,
      method: 'GET',
    });
    yield put({
      type: serviceActions.GET_PENDING_REGISTRATION_SERVICE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: serviceActions.GET_PENDING_REGISTRATION_SERVICE_ERROR,
      error: error.data,
    });
  }
}

function* getServiceEvent(actions) {
  try {
    const { studentProgrammeId, serviceType } = actions;
    const response = yield axios({
      url: `/student-portal/services/event/${studentProgrammeId}`,
      method: 'GET',
      params: { category: serviceType },
    });

    yield put({
      type: serviceActions.GET_SERVICE_EVENT_SUCCESS,
      data: response.data,
      studentProgrammeId,
      category: serviceType,
    });
  } catch (error) {
    yield put({
      type: serviceActions.GET_SERVICE_EVENT_ERROR,
      error: error.data,
    });
  }
}

function* getServiceHistory(actions) {
  try {
    const { category } = actions;
    const response = yield axios({
      url: `/student-portal/services/history/${category}`,
      method: 'GET',
    });
    yield put({
      type: serviceActions.GET_SERVICE_HISTORY_SUCCESS,
      data: response.data,
      category,
    });
  } catch (error) {
    yield put({
      type: serviceActions.GET_SERVICE_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* generateServicePRN(actions) {
  try {
    const { category, id, studentProgrammeId, serviceType } = actions;
    const response = yield axios({
      url: `/student-portal/services/generate-prn/${category}/${id}`,
      method: 'POST',
    });
    yield put({
      type: serviceActions.GENERATE_SERVICE_PRN_SUCCESS,
      data: response.data,
    });
    yield put({
      type: serviceActions.GET_PENDING_APPLICATIONS_REQUEST,
      category,
      studentProgrammeId,
      serviceType,
    });
  } catch (error) {
    yield put({
      type: serviceActions.GENERATE_SERVICE_PRN_ERROR,
      error: error.data,
    });
  }
}

function* getProgrammes() {
  try {
    const response = yield axios({
      url: '/student-portal/services/programmes',
      method: 'GET',
    });
    yield put({
      type: serviceActions.GET_PROGRAMMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: serviceActions.GET_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* downloadServiceLetter(actions) {
  try {
    const { category, serviceId } = actions;

    yield axios({
      url: `/student-portal/services/download-letter/${category}/${serviceId}`,
      method: 'POST',
      data: actions.context,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/pdf',
        }),
        `ACCEPTANCE-LETTER.pdf`
      );
    });
    yield put({
      type: serviceActions.DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: serviceActions.DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* getStudentIdTracker(actions) {
  try {
    const { programmeId } = actions;

    const response = yield axios({
      url: `/student-portal/services/identification-tracker/${programmeId}`,
      method: 'GET',
    });

    yield put({
      type: serviceActions.GET_STUDENT_ID_TRACKER_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: serviceActions.GET_STUDENT_ID_TRACKER_ERROR,
      error: error.data || error,
    });
  }
}

function* getStudentIdDetails(actions) {
  try {
    const { programmeId } = actions;

    const response = yield axios({
      url: `/student-portal/services/get-id-data/${programmeId}`,
      method: 'GET',
    });

    yield put({
      type: serviceActions.GET_STUDENT_ID_DETAILS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: serviceActions.GET_STUDENT_ID_DETAILS_ERROR,
      error: error.data || error,
    });
  }
}

function* verifyStudentIdDetails(actions) {
  try {
    const { programmeId } = actions;

    const response = yield axios({
      url: `/student-portal/services/verify-data/${programmeId}`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: serviceActions.VERIFY_STUDENT_ID_DETAILS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: serviceActions.VERIFY_STUDENT_ID_DETAILS_ERROR,
      error: error.data || error,
    });
  }
}

function* watchSubmitApplications() {
  yield takeLatest(
    serviceActions.SUBMIT_APPLICATION_REQUEST,
    submitApplications
  );
}

function* watchSubmitRegistrationApplication() {
  yield takeLatest(
    serviceActions.SUBMIT_REGISTRATION_APPLICATION_REQUEST,
    submitRegistrationApplication
  );
}

function* watchDeletePendingService() {
  yield takeLatest(
    serviceActions.DELETE_PENDING_APPLICATIONS_REQUEST,
    deletePendingService
  );
}

function* watchEditApplications() {
  yield takeLatest(
    serviceActions.UPDATE_APPLICATION_REQUEST,
    updateApplications
  );
}

function* watchGetPendingApplications() {
  yield takeLatest(
    serviceActions.GET_PENDING_APPLICATIONS_REQUEST,
    getPendingApplications
  );
}

function* watchGetPendingRegistrationApplications() {
  yield takeLatest(
    serviceActions.GET_PENDING_REGISTRATION_SERVICE_REQUEST,
    getPendingRegistrationApplications
  );
}

function* watchGetProgrammes() {
  yield takeLatest(serviceActions.GET_PROGRAMMES_REQUEST, getProgrammes);
}

function* watchGetServiceEvent() {
  yield takeLatest(serviceActions.GET_SERVICE_EVENT_REQUEST, getServiceEvent);
}

function* watchGenerateServicePRN() {
  yield takeLatest(
    serviceActions.GENERATE_SERVICE_PRN_REQUEST,
    generateServicePRN
  );
}

function* watchGetServiceHistory() {
  yield takeLatest(
    serviceActions.GET_SERVICE_HISTORY_REQUEST,
    getServiceHistory
  );
}

function* watchDownloadServiceLetter() {
  yield takeLatest(
    serviceActions.DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_REQUEST,
    downloadServiceLetter
  );
}

function* watchGetStudentIdTracker() {
  yield takeLatest(
    serviceActions.GET_STUDENT_ID_TRACKER_REQUEST,
    getStudentIdTracker
  );
}

function* watchGetStudentIdDetails() {
  yield takeLatest(
    serviceActions.GET_STUDENT_ID_DETAILS_REQUEST,
    getStudentIdDetails
  );
}

function* watchVerifyStudentIdDetails() {
  yield takeLatest(
    serviceActions.VERIFY_STUDENT_ID_DETAILS_REQUEST,
    verifyStudentIdDetails
  );
}

const forkFunctions = [
  fork(watchSubmitApplications),
  fork(watchDeletePendingService),
  fork(watchGetPendingApplications),
  fork(watchGetProgrammes),
  fork(watchGetServiceEvent),
  fork(watchGenerateServicePRN),
  fork(watchGetServiceHistory),
  fork(watchEditApplications),
  fork(watchDownloadServiceLetter),
  fork(watchGetStudentIdTracker),
  fork(watchGetStudentIdDetails),
  fork(watchVerifyStudentIdDetails),
  fork(watchGetPendingRegistrationApplications),
  fork(watchSubmitRegistrationApplication),
];

export default forkFunctions;
