import { find, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  DataSpinner,
  ReloadButton,
  TabMenu,
} from '../../../components/common';
import { paymentActions, settingActions } from '../../../config/actions';
import MenuList from '../../../config/routes/MenuList';

function PreviousTransactions() {
  const dispatch = useDispatch();
  const { previousPayments, gettingPreviousPayments } = useSelector(
    (state) => state.myTransaction
  );
  const { selectedMenu, appNavigation } = useSelector((state) => state.setting);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const [currentMenu, setCurrentMenu] = useState('transactions');

  const menuItems = [
    {
      title: 'Invoice Payments',
      action: 'transactions',
    },
    {
      title: 'Fees Deposits',
      action: 'deposits',
    },
  ];

  const getTransactions = () =>
    dispatch(
      paymentActions.getPreviousTransactions(
        currentMenu,
        currentStudentProgramme.id
      )
    );

  const navigateUser = (route) => {
    const { PreviousTransaction } = MenuList;
    dispatch(
      settingActions.setNavigation({
        ...appNavigation,
        menuLevel_1: 'Payments',
        menuLevel_2: PreviousTransaction?.label,
        menuLevel_3: route,
      })
    );
  };

  useEffect(() => {
    if (isEmpty(previousPayments[currentMenu])) getTransactions();
  }, [currentMenu]);

  useEffect(() => {
    const { key } = selectedMenu;
    const activeTab = find(menuItems, (item) => item.action === currentMenu);

    if (key === 'previous-transactions') {
      navigateUser(activeTab?.title);
    }
  }, []);

  return (
    <Card>
      <Card.Header>
        <div className="fw-bold">MIGRATED PAYMENTS FROM PREVIOUS SYSTEM</div>
        <div className="card-options">
          <ReloadButton
            loading={gettingPreviousPayments}
            onClick={() => getTransactions()}
          />
        </div>
      </Card.Header>
      <TabMenu
        currentMenu={currentMenu}
        setCurrentMenu={setCurrentMenu}
        navigateUser={navigateUser}
        menus={menuItems}
      />
      <DataTable
        data={previousPayments[currentMenu] || []}
        noHeader
        progressPending={
          gettingPreviousPayments && isEmpty(previousPayments[currentMenu])
        }
        progressComponent={<DataSpinner />}
        columns={[
          {
            name: 'REFERENCE NO.',
            selector: (row) => row.transaction_reference || '',
          },
          {
            name: 'ENTITY NAME',
            selector: (row) => row.entity_name || '',
          },
          {
            name: 'AMOUNT',
            selector: (row) => parseInt(row.amount, 10).toLocaleString() || '',
          },
          {
            name: 'BANK',
            selector: (row) => row.bank || '',
          },
          {
            name: 'BRANCH',
            selector: (row) => row.branch || '',
          },
          {
            name: 'PAYMENT DATE',
            selector: (row) => moment(row.payment_date).format('LL') || '',
          },
        ]}
        noDataComponent={
          <DataNotFound message={`YOU HAVE NO MIGRATED ${currentMenu}`} />
        }
        striped
        dense
        className="text-sm"
        expandableIcon={{
          expanded: <FaMinus className="text-info" />,
          collapsed: <FaPlus className="text-info" />,
        }}
      />
    </Card>
  );
}

export default PreviousTransactions;
