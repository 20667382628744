import update from 'immutability-helper';
import { serviceActions } from '../../actions';
import initialState from '../../initialState';

function service(state = initialState.service, actions) {
  switch (actions.type) {
    case serviceActions.SUBMIT_APPLICATION_REQUEST:
      return {
        ...state,
        submittingApplication: true,
        submitApplicationError: {},
      };
    case serviceActions.SUBMIT_APPLICATION_SUCCESS: {
      const { pendingApplications } = state;
      const { data, category } = actions;

      const findIndex = pendingApplications.findIndex(
        (application) => application.service === category
      );

      const pendingApplication = { service: category, application: data };
      let newState;

      if (findIndex === -1) {
        newState = update(state, {
          pendingApplications: {
            $push: [pendingApplication],
          },
          submittingApplication: { $set: false },
        });
      } else {
        newState = update(state, {
          pendingApplications: {
            [findIndex]: { $set: pendingApplication },
          },
          submittingApplication: { $set: false },
        });
      }

      return newState;
    }
    case serviceActions.SUBMIT_APPLICATION_ERROR:
      return {
        ...state,
        submittingApplication: false,
        submitApplicationError: actions.error,
      };

    case serviceActions.GET_SERVICE_EVENT_REQUEST:
      return {
        ...state,
        gettingServiceEvent: true,
        serviceEventError: {},
      };
    case serviceActions.GET_SERVICE_EVENT_SUCCESS: {
      const { serviceEvents } = state;
      const { data, studentProgrammeId, category } = actions;

      const findIndex = serviceEvents.findIndex(
        (event) =>
          event.studentProgrammeId === studentProgrammeId &&
          event.category === category
      );

      const event = {
        ...data,
        studentProgrammeId,
        category,
      };

      let newState;

      if (findIndex === -1) {
        newState = update(state, {
          serviceEvents: {
            $push: [event],
          },
          gettingServiceEvent: { $set: false },
        });
      } else {
        newState = update(state, {
          serviceEvents: {
            [findIndex]: { $set: event },
          },
          gettingServiceEvent: { $set: false },
        });
      }

      return newState;
    }
    case serviceActions.GET_SERVICE_EVENT_ERROR:
      return {
        ...state,
        gettingServiceEvent: false,
        serviceEventError: actions.error,
      };

    case serviceActions.GET_PENDING_APPLICATIONS_REQUEST:
      return {
        ...state,
        fetchingPendingApplications: true,
        pendingApplicationError: {},
      };
    case serviceActions.GET_PENDING_APPLICATIONS_SUCCESS: {
      const { pendingApplications } = state;
      const { data, studentProgrammeId, category } = actions;

      const findIndex = pendingApplications.findIndex(
        (e) =>
          parseInt(e.studentProgrammeId, 10) ===
            parseInt(studentProgrammeId, 10) && category === e.category
      );

      const newData = {
        data,
        category,
        studentProgrammeId,
      };
      let newState;

      if (findIndex === -1) {
        newState = update(state, {
          pendingApplications: {
            $push: [newData],
          },
          fetchingPendingApplications: { $set: false },
        });
      } else {
        newState = update(state, {
          pendingApplications: {
            [findIndex]: { $set: newData },
          },
          fetchingPendingApplications: { $set: false },
        });
      }

      return newState;
    }

    case serviceActions.GET_PENDING_APPLICATIONS_ERROR:
      return {
        ...state,
        fetchingPendingApplications: false,
        pendingApplicationError: actions.error,
      };

    case serviceActions.GET_PENDING_REGISTRATION_SERVICE_REQUEST:
      return {
        ...state,
        fetchingPendingApplications: true,
        pendingApplicationError: {},
      };
    case serviceActions.GET_PENDING_REGISTRATION_SERVICE_SUCCESS:
      return {
        ...state,
        fetchingPendingApplications: false,
        pendingRegistrationApplications: actions.data,
      };
    case serviceActions.GET_PENDING_REGISTRATION_SERVICE_ERROR:
      return {
        ...state,
        fetchingPendingApplications: false,
        pendingApplicationError: actions.error,
      };

    case serviceActions.SUBMIT_REGISTRATION_APPLICATION_REQUEST:
      return {
        ...state,
        submittingApplication: true,
        submitApplicationError: {},
      };
    case serviceActions.SUBMIT_REGISTRATION_APPLICATION_SUCCESS:
      return {
        ...state,
        submittingApplication: false,
        submittedApplications: actions.data,
      };
    case serviceActions.SUBMIT_REGISTRATION_APPLICATION_ERROR:
      return {
        ...state,
        submittingApplication: false,
        submitApplicationError: actions.error,
      };

    case serviceActions.GET_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingProgrammes: true,
        getProgrammesError: {},
      };
    case serviceActions.GET_PROGRAMMES_SUCCESS:
      return {
        ...state,
        gettingProgrammes: false,
        programmes: actions.data,
      };
    case serviceActions.GET_PROGRAMMES_ERROR:
      return {
        ...state,
        gettingProgrammes: false,
        getProgrammesError: actions.error,
      };

    case serviceActions.UPDATE_APPLICATION_REQUEST:
      return {
        ...state,
        updatingApplication: true,
        updateApplicationError: {},
        updatedApplication: false,
      };
    case serviceActions.UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        updatingApplication: false,
        updatedApplication: actions.data || true,
      };
    case serviceActions.UPDATE_APPLICATION_ERROR:
      return {
        ...state,
        updatingApplication: false,
        updateApplicationError: actions.error,
      };

    case serviceActions.DELETE_PENDING_APPLICATIONS_REQUEST:
      return {
        ...state,
        deletingService: true,
        deleteServiceError: {},
      };
    case serviceActions.DELETE_PENDING_APPLICATIONS_SUCCESS:
      return {
        ...state,
        deletingService: false,
        deletedService: actions.data,
      };
    case serviceActions.DELETE_PENDING_APPLICATIONS_ERROR:
      return {
        ...state,
        deletingService: false,
        deleteServiceError: actions.error,
      };

    case serviceActions.GENERATE_SERVICE_PRN_REQUEST:
      return {
        ...state,
        generatingPRN: true,
        generatePRNError: {},
      };
    case serviceActions.GENERATE_SERVICE_PRN_SUCCESS:
      return {
        ...state,
        generatingPRN: false,
        generatedPRN: actions.data,
      };
    case serviceActions.GENERATE_SERVICE_PRN_ERROR:
      return {
        ...state,
        generatingPRN: false,
        generatePRNError: actions.error,
      };

    case serviceActions.GET_SERVICE_HISTORY_REQUEST:
      return {
        ...state,
        gettingServiceHistory: true,
        serviceHistoryError: {},
      };
    case serviceActions.GET_SERVICE_HISTORY_SUCCESS: {
      const { serviceHistories } = state;
      const { data, category } = actions;

      const findIndex = serviceHistories.findIndex(
        (e) => category === e.category
      );

      const newData = {
        data,
        category,
      };
      let newState;

      if (findIndex === -1) {
        newState = update(state, {
          serviceHistories: {
            $push: [newData],
          },
          gettingServiceHistory: { $set: false },
        });
      } else {
        newState = update(state, {
          serviceHistories: {
            [findIndex]: { $set: newData },
          },
          gettingServiceHistory: { $set: false },
        });
      }

      return newState;
    }
    case serviceActions.GET_SERVICE_HISTORY_ERROR:
      return {
        ...state,
        gettingServiceHistory: false,
        serviceHistoryError: actions.error,
      };

    case serviceActions.DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_REQUEST:
      return {
        ...state,
        downloadingServiceLetter: true,
        downloadServiceLetterError: {},
      };
    case serviceActions.DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_SUCCESS:
      return {
        ...state,
        downloadingServiceLetter: false,
        downloadServiceLetterSuccess: actions.data,
      };
    case serviceActions.DOWNLOAD_SERVICE_ACCEPTANCE_LETTER_ERROR:
      return {
        ...state,
        downloadingServiceLetter: false,
        downloadServiceLetterError: actions.error,
      };
    case serviceActions.GET_STUDENT_ID_TRACKER_REQUEST:
      return {
        ...state,
        gettingStudentIdTracker: true,
        studentIdTrackerError: {},
      };
    case serviceActions.GET_STUDENT_ID_TRACKER_SUCCESS:
      return {
        ...state,
        gettingStudentIdTracker: false,
        studentIdTracker: actions.data,
      };
    case serviceActions.GET_STUDENT_ID_TRACKER_ERROR:
      return {
        ...state,
        gettingStudentIdTracker: false,
        studentIdTrackerError: actions.error,
      };

    case serviceActions.GET_STUDENT_ID_DETAILS_REQUEST:
      return {
        ...state,
        gettingIdCardData: true,
        idCardDataError: {},
      };
    case serviceActions.GET_STUDENT_ID_DETAILS_SUCCESS:
      return {
        ...state,
        gettingIdCardData: false,
        idCardData: actions.data,
      };
    case serviceActions.GET_STUDENT_ID_DETAILS_ERROR:
      return {
        ...state,
        gettingIdCardData: false,
        idCardDataError: actions.error,
      };

    case serviceActions.VERIFY_STUDENT_ID_DETAILS_REQUEST:
      return {
        ...state,
        verifyingIdCardData: true,
        verifyError: {},
      };
    case serviceActions.VERIFY_STUDENT_ID_DETAILS_SUCCESS:
      return {
        ...state,
        verifyingIdCardData: false,
        verifySuccess: actions.data,
      };
    case serviceActions.VERIFY_STUDENT_ID_DETAILS_ERROR:
      return {
        ...state,
        verifyingIdCardData: false,
        verifyError: actions.error,
      };

    default:
      return state;
  }
}

export default service;
