const RoutePaths = {
  dashboard: {
    path: '/',
    name: 'dashboard',
    pageTitle: 'Dashboard',
  },
  login: {
    path: 'login',
    name: 'login',
    pageTitle: 'Login',
  },
  resetPassword: {
    path: 'forgot-password',
    name: 'forgot-password',
    pageTitle: 'Forgot Password',
  },
};

export default RoutePaths;
