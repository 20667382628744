const paymentReference = {
  paymentReference: {
    generatingPaymentReference: false,
    generateError: {},
    generateSuccess: {},

    generatingGraduationPRN: false,
    generateGradPRNError: {},
    graduationPaymentReference: {},

    gettingPaymentReferences: false,
    getError: {},

    paymentReferences: [],
    paymentReference: {},

    paymentReferenceHistories: [],
  },
};

export default paymentReference;
