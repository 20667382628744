const calendar = {
  GET_ACADEMIC_YEAR_EVENTS_REQUEST: 'GET_ACADEMIC_YEAR_EVENTS_REQUEST',
  GET_ACADEMIC_YEAR_EVENTS_SUCCESS: 'GET_ACADEMIC_YEAR_EVENTS_SUCCESS',
  GET_ACADEMIC_YEAR_EVENTS_ERROR: 'GET_ACADEMIC_YEAR_EVENTS_ERROR',

  getAcademicYearEvents: (currentStudentProgrammeId) => ({
    type: calendar.GET_ACADEMIC_YEAR_EVENTS_REQUEST,
    currentStudentProgrammeId,
  }),
};

export default calendar;
