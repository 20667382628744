import { get, isArray, isEmpty, sum } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  DataSpinner,
  InputText,
} from '../../../components/common';
import FormModal from '../../../components/common/FormModal';
import { paymentActions, settingActions } from '../../../config/actions';
import darkHeader from '../../../helpers/dataTableCustomStyle';

function OffsetInvoice() {
  const dispatch = useDispatch();
  const { handleSubmit, register, control, errors, watch } = useForm();
  const { showOffsetInvoiceModal } = useSelector((state) => state.setting);
  const { authUser } = useSelector((state) => state.auth);
  const {
    transactions: transactionsData,
    offsetting,
    loading,
  } = useSelector((state) => state.myTransaction);
  const [amountToAllocate, setAmountToAllocate] = useState(0);
  const [myTransactions, setMyTransactions] = useState([]);

  const watchAmounts = watch('amounts_to_allocate');

  const getTransactions = () => dispatch(paymentActions.getMyTransactions());

  useEffect(() => {
    if (isEmpty(transactionsData)) getTransactions();
  }, []);

  const closeModal = () =>
    dispatch(settingActions.showOffsetInvoiceModal(false, null));

  useEffect(() => {
    if (
      !isEmpty(transactionsData) &&
      isArray(transactionsData?.data) &&
      isArray(transactionsData?.deposits)
    ) {
      const transactions = [];
      [...transactionsData.data, ...transactionsData.deposits].forEach(
        (transaction) =>
          transactions.push(
            ...transaction.payment_transactions.filter(
              (payment) => payment.unallocated_amount > 0
            )
          )
      );
      setMyTransactions(transactions);
    } else {
      setMyTransactions([]);
    }
  }, [transactionsData]);

  useEffect(() => {
    if (watchAmounts) {
      setAmountToAllocate(sum(Object.values(watchAmounts).map(Number)));
    } else setAmountToAllocate(0);
  }, [watchAmounts]);

  const submitForm = (data) => {
    const paymentTransactions = [];

    if (data.amounts_to_allocate) {
      data.amounts_to_allocate.forEach((amount, index) => {
        if (parseInt(amount, 10) > 0) {
          paymentTransactions.push({
            id: parseInt(data.payment_ids[index], 10),
            amount: parseInt(amount, 10),
          });
        }
      });
    }
    const formData = {
      payment_transactions: paymentTransactions,
      student_id: authUser.id,
      invoice_number: showOffsetInvoiceModal.invoiceData?.invoice_number,
    };
    dispatch(
      paymentActions.offsetInvoice2(
        showOffsetInvoiceModal.invoiceData?.id,
        formData
      )
    );
  };

  const allocateButton = useCallback(
    (row) => (
      <>
        <InputText
          name={`amounts_to_allocate[${row.id}]`}
          defaultValue={0}
          type="number"
          min={0}
          max={row.unallocated_amount}
          register={register({
            min: { value: 0, message: 'Invalid Amount' },
            max: {
              value: row.unallocated_amount,
              message: `Maximum amount should be ${row.unallocated_amount}`,
            },
          })}
          control={control}
          error={get(errors, `amounts_to_allocate[${row.id}].message`)}
        />
        <InputText
          name={`payment_ids[${row.id}]`}
          defaultValue={row.id}
          type="hidden"
          register={register}
        />
      </>
    ),
    []
  );

  const columns = useMemo(
    () => [
      {
        name: 'REFERENCE No.',
        wrap: true,
        width: '150px',
        sortable: true,
        selector(row) {
          return row?.mode_reference;
        },
      },
      {
        name: 'INVOICE TYPE',
        width: '150px',
        wrap: true,
        sortable: true,
        selector(row) {
          return row?.transaction_origin;
        },
      },
      {
        name: 'UNALLOCATED',
        sortable: true,
        width: '150px',
        cell(row) {
          return parseInt(row.unallocated_amount, 10).toLocaleString();
        },
      },
      {
        name: 'AMOUNT TO ALLOCATE',
        compact: true,
        width: '150px',
        cell: allocateButton,
      },
      {
        name: 'CURRENCY',
        sortable: true,
        selector(row) {
          return row?.currency;
        },
      },
    ],
    []
  );

  return (
    <FormModal
      formTitle={`Offset Invoice ${showOffsetInvoiceModal.invoiceData?.invoice_number}`}
      defaultShow={showOffsetInvoiceModal?.show}
      onCloseModal={closeModal}
      size="lg"
      handleSubmit={handleSubmit(submitForm)}
      submitButtonProps={{
        text: 'Offset Invoice',
        disabled:
          amountToAllocate <= 0 ||
          amountToAllocate > showOffsetInvoiceModal.invoiceData?.amount_due ||
          offsetting,
        loading: offsetting,
        loadingText: 'Offsetting...',
      }}
    >
      <Row className="mb-2 text-uppercase">
        <Col md={6}>
          <InputText
            label="Invoice ID"
            defaultValue={showOffsetInvoiceModal.invoiceData?.invoice_number}
            disabled
            inline
          />
        </Col>
        <Col md={6}>
          <InputText
            label="Amount Due"
            defaultValue={`${parseInt(
              showOffsetInvoiceModal.invoiceData?.amount_due,
              10
            ).toLocaleString()} ${
              showOffsetInvoiceModal.invoiceData?.currency
            }`}
            disabled
            inline
          />
        </Col>
      </Row>
      <DataTable
        data={myTransactions}
        columns={columns}
        customStyles={darkHeader}
        className="border"
        striped
        dense
        noHeader
        progressPending={loading}
        progressComponent={<DataSpinner />}
        noDataComponent={
          <DataNotFound message="This student has No Unallocated Payment transactions" />
        }
      />
      {!isEmpty(transactionsData) && !isEmpty(showOffsetInvoiceModal) && (
        <Card.Header className="p-2 font500 text-uppercase border bg-light w-100">
          <span className="text-info">
            Total Amount To Allocate:
            {` ${amountToAllocate.toLocaleString()}`}
          </span>
          <span className="ms-1 font500">
            Balance:
            <span
              className={
                showOffsetInvoiceModal.invoiceData.amount_due -
                  amountToAllocate <
                0
                  ? 'ms-1 text-danger'
                  : 'ms-1 text-success'
              }
            >
              {(
                showOffsetInvoiceModal.invoiceData.amount_due - amountToAllocate
              ).toLocaleString()}
            </span>
          </span>
        </Card.Header>
      )}
    </FormModal>
  );
}

export default OffsetInvoice;
