import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import {
  FaCheckCircle,
  FaGraduationCap,
  FaPrint,
  FaUserFriends,
  FaUserGraduate,
  FaUserSecret,
  FaUserTimes,
  FaUsersCog,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { DataSpinner } from '../../components/common';
import {
  appActions,
  authActions,
  enrollmentActions,
  settingActions,
} from '../../config/actions';
import MenuList from '../../config/routes/MenuList';
import { formatName, whichInstitution } from '../../helpers/dataFormatter';
import AcademicDetail from './AcademicDetail';
import Avatar from './Avatar';
import BioData from './BioData';
import ChangePassword from './ChangePassword';
import GuardianDetail from './GuardianDetail';
import NextOfKin from './NextOfKin';
import PrintBioData from './PrintBioData';

function MyProfile() {
  const dispatch = useDispatch();
  const { gettingAuthUser, authUser, currentStudentProgramme } = useSelector(
    (state) => state.auth
  );
  const { institutionStructure } = useSelector((state) => state.app);
  const { currentSemester } = useSelector((state) => state.app);
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);
  const [currentTab, setCurrentTab] = useState('bio-data');

  const reloadAuthUser = () => {
    dispatch(authActions.getAuthUser());
    dispatch(enrollmentActions.getCurrentEvents(currentStudentProgramme?.id));
    dispatch(appActions.getCurrentSemester());
  };

  const isMubs = whichInstitution('MUBS', institutionStructure);

  const componentRef = useRef();

  const navigateUser = (route) => {
    const {
      BioData: { label },
    } = MenuList;

    if (!isEmpty(route)) {
      dispatch(
        settingActions.setNavigation({
          ...appNavigation,
          menuLevel_1: null,
          menuLevel_2: label,
          menuLevel_3: route,
        })
      );
    }
  };

  useEffect(() => {
    if (isEmpty(authUser)) reloadAuthUser();
  }, []);

  useEffect(() => {
    const { key } = selectedMenu;
    if (key === 'bio-data') {
      navigateUser('Personal Details');
    }
  }, []);

  const handleClick = (title, tabKey) => {
    setCurrentTab(tabKey);
    navigateUser(title);
  };

  const tabList = [
    {
      id: 1,
      title: 'Personal Details',
      key: 'bio-data',
      icon: <FaUserGraduate className="me-2" />,
    },
    {
      id: 2,
      title: 'Academic Details',
      key: 'academic-details',
      icon: <FaGraduationCap className="me-2" />,
    },
    {
      id: 3,
      title: 'Guardian Details',
      key: 'guardian-details',
      icon: <FaUsersCog className="me-2" />,
    },
    {
      id: 4,
      title: 'Next of Kin',
      key: 'next-of-kin',
      icon: <FaUserFriends className="me-2" />,
    },
    {
      id: 5,
      title: 'Change Password',
      key: 'change-password',
      icon: <FaUserSecret className="me-2" />,
    },
  ];

  const printButton = useCallback(
    () => (
      <Button type="primary" size="sm" className="font600 text-sm">
        <FaPrint className="me-1 " />
        Print Bio Data
      </Button>
    ),
    []
  );

  const pageStyle = `
    .bioData-header {
        display: none !important;
    }
    .row.my-2 {
      flex-wrap: nowrap !important;
      max-width: 50%;
    }
    div.card-body {
      padding: 0;
      margin: 0;
      border: none;
    }
  `;

  return (
    <>
      <Card>
        <Card.Header className="py-2 text-primary font600 text-sm mb-0">
          MY ACADEMIC PROFILE
          <div className="card-options">
            <ReactToPrint
              trigger={printButton}
              pageStyle={pageStyle}
              content={() => componentRef.current}
            />
          </div>
        </Card.Header>
        <Row className="row-deck gx-0 gy-2">
          <Col md={3}>
            <Card className="text-sm text-muted border-0 p-0">
              <div className="text-center p-3 border-bottom">
                <div className="mx-auto text-center pb-2">
                  <Avatar />
                </div>
                {gettingAuthUser && isEmpty(authUser) ? (
                  <DataSpinner message="Loading Profile..." />
                ) : (
                  <>
                    <div className="font600 text-primary">
                      {formatName(authUser)}
                    </div>
                    <div className="font600 text-uppercase text-xs mt-2">{`STUDENT NO.: ${
                      isMubs &&
                      !isEmpty(currentStudentProgramme?.old_student_number)
                        ? currentStudentProgramme?.old_student_number
                        : currentStudentProgramme?.student_number
                    }`}</div>

                    <Button
                      variant={
                        currentSemester?.is_registered ? 'success' : 'danger'
                      }
                      size="sm"
                      className="text-sm mt-2 font600 text-white"
                    >
                      {currentSemester?.is_registered ? (
                        <FaCheckCircle className="me-1" />
                      ) : (
                        <FaUserTimes className="me-1" />
                      )}
                      {currentSemester?.is_registered
                        ? 'REGISTERED'
                        : 'NOT REGISTERED'}
                    </Button>
                  </>
                )}
              </div>
              <ListGroup variant="flush">
                {tabList.map((tab) => (
                  <ListGroup.Item
                    key={tab.id}
                    onClick={() => handleClick(tab.title, tab.key)}
                    active={currentTab === tab.key}
                    action
                    className="fw-bold text-uppercase text-sm text-start"
                  >
                    {tab.icon}
                    {tab.title}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          </Col>
          <Col md={9}>
            <Card className="border-start border-top-0 border-bottom-0 border-end-0">
              {currentTab === 'bio-data' && <BioData authUser={authUser} />}
              {currentTab === 'next-of-kin' && (
                <NextOfKin authUser={authUser} />
              )}
              {currentTab === 'guardian-details' && (
                <GuardianDetail authUser={authUser} />
              )}
              {currentTab === 'academic-details' && (
                <AcademicDetail authUser={authUser} />
              )}
              {currentTab === 'change-password' && <ChangePassword />}
            </Card>
          </Col>
        </Row>
      </Card>

      <div className="my-2 w-100 text-center text-muted text-xs">
        Last Login
        {` ${moment(authUser.last_login).format(
          'ddd, MMM Do YYYY, h:mm:ss a'
        )}`}
      </div>
      <div className="d-none">
        <div ref={componentRef}>
          <PrintBioData authUser={authUser} />
        </div>
      </div>
    </>
  );
}

export default MyProfile;
