import { SyncOutlined } from '@ant-design/icons';
import React from 'react';
import SubmitButton from './SubmitButton';

function ReloadButton({ ...props }) {
  return (
    <SubmitButton
      iconBefore={<SyncOutlined />}
      type="dashed"
      danger
      text="RELOAD"
      loadingText="Loading..."
      className="text-sm text-uppercase font600 p-1 px-2"
      size="small"
      {...props}
    />
  );
}

export default ReloadButton;
