import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { calendarActions } from '../../actions';

function* getAcademicYearEvents(actions) {
  try {
    const response = yield axios({
      url: `/student-portal/enrollment/student-events/${actions.currentStudentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: calendarActions.GET_ACADEMIC_YEAR_EVENTS_SUCCESS,
      data: response.data,
      programmeId: actions.currentStudentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: calendarActions.GET_ACADEMIC_YEAR_EVENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAcademicYearEvents() {
  yield takeLatest(
    calendarActions.GET_ACADEMIC_YEAR_EVENTS_REQUEST,
    getAcademicYearEvents
  );
}

const forkFunctions = [fork(watchGetAcademicYearEvents)];

export default forkFunctions;
