import { DollarOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Alert, Table } from 'react-bootstrap';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitButton } from '../../../components/common';
import { serviceActions } from '../../../config/actions';
import { getPaymentStatus } from '../../../helpers/dataFormatter';

function ApplicationStatus({ application, currentTab, setIsEditing }) {
  const { generatingPRN, deletingService } = useSelector(
    (state) => state.service
  );
  const { activeProgramme } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const generatePRN = () => {
    dispatch(
      serviceActions.generateServicePRN(
        'change-of-programme',
        application?.id,
        application?.student_programme_id,
        currentTab
      )
    );
  };

  const deletePendingApplication = () => {
    dispatch(
      serviceActions.deletePendingService(
        'change-of-programme',
        application?.id,
        activeProgramme?.id,
        currentTab
      )
    );
  };

  return (
    <>
      {application.ura_prn && application.amount !== application.paid && (
        <Alert variant="success" className="text-center p-3 rounded">
          <Alert.Heading className="text-uppercase fs-5">
            Use this Reference Number{' '}
            <strong className="text-danger fs-4">{application.ura_prn}</strong>{' '}
            to Pay for Change of Programme at any our Banks.
          </Alert.Heading>

          <div className="fs-5">Expiry date: {application.expiry_date}</div>
        </Alert>
      )}
      <Table borderless responsive striped size="lg" className="border">
        <tbody>
          <tr>
            <td width={200} />
            <td width={200}>OLD PROGRAMME</td>
            <td width={200}>NEW PROGRAMME</td>
          </tr>
          <tr>
            <td>PROGRAMME</td>
            <td>{application?.studentProgramme?.programme?.programme_title}</td>
            <td>{application.newProgramme?.programme_title}</td>
          </tr>
          <tr>
            <td>SUBJECT COMBINATIONS</td>
            <td>N/A</td>
            <td>
              {map(
                application.newSubjectComb?.subjects,
                (e) => e?.subject?.subject_code
              ).join('/')}
            </td>
          </tr>
          <tr>
            <td>ENTRY STUDY YEAR</td>
            <td>{activeProgramme?.entry_study_year}</td>
            <td>{application.studyYear?.programme_study_years}</td>
          </tr>
          <tr>
            <td>STUDY TIME</td>
            <td>{activeProgramme?.programme_type}</td>
            <td>
              {application.newProgrammeType?.programmeType?.metadata_value ||
                'N/A'}
            </td>
          </tr>
          <tr>
            <td>CAMPUS</td>
            <td>{activeProgramme?.campus}</td>
            <td>{application.newCampus?.metadata_value}</td>
          </tr>
          <tr>
            <td>ACADEMIC YEAR</td>
            <td>{activeProgramme?.entry_academic_year}</td>
            <td>{application.academicYear?.metadata_value}</td>
          </tr>
          <tr>
            <td>REASON FOR CHANGE</td>
            <td>N/A</td>
            <td>{application?.reason || 'N/A'}</td>
          </tr>
          <tr>
            <td>APPLICATION DATE</td>
            <td />
            <td>{moment(application.created_at).format('LLLL')}</td>
          </tr>
          <tr>
            <td>APPLICATION STATUS</td>
            <td />
            <td>{application.request_status}</td>
          </tr>
          <tr>
            <td>AMOUNT BILLED</td>
            <td />
            <td>{`${parseInt(application?.amount, 10).toLocaleString()} ${
              application.currency
            }`}</td>
          </tr>
          <tr>
            <td>AMOUNT PAID</td>
            <td />
            <td>
              {`${parseInt(application?.paid, 10).toLocaleString()} ${
                application.currency
              }`}
            </td>
          </tr>
          <tr>
            <td>PAYMENT STATUS</td>
            <td />
            <td>{getPaymentStatus(application?.payment_status)}</td>
          </tr>
          <tr>
            <td>PAYMENT REFERENCE</td>
            <td />
            <td>{application?.ura_prn || 'NOT GENERATED'}</td>
          </tr>
        </tbody>
      </Table>

      <div className="text-center">
        <SubmitButton
          text="GENERATE PAYMENT REFERENCE"
          size="small"
          iconBefore={<DollarOutlined />}
          loading={generatingPRN}
          loadingText="Generating..."
          onClick={generatePRN}
          className="me-2 fw-bold"
        />
        {application.request_status === 'PENDING' && (
          <>
            <Button
              size="small"
              type="primary"
              className="text-xs me-2 fw-bold text-uppercase"
              onClick={() => setIsEditing(true)}
              icon={<FaPencilAlt className="mx-1" />}
            >
              Edit Application
            </Button>
            <Popconfirm
              title="Delete Application"
              description="Are you sure you want to delete Change of Programme Request?"
              onConfirm={deletePendingApplication}
              okButtonProps={{
                loading: deletingService,
              }}
            >
              <Button
                size="small"
                danger
                type="primary"
                loading={deletingService}
                icon={<FaTrash />}
              >
                DELETE
              </Button>
            </Popconfirm>
          </>
        )}
        {application.request_status === 'ACCEPTED' && (
          <SubmitButton
            text="DOWNLOAD ACCEPTANCE LETTER"
            size="small"
            className="text-xs me-2 fw-bold text-uppercase"
            onClick={() => setIsEditing(true)}
            iconBefore={<FaPencilAlt className="mx-1" />}
          />
        )}
      </div>
    </>
  );
}

ApplicationStatus.propTypes = {
  application: PropTypes.oneOfType([object]).isRequired,
  setIsEditing: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
};

export default ApplicationStatus;
