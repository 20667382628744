const enrollment = {
  GET_CURRENT_EVENTS_REQUEST: 'GET_CURRENT_EVENTS_REQUEST',
  GET_CURRENT_EVENTS_SUCCESS: 'GET_CURRENT_EVENTS_SUCCESS',
  GET_CURRENT_EVENTS_ERROR: 'GET_CURRENT_EVENTS_ERROR',

  GET_ENROLLMENT_HISTORY_REQUEST: 'GET_ENROLLMENT_HISTORY_REQUEST',
  GET_ENROLLMENT_HISTORY_SUCCESS: 'GET_ENROLLMENT_HISTORY_SUCCESS',
  GET_ENROLLMENT_HISTORY_ERROR: 'GET_ENROLLMENT_HISTORY_ERROR',

  ENROLL_STUDENT_REQUEST: 'ENROLL_STUDENT_REQUEST',
  ENROLL_STUDENT_SUCCESS: 'ENROLL_STUDENT_SUCCESS',
  ENROLL_STUDENT_ERROR: 'ENROLL_STUDENT_ERROR',

  GET_MIGRATED_ENROLLMENT_REQUEST: 'GET_MIGRATED_ENROLLMENT_REQUEST',
  GET_MIGRATED_ENROLLMENT_SUCCESS: 'GET_MIGRATED_ENROLLMENT_SUCCESS',
  GET_MIGRATED_ENROLLMENT_ERROR: 'GET_MIGRATED_ENROLLMENT_ERROR',

  SET_REDIRECT_TO_ENROLLMENT: 'SET_REDIRECT_TO_ENROLLMENT',

  getCurrentEvents: (currentStudentProgrammeId) => ({
    type: enrollment.GET_CURRENT_EVENTS_REQUEST,
    currentStudentProgrammeId,
  }),

  getEnrollmentHistory: (currentStudentProgrammeId) => ({
    type: enrollment.GET_ENROLLMENT_HISTORY_REQUEST,
    currentStudentProgrammeId,
  }),

  enrollStudent: (data) => ({
    type: enrollment.ENROLL_STUDENT_REQUEST,
    data,
  }),

  getMigratedEnrollments: (studentProgrammeId) => ({
    type: enrollment.GET_MIGRATED_ENROLLMENT_REQUEST,
    studentProgrammeId,
  }),

  redirectToEnrollment: (payload) => ({
    type: enrollment.SET_REDIRECT_TO_ENROLLMENT,
    payload,
  }),
};

export default enrollment;
