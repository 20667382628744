import { Image } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import PropTypes, { array, object } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import DefaultLogo from '../../../assets/img/default.png';
import SemesterFees from './SemesterFees';

class PrintFeesStructure extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      contextFeesStructure,
      institutionStructure,
      currentStudentProgramme,
    } = this.props;

    const institutionLogo = `${process.env.REACT_APP_INSTITUTION_LOGO_PATH}/${institutionStructure?.institution_logo}`;

    return (
      <div className="bg-white">
        <Row className="g-0">
          <Col className="col-2 d-flex align-self-center justify-content-center">
            <Image
              src={institutionLogo}
              fallback={DefaultLogo}
              alt="Logo"
              preview={false}
              style={{ maxWidth: '100px' }}
            />
          </Col>
          <Col className="col-10 text-uppercase fw-bold">
            <h5 className="text-uppercase fs-4 fw-bold">
              {institutionStructure?.institution_name}
            </h5>
            <div className="fw-bold my-2 text-md">Office of the Bursar</div>
            <div className="mb-2">FEES STRUCTURE</div>
            <div className="text-muted">
              <small>
                PRINT DATE: {moment().format('dddd, MMMM Do YYYY, h:mm:ss a')}
              </small>
            </div>
          </Col>
        </Row>

        <Table bordered size="sm" striped className="my-3 text-sm">
          <tbody>
            <tr>
              <td>PROGRAMME</td>
              <td colSpan={3}>{currentStudentProgramme.programme_title}</td>
            </tr>
            <tr>
              <td>PROG CODE</td>
              <td>{currentStudentProgramme.programme_code}</td>

              <td>VERSION</td>
              <td>{currentStudentProgramme.version_title}</td>
            </tr>
            <tr>
              <td>NATIONALITY</td>
              <td>{currentStudentProgramme.billing_category}</td>

              <td>CAMPUS</td>
              <td>{currentStudentProgramme.campus}</td>
            </tr>
            <tr>
              <td>INTAKE</td>
              <td>{currentStudentProgramme.intake}</td>

              <td>SPONSORSHIP</td>
              <td>{currentStudentProgramme.sponsorship}</td>
            </tr>
          </tbody>
        </Table>

        {map(contextFeesStructure, (item) => (
          <Fragment key={`${item.programme_study_year_id}-`}>
            <SemesterFees
              fees={item.semesterOne}
              semester={`${item.programme_study_year} SEMESTER I`}
            />

            <SemesterFees
              fees={item.semesterTwo}
              semester={`${item.programme_study_year} SEMESTER II`}
            />
          </Fragment>
        ))}
      </div>
    );
  }
}

PrintFeesStructure.propTypes = {
  contextFeesStructure: PropTypes.oneOfType([array]).isRequired,
  currentStudentProgramme: PropTypes.oneOfType([object]).isRequired,
  institutionStructure: PropTypes.oneOfType([object]).isRequired,
};

export default PrintFeesStructure;
