import PropTypes, { array } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FaUserGraduate } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { Tag } from 'antd';
import AccordionHeader from '../../components/common/AccordionHeader';
import SingleEnrollment from './SingleEnrollment';
import { calendarActions } from '../../config/actions';

function Enrollments({ enrollmentHistories }) {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(1);
  const { calendarYearEvents } = useSelector((state) => state.calendar);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { currentSemester } = useSelector((state) => state.app);

  useEffect(() => {
    if (isEmpty(calendarYearEvents)) {
      dispatch(
        calendarActions.getAcademicYearEvents(currentStudentProgramme.id)
      );
    }
  }, []);

  return (
    <Accordion defaultActiveKey="1" activeKey={activeKey}>
      {enrollmentHistories.map((enrollment, index) => (
        <Accordion.Item
          eventKey={index + 1}
          className="mb-2"
          key={enrollment.id}
        >
          <AccordionHeader
            activeKey={activeKey}
            eventKey={index + 1}
            setActiveKey={setActiveKey}
          >
            <FaUserGraduate className="me-2" />
            {` ${enrollment?.studyYear?.programme_study_years}, ${enrollment.event?.semester?.semester?.metadata_value} - ${enrollment.event.academicYear?.academicYear?.metadata_value}`}
            {isEqual(
              enrollment.event?.semester?.semester?.metadata_value,
              calendarYearEvents[0]?.semester
            ) &&
              isEqual(
                enrollment?.event?.academicYear?.academicYear?.metadata_value,
                calendarYearEvents[0]?.academic_year
              ) &&
              isEqual(
                currentSemester?.semester,
                enrollment.event?.semester?.semester?.metadata_value
              ) && (
                <span className="mx-3">
                  <Tag color="green">CURRENT</Tag>
                </span>
              )}
          </AccordionHeader>
          <Accordion.Body>
            <SingleEnrollment
              event={
                enrollment.event
                  ? {
                      academic_year:
                        enrollment.event.academicYear?.academicYear
                          ?.metadata_value,
                      semester:
                        enrollment.event?.semester?.semester?.metadata_value,
                    }
                  : {}
              }
              enrollment={enrollment}
            />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

Enrollments.defaultProps = {
  enrollmentHistories: [],
};

Enrollments.propTypes = {
  enrollmentHistories: PropTypes.oneOfType([array]),
};

export default Enrollments;
