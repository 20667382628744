import { PrinterOutlined } from '@ant-design/icons';
import { find, isEmpty, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import {
  DataNotFound,
  DataSpinner,
  ReloadButton,
  SubmitButton,
} from '../../../components/common';
import { paymentActions } from '../../../config/actions';
import PrintFeesStructure from './PrintFeesStructure';
import SemesterFees from './SemesterFees';

function MyFeesStructure() {
  const dispatch = useDispatch();
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { myFeesStructure, gettingFeesStructure } = useSelector(
    (state) => state.myTransaction
  );
  const [contextFeesStructure, setContextFeesStructure] = useState([]);
  const { institutionStructure } = useSelector((state) => state.app);

  const getFeesStructure = () => {
    if (currentStudentProgramme) {
      dispatch(paymentActions.getFeesStructure(currentStudentProgramme.id));
    }
  };

  const getContextFees = () => {
    const findFees = find(
      myFeesStructure,
      (f) => f.studentProgrammeId === currentStudentProgramme.id
    );
    return findFees?.feesStructure || [];
  };

  useEffect(() => {
    if (isEmpty(getContextFees())) getFeesStructure();
  }, []);

  useEffect(() => {
    if (myFeesStructure) setContextFeesStructure(getContextFees());
  }, [myFeesStructure]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printButton = useCallback(() => (
    <SubmitButton
      text="Print"
      size="small"
      onClick={handlePrint}
      className="ms-2 text-uppercase fw-bold text-sm"
      iconBefore={<PrinterOutlined className="me-2 text-sm" />}
    />
  ));

  const pageStyle = `
    @page {
      size: auto;
      margin: 16mm;
      font-size: 12px;
    }
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @media all {
      .pagebreak {
        display: none;
      }
    }
  `;

  return (
    <Card>
      <Card.Header className="py-2">
        <span className="font500 text-uppercase text-info">Fees Structure</span>
        <div className="card-options">
          <ReloadButton
            loading={gettingFeesStructure}
            onClick={getFeesStructure}
          />
          {!isEmpty(contextFeesStructure) && (
            <ReactToPrint
              trigger={printButton}
              content={() => componentRef.current}
              pageStyle={pageStyle}
              documentTitle="FEES STRUCTURE"
            />
          )}
        </div>
      </Card.Header>
      {gettingFeesStructure && isEmpty(contextFeesStructure) ? (
        <DataSpinner text="Loading Fees Structure" />
      ) : (
        <>
          {!isEmpty(contextFeesStructure) ? (
            <Card.Body>
              <Accordion defaultActiveKey={0}>
                {uniqBy(
                  contextFeesStructure,
                  'programme_study_year_id'
                ).flatMap((item, index) => (
                  <Accordion.Item
                    eventKey={index}
                    key={`${item.programme_study_year_id}-`}
                  >
                    <Accordion.Header>
                      {item.programme_study_year}
                    </Accordion.Header>
                    <Accordion.Body className="p-2">
                      <SemesterFees
                        fees={item.semesterOne}
                        semester="SEMESTER I"
                      />

                      <SemesterFees
                        fees={item.semesterTwo}
                        semester="SEMESTER II"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Card.Body>
          ) : (
            <DataNotFound />
          )}
        </>
      )}

      <div style={{ display: 'none' }}>
        <PrintFeesStructure
          contextFeesStructure={contextFeesStructure}
          ref={componentRef}
          currentStudentProgramme={currentStudentProgramme}
          institutionStructure={institutionStructure}
        />
      </div>
    </Card>
  );
}

export default MyFeesStructure;
