import React from 'react';
import { CiImageOff } from 'react-icons/ci';
import PropTypes from 'prop-types';

function NoCardImage({ side }) {
  return (
    <>
      <div
        className="d-flex flex-column align-items-center mt-4"
        style={{ maxHeight: '210px' }}
      >
        <CiImageOff size={60} color="gray" />
        <div className="fw-bold text-danger text-uppercase">{`NO ${side} IMAGE FOUND`}</div>
      </div>
    </>
  );
}

NoCardImage.propTypes = {
  side: PropTypes.string.isRequired,
};

export default NoCardImage;
