import { paymentActions } from '../../actions';
import initialState from '../../initialState';

function myInvoice(state = initialState.myInvoice, actions) {
  switch (actions.type) {
    case paymentActions.GET_MY_INVOICES_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case paymentActions.GET_MY_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: actions.data,
        loading: false,
      };
    case paymentActions.GET_MY_INVOICES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case paymentActions.GET_MY_GRADUATION_INVOICES_REQUEST:
      return {
        ...state,
        gettingGraduationInvoices: true,
        graduationInvoiceError: {},
      };
    case paymentActions.GET_MY_GRADUATION_INVOICES_SUCCESS:
      return {
        ...state,
        graduationInvoices: actions.data,
        gettingGraduationInvoices: false,
      };
    case paymentActions.GET_MY_GRADUATION_INVOICES_ERROR:
      return {
        ...state,
        graduationInvoiceError: actions.error,
        gettingGraduationInvoices: false,
      };
    case paymentActions.SET_INVOICE_TOTAL_DUE:
      return {
        ...state,
        invoiceTotalDue: actions.data,
      };
    case paymentActions.SET_SHOULD_ALLOCATE_FUNDS:
      return {
        ...state,
        shouldAllocate: actions.payload,
      };

    default:
      return state;
  }
}

export default myInvoice;
