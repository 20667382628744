/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';
import { ModalBody } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Slider, Switch } from 'antd';
import { isEmpty, map, toLower } from 'lodash';
import FormModal from '../../components/common/FormModal';
import { settingActions } from '../../config/actions';
import {
  storeThemeSettings,
  defaultThemeSettings,
  getThemeSettings,
} from '../../helpers/themeSetting';

function ThemeSettingModal({ showThemeModal, darkMode, setDarkMode }) {
  const theme = getThemeSettings();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const closeThemeModal = () => dispatch(settingActions.setThemeModal(false));
  const resetTheme = () => {
    const newData = {
      ...defaultThemeSettings,
      isDarkMode: darkMode,
    };

    form.setFieldsValue(newData);
    setFormData(newData);
  };

  useEffect(() => {
    if (!isEmpty(theme)) {
      setFormData(theme);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(formData)) {
      if (formData?.isDarkMode === true) {
        DarkReader.enable(formData);
      } else {
        DarkReader.disable();
      }
    }
    storeThemeSettings(formData);
  }, [formData]);

  useEffect(() => {
    if (!isEmpty(theme)) {
      form.setFieldsValue({
        ...theme,
        brightness: theme?.brightness,
        contrast: theme?.contrast,
        sepia: theme?.sepia,
        isDarkMode: darkMode,
        auto: theme?.auto,
      });
    }
  }, []);

  const dataTemplate = useMemo(() => [
    {
      type: 'switch',
      name: 'isDarkMode',
      label: 'Theme',
      value: darkMode,
    },
    {
      type: 'slider',
      label: 'Brightness',
      value: theme?.brightness,
    },
    {
      type: 'slider',
      label: 'Contrast',
      value: theme?.contrast,
    },
    {
      type: 'slider',
      label: 'Sepia',
      value: theme?.sepia,
    },
  ]);

  return (
    <div>
      <FormModal
        formTitle="DARK THEME SETTINGS"
        defaultShow={showThemeModal}
        backdrop="static"
        size="lg"
        onCloseModal={() => closeThemeModal()}
        handleSubmit={resetTheme}
        submitButtonProps={{
          text: 'RESET',
        }}
      >
        <ModalBody>
          <Form
            form={form}
            labelCol={{ className: 'pb-1 fw-bold text-sm', span: 3 }}
            onValuesChange={(changedValues) =>
              setFormData((prevState) => ({ ...prevState, ...changedValues }))
            }
          >
            {map(dataTemplate, (setting) => (
              <Form.Item
                key={setting.label}
                name={setting?.name || toLower(setting.label)}
                label={setting.label}
                className="mb-1"
              >
                {(setting.type === 'slider' && (
                  <Slider value={setting.value} min={0} max={100} />
                )) || (
                  <Switch
                    checked={setting?.value}
                    checkedChildren={<span>Dark</span>}
                    unCheckedChildren={<span>Light</span>}
                    onChange={() => setDarkMode(!darkMode)}
                  />
                )}
              </Form.Item>
            ))}
          </Form>
        </ModalBody>
      </FormModal>
    </div>
  );
}
ThemeSettingModal.propTypes = {
  showThemeModal: PropTypes.bool.isRequired,
  darkMode: PropTypes.bool.isRequired,
  setDarkMode: PropTypes.func.isRequired,
};
export default ThemeSettingModal;
