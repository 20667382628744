const paymentReference = {
  GENERATE_PAYMENT_REFERENCE_REQUEST: 'GENERATE_PAYMENT_REFERENCE_REQUEST',
  GENERATE_PAYMENT_REFERENCE_SUCCESS: 'GENERATE_PAYMENT_REFERENCE_SUCCESS',
  GENERATE_PAYMENT_REFERENCE_ERROR: 'GENERATE_PAYMENT_REFERENCE_ERROR',

  GETTING_PAYMENT_REFERENCES_REQUEST: 'GETTING_PAYMENT_REFERENCES_REQUEST',
  GETTING_PAYMENT_REFERENCES_SUCCESS: 'GETTING_PAYMENT_REFERENCES_SUCCESS',
  GETTING_PAYMENT_REFERENCES_ERROR: 'GETTING_PAYMENT_REFERENCES_ERROR',

  GENERATE_GRADUATION_INVOICE_PRN_REQUEST:
    'GENERATE_GRADUATION_INVOICE_PRN_REQUEST',
  GENERATE_GRADUATION_INVOICE_PRN_SUCCESS:
    'GENERATE_GRADUATION_INVOICE_PRN_SUCCESS',
  GENERATE_GRADUATION_INVOICE_PRN_ERROR:
    'GENERATE_GRADUATION_INVOICE_PRN_ERROR',

  generatePaymentReference: (
    data,
    referenceType = 'invoice',
    studentProgrammeId
  ) => ({
    type: paymentReference.GENERATE_PAYMENT_REFERENCE_REQUEST,
    data,
    referenceType,
    studentProgrammeId,
  }),

  getPaymentReferences: (data) => ({
    type: paymentReference.GETTING_PAYMENT_REFERENCES_REQUEST,
    data,
  }),

  generateGraduationInvoicePRN: (id) => ({
    type: paymentReference.GENERATE_GRADUATION_INVOICE_PRN_REQUEST,
    id,
  }),
};

export default paymentReference;
