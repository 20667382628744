import AcademicCalendar from '../../containers/AcademicCalendar';
import EnrollmentHistory from '../../containers/EnrollmentHistory';
import CurrentEvents from '../../containers/Events/CurrentEvents';
import MigratedEnrollment from '../../containers/MigratedEnrollment';
import MyProfile from '../../containers/MyProfile';
import PaymentReferences from '../../containers/PaymentReferences';
import MyFeesStructure from '../../containers/Payments/MyFeesStructure';
import MyInvoices from '../../containers/Payments/MyInvoices';
import MigratedTransactions from '../../containers/Payments/MigratedTransactions';
import SearchPayment from '../../containers/Payments/SearchPayment';
import MyLedger from '../../containers/Payments/MyLedger';
import MyTransactions from '../../containers/Payments/MyTransactions';
import RegistrationHistory from '../../containers/RegistrationHistory';
import Result from '../../containers/Results';
import Services from '../../containers/Services';
import ServiceHistory from '../../containers/Services/ServiceHistory';
import ComingSoon from '../../containers/Pages/ComingSoon';
import MenuList from './MenuList';
import IdentificationCard from '../../containers/Services/IdentificationCard';
import FinanceClearance from '../../containers/Finance Clearance';
import TeachingTimetable from '../../containers/Timetable';

export const getMenuComponent = (menuAction) => {
  let component;

  switch (menuAction) {
    case MenuList.BioData.action:
      component = MyProfile;
      break;

    case MenuList.PaymentReference.action:
      component = PaymentReferences;
      break;

    case MenuList.SelfEnrollment.action:
      component = CurrentEvents;
      break;

    case MenuList.EnrollmentHistory.action:
      component = EnrollmentHistory;
      break;

    case MenuList.RegistrationHistory.action:
      component = RegistrationHistory;
      break;

    case MenuList.MigratedEnrollment.action:
      component = MigratedEnrollment;
      break;

    case MenuList.MyInvoice.action:
      component = MyInvoices;
      break;

    case MenuList.MyTransaction.action:
      component = MyTransactions;
      break;

    case MenuList.PreviousTransaction.action:
      component = MigratedTransactions;
      break;

    case MenuList.studentPaymentLedger.action:
      component = MyLedger;
      break;

    case MenuList.myFeesStructure.action:
      component = MyFeesStructure;
      break;

    case MenuList.AcademicCalendar.action:
      component = AcademicCalendar;
      break;

    case MenuList.Services.action:
      component = Services;
      break;

    case MenuList.ServiceHistory.action:
      component = ServiceHistory;
      break;

    case MenuList.MyResults.action:
      component = Result;
      break;

    case MenuList.SearchPayment.action:
      component = SearchPayment;
      break;
    case MenuList.IdentificationCard.action:
      component = IdentificationCard;
      break;
    case MenuList.FinanceClearance.action:
      component = FinanceClearance;
      break;
    case MenuList.TeachingTimetable.action:
      component = TeachingTimetable;
      break;
    default:
      component = ComingSoon;
      break;
  }

  return component;
};

export default MenuList;
