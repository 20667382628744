import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React, { useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  ModalBody,
  ModalFooter,
  Row,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FaPrint } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { CustomModal, SubmitButton } from '../../components/common';
import EnrollmentCard from './EnrollmentCard';
import GeneratePaymentReference from './GeneratePaymentReference';

function SingleEnrollment({ event, enrollment }) {
  const componentRef = useRef();
  const [showCardModal, setShowCardModal] = useState(false);

  const printHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Card className="mb-2">
        <Card.Header className="py-2 text-info font600 text-xs">
          ENROLLMENT DETAILS
          {!isEmpty(enrollment) && (
            <div className="card-options">
              <Button
                size="sm"
                variant="info"
                className="text-sm fw-bold me-2"
                disabled={false}
                onClick={() => setShowCardModal(true)}
              >
                <FaPrint className="me-2" />
                PRINT PROOF OF ENROLLMENT
              </Button>
            </div>
          )}
        </Card.Header>
      </Card>
      <Row className="row-deck g-3 text-muted">
        <Col md={4} className="mb-3">
          <Card body className="py-1 bg-light">
            <p className="text-sm">
              <span className="font600 text-uppercase me-1">
                Academic Year:
              </span>
              {event.academic_year}
            </p>
            <p className="text-sm mb-0">
              <span className="font600 text-uppercase me-1">SEMESTER:</span>
              {event.semester}
              <span className="font600 text-uppercase mx-1">STUDY YEAR:</span>
              {enrollment.studyYear?.programme_study_years}
            </p>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card body className="py-1 bg-light">
            <p className="text-sm">
              <span className="font600 text-uppercase me-1">ENROLLED AS:</span>
              {enrollment.enrollmentStatus?.metadata_value}
            </p>
            <p className="text-sm mb-0">
              <span className="font600 text-uppercase me-1">ENROLLED BY:</span>
              {enrollment.enrolled_by === 'STUDENT'
                ? 'SELF'
                : enrollment.enrolled_by}
            </p>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card body className="py-1 bg-light">
            <p className="text-sm">
              <span className="font600 text-uppercase me-1">
                Enrollment Token:
              </span>
              {enrollment.enrollment_token}
            </p>
            <p className="text-sm mb-0">
              <span className="font600 text-uppercase me-1">Enrolled on:</span>
              {moment(enrollment.created_at).format(
                'ddd, MMM Do YYYY, h:mm:ss a'
              )}
            </p>
          </Card>
        </Col>
      </Row>
      <div className="mb-3">
        <GeneratePaymentReference enrollment={enrollment} />
      </div>

      {showCardModal && (
        <CustomModal
          show={showCardModal}
          onCloseModal={() => setShowCardModal(false)}
          title="PROOF OF ENROLLMENT"
          scrollable
          centered
          size="lg"
        >
          <Helmet>
            <title>PROOF OF ENROLLMENT</title>
          </Helmet>
          <ModalBody className="text-sms" ref={componentRef}>
            <EnrollmentCard record={{ event, enrollment }} />
          </ModalBody>
          <ModalFooter className="py-2 bg-light">
            <SubmitButton
              htmlType="button"
              type="primary"
              danger
              text="CANCEL"
              onClick={() => setShowCardModal(false)}
            />
            <SubmitButton
              htmlType="button"
              text="PRINT"
              iconBefore={<FaPrint className="me-2" />}
              onClick={printHandler}
            />
          </ModalFooter>
        </CustomModal>
      )}
    </>
  );
}

SingleEnrollment.defaultProps = {
  enrollment: {},
  event: {},
};

SingleEnrollment.propTypes = {
  enrollment: PropTypes.oneOfType([object]),
  event: PropTypes.oneOfType([object]),
};

export default SingleEnrollment;
