import { isEmpty } from 'lodash';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageLoader } from './components/common';
import { appActions } from './config/actions';
import RoutePaths from './config/routes/RoutePaths';
import Dashboard from './containers/Dashboard';
import PageNotFound from './containers/Errors/PageNotFound';
import usePrevious from './containers/Hooks/usePrevious';
import './custom.scss';
import { getThemeSettings } from './helpers/themeSetting';
import ToggleTheme from './components/shared/ToggleTheme';

function App() {
  const dispatch = useDispatch();
  const settings = getThemeSettings();
  const [darkMode, setDarkMode] = useState(settings?.isDarkMode || false);
  const { serverSuccess, serverError } = useSelector((state) => state.server);
  const { metadata, institutionStructure } = useSelector((state) => state.app);
  const prevState = usePrevious({ serverSuccess, serverError });

  useEffect(() => {
    if (isEmpty(institutionStructure)) {
      dispatch(appActions.getInstitutionStructure());
    }
    if (isEmpty(metadata)) {
      dispatch(appActions.getMetadata());
    }
  }, []);

  useEffect(() => {
    // Prevent Going back to previous page
    window.history.pushState(null, document.title, '/');
    window.addEventListener('popstate', () => {
      // TO view the events, pass event as a prop to the function

      window.history.pushState(null, document.title, '/');
    });
  });

  useEffect(() => {
    if (!isEmpty(prevState)) {
      if (!isEmpty(serverError) && prevState.serverError !== serverError) {
        toast.error(
          serverError?.error?.message ||
            serverError?.error ||
            serverError?.server?.message,
          {
            toastId:
              serverError?.error?.message ||
              serverError?.error ||
              serverError?.server?.message,
          }
        );
      }
      if (
        !isEmpty(serverSuccess) &&
        prevState.serverSuccess !== serverSuccess
      ) {
        toast.success(serverSuccess?.server?.message, {
          toastId: serverSuccess?.server?.message,
        });
      }
    }
  }, [serverError, serverSuccess]);

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route index path={RoutePaths.dashboard.path} element={<Dashboard />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ToggleTheme darkMode={darkMode} setDarkMode={setDarkMode} />
    </Suspense>
  );
}

export default App;
