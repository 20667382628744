import { CodeOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Card, Form } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AntDInputText, SubmitButton } from '../../components/common';
import { authActions } from '../../config/actions';
import RoutePaths from '../../config/routes/RoutePaths';
import usePrevious from '../Hooks/usePrevious';

function ResetForm({ setCurrentPage, setResetSuccess, resetSuccess }) {
  const dispatch = useDispatch();
  const { requestingToken, resettingPassword } = useSelector(
    (state) => state.auth
  );
  const {
    resetPasswordError,
    requestTokenError,
    resetPasswordSuccess,
    requestTokenSuccess,
  } = useSelector((state) => state.auth);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const previousState = usePrevious({
    requestTokenError,
    requestTokenSuccess,
    resetPasswordError,
    resetPasswordSuccess,
  });

  useEffect(() => {
    setErrorMessage(null);
    setSuccessMessage(null);
    if (!isEmpty(previousState)) {
      if (
        !isEmpty(requestTokenError) &&
        previousState.requestTokenError !== requestTokenError
      ) {
        setErrorMessage(
          requestTokenError?.error || requestTokenError?.server?.message
        );
      }
      if (
        previousState.requestTokenSuccess !== requestTokenSuccess &&
        requestTokenSuccess?.server
      ) {
        setSuccessMessage(requestTokenSuccess?.server?.message);
        setResetSuccess('student');
      }
    }
  }, [requestTokenError, requestTokenSuccess]);

  useEffect(() => {
    if (!isEmpty(previousState)) {
      if (
        !isEmpty(resetPasswordError) &&
        previousState.resetPasswordError !== resetPasswordError
      ) {
        setErrorMessage(
          resetPasswordError?.error || resetPasswordError.server.message
        );
      }
      if (
        previousState.resetPasswordSuccess !== resetPasswordSuccess &&
        resetPasswordSuccess?.server
      ) {
        setCurrentPage(RoutePaths.login.path);
      }
    }
  }, [resetPasswordError, resetPasswordSuccess]);

  const onSubmit = (data) => {
    setErrorMessage(null);
    if (!isEmpty(data) && resetSuccess !== 'not used') {
      dispatch(authActions.resetPassword(data));
    } else {
      dispatch(authActions.requestToken(data));
    }
  };

  return (
    <Card className="border shadow-sm ">
      <div className="py-1 text-center fw-bold mb-4">RESET YOUR PASSWORD</div>
      {(errorMessage || successMessage) && (
        <Alert
          type={errorMessage ? 'error' : 'success'}
          message={errorMessage || successMessage}
          className="mb-3"
          showIcon
        />
      )}
      <Form onFinish={onSubmit} layout="vertical">
        <AntDInputText
          name="username"
          label="Enter your Student Number"
          inputAttributes={{
            prefix: <UserOutlined />,
            placeholder: 'Student Number or Reg. Number',
            readOnly: resetSuccess === 'student',
          }}
          rules={[
            {
              required: true,
              message: 'Enter your Student Number to recover your account',
            },
            { min: 7, message: 'Username is too short' },
            { max: 15, message: 'Username is too long' },
          ]}
        />

        {resetSuccess !== 'not used' && (
          <>
            <AntDInputText
              name="otp_code"
              label="Reset Token"
              inputAttributes={{
                prefix: <CodeOutlined />,
                placeholder: 'Reset Token',
              }}
              rules={[
                {
                  required: true,
                  message:
                    'Enter the One Time Password sent to your email or phone',
                },
                { min: 5, message: 'Code is too short' },
                { mx: 6, message: 'Code is too long' },
              ]}
            />

            <AntDInputText
              name="new_password"
              label="New Password"
              type="password"
              inputAttributes={{
                prefix: <LockOutlined />,
                placeholder: 'Password',
              }}
              rules={[
                {
                  required: true,
                  message: 'Enter your new password',
                },
                {
                  min: 6,
                  message: 'Minimum password length should 6 characters',
                },
                {
                  max: 16,
                  message: 'Maximum password length should 16 characters',
                },
              ]}
            />

            <AntDInputText
              name="confirm_password"
              label="Confirm New Password"
              type="password"
              inputAttributes={{
                prefix: <LockOutlined />,
                placeholder: 'Password',
              }}
              rules={[
                {
                  required: true,
                  message: 'Confirm your New Password',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords Do Not Match!'));
                  },
                }),
              ]}
            />
          </>
        )}

        <SubmitButton
          loading={requestingToken || resettingPassword}
          loadingText={
            resetSuccess !== 'not used'
              ? 'Resetting Password...'
              : 'Requesting...'
          }
          block
          text={
            resetSuccess !== 'not used' ? 'Reset Password' : 'Request Token'
          }
        />
      </Form>
    </Card>
  );
}

ResetForm.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  resetSuccess: PropTypes.string.isRequired,
  setResetSuccess: PropTypes.func.isRequired,
};

export default ResetForm;
