/* eslint-disable jsx-a11y/control-has-associated-label */
import { isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Card, Table } from 'react-bootstrap';

function GraduationInvoiceDetails({ invoiceDetails }) {
  return (
    <>
      {!isEmpty(invoiceDetails) && (
        <>
          <Card body className="border-0">
            <Table>
              <thead className="fw-bold">
                <td>CODE.</td>
                <td>ELEMENT</td>
                <td>CURRENCY</td>
                <td>AMOUNT</td>
              </thead>
              <tbody>
                {invoiceDetails.fees_elements.map((element) => (
                  <tr key={element.id}>
                    <td>{element?.fees_element_code}</td>
                    <td>{element?.fees_element_name}</td>
                    <td>{element?.currency}</td>
                    <td>{parseInt(element?.amount, 10).toLocaleString()}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2} className="border-0" />
                  <td className="border-0" />
                </tr>
                <tr className="mt-3 border-top-3">
                  <td colSpan={2} className="border-0" />
                  <td className="text-info">TOTAL AMOUNT BILLED</td>
                  <td className="text-info fs-5">
                    {parseInt(
                      invoiceDetails.invoice_amount,
                      10
                    ).toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="border-0" />
                  <td className="text-success">AMOUNT PAID</td>
                  <td>
                    <strong className="text-success fs-5">
                      {parseInt(
                        invoiceDetails.amount_paid,
                        10
                      ).toLocaleString()}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="border-0" />
                  <td>AMOUNT DUE</td>
                  <td className="text-danger fs-5">
                    {parseInt(invoiceDetails.amount_due, 10).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </>
      )}
    </>
  );
}

GraduationInvoiceDetails.propTypes = {
  invoiceDetails: PropTypes.oneOfType([object]).isRequired,
};

export default GraduationInvoiceDetails;
