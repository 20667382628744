import { get, isArray, sumBy } from 'lodash';
import PropTypes, { any } from 'prop-types';
import React, { useCallback } from 'react';
import { Accordion } from 'react-bootstrap';
import { FaPaperclip } from 'react-icons/fa';
import { AntDTable, InputText } from '../../components/common';
import AccordionHeader from '../../components/common/AccordionHeader';

function InvoiceCard({
  register,
  errors,
  activeKey,
  setActiveKey,
  invoiceCardId,
  invoiceTitle,
  invoice,
  invoiceType,
}) {
  const totalInvoiceAmount = sumBy(
    isArray(invoice) ? invoice : [invoice],
    'invoice_amount'
  );

  const amountToPayField = useCallback((invoiceNumber, invoiceRow, index) => (
    <>
      <InputText
        name={`${invoiceType}_invoice_ids[${index}]`}
        type="hidden"
        min={0}
        defaultValue={invoiceRow.id}
        register={register()}
      />
      <InputText
        name={`${invoiceType}[${index}]`}
        type="number"
        min={0}
        max={invoiceRow?.amount_due}
        register={register({
          required: 'Amount Field is required',
          min: { value: 0, message: 'Minimum amount should be 0' },
          max: {
            value: invoiceRow?.amount_due,
            message: `Maximum amount should be ${invoiceRow?.amount_due}`,
          },
        })}
        readOnly={parseInt(invoiceRow?.amount_due, 10) < 1}
        className="text-info"
        defaultValue={parseInt(invoiceRow?.amount_due || 0, 10)}
        error={get(errors, `$${invoiceType}[${index}].message`)}
      />
    </>
  ));

  return (
    <Accordion.Item
      eventKey={invoiceCardId}
      className="mb-2 border border-muted"
    >
      <AccordionHeader
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        eventKey={invoiceCardId}
      >
        <FaPaperclip className="me-1" />
        {invoiceTitle} - {parseInt(totalInvoiceAmount, 10).toLocaleString()}
      </AccordionHeader>
      <Accordion.Body className="p-0">
        <AntDTable
          data={isArray(invoice) ? invoice : [invoice]}
          xScroll="100%"
          pagination={false}
          columns={[
            {
              title: 'INVOICE NO.',
              dataIndex: 'invoice_number',
              width: 140,
            },
            {
              title: 'AMOUNT',
              dataIndex: 'invoice_amount',
              render: (amount) => parseInt(amount, 10).toLocaleString(),
              width: 120,
            },
            {
              title: 'PAID',
              dataIndex: 'amount_paid',
              render: (amount) => parseInt(amount, 10).toLocaleString(),
              width: 120,
            },
            {
              title: 'DUE',
              dataIndex: 'amount_due',
              render: (amount) => parseInt(amount, 10).toLocaleString(),
              width: 120,
            },
            {
              title: 'AMOUNT TO PAY',
              dataIndex: 'invoice_number',
              width: 150,
              render: amountToPayField,
            },
          ]}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}

InvoiceCard.defaultProps = {
  invoice: {},
  activeKey: null,
};

InvoiceCard.propTypes = {
  invoice: PropTypes.oneOfType([any]),
  errors: PropTypes.oneOfType([any]).isRequired,
  invoiceCardId: PropTypes.oneOfType([any]).isRequired,
  activeKey: PropTypes.oneOfType([any]),
  register: PropTypes.func.isRequired,
  setActiveKey: PropTypes.func.isRequired,
  invoiceType: PropTypes.string.isRequired,
  invoiceTitle: PropTypes.string.isRequired,
};
export default InvoiceCard;
