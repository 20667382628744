import { includes, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { ReloadButton, TabMenu } from '../../../components/common';
import { paymentActions, settingActions } from '../../../config/actions';
import MenuList from '../../../config/routes/MenuList';
import Transactions from './Transactions';

function MyTransactions() {
  const dispatch = useDispatch();
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { transactions, loading, myStudentLedger } = useSelector(
    (state) => state.myTransaction
  );
  const [currentMenu, setCurrentMenu] = useState('data');
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);

  const transactionActions = ['data', 'deposits'];
  const isTransaction = includes(transactionActions, currentMenu);

  useEffect(() => {
    if (isEmpty(myStudentLedger))
      dispatch(paymentActions.getStudentLedger(currentStudentProgramme?.id));
  }, []);

  const getTransactions = () => dispatch(paymentActions.getMyTransactions());

  const menuItems = [
    {
      title: 'Invoice Payments',
      action: 'data',
    },
    {
      title: 'Fees Deposits',
      action: 'deposits',
    },
  ];

  const navigateUser = (route) => {
    const { MyTransaction } = MenuList;
    dispatch(
      settingActions.setNavigation({
        ...appNavigation,
        menuLevel_1: 'payments',
        menuLevel_2: MyTransaction?.label,
        menuLevel_3: route,
      })
    );
  };

  useEffect(() => {
    if (isEmpty(transactions)) getTransactions();
  }, []);

  useEffect(() => {
    const { key } = selectedMenu;

    if (key === 'my-transactions') {
      navigateUser(menuItems[0]?.title);
    }
  }, []);

  return (
    <Card>
      <TabMenu
        currentMenu={currentMenu}
        setCurrentMenu={setCurrentMenu}
        navigateUser={navigateUser}
        menus={menuItems}
      >
        <div className="card-options">
          <ReloadButton loading={loading} onClick={() => getTransactions()} />
        </div>
      </TabMenu>

      <Card.Body className="p-3">
        {isTransaction && <Transactions currentMenu={currentMenu} />}
      </Card.Body>
    </Card>
  );
}

export default MyTransactions;
