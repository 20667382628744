import React from 'react';
import { get } from 'lodash';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InputText, SubmitButton } from '../../../components/common';
import { serviceActions } from '../../../config/actions';

function ApplicationForm() {
  const { register, handleSubmit, errors } = useForm();
  const { accountBalance } = useSelector((state) => state.auth);
  const { submittingApplication } = useSelector((state) => state.service);
  const dispatch = useDispatch();

  const formSubmitHandler = (data) => {
    if (data?.amount) {
      const applicationData = {
        id: 'RefundForm101',
        date: new Date().toDateString(),
        ...data,
      };
      dispatch(
        serviceActions.submitApplication(applicationData, 'refund-application')
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit(formSubmitHandler)}>
      <InputText
        name="amount"
        type="number"
        label="Amount (Ugx)"
        inline
        placeholder="Enter Amount to be refunded"
        register={register({
          required: 'Enter Amount',
          max: {
            value: accountBalance,
            message: 'Amount must be less or equal to your account balance',
          },
        })}
        error={get(errors, 'amount.message')}
      />
      <SubmitButton
        className="float-end text-uppercase w-200"
        text="Submit Application"
        loading={submittingApplication}
        loadingText="Submitting..."
      />
    </Form>
  );
}

export default ApplicationForm;
