import { Tag } from 'antd';
import { orderBy } from 'lodash';
import moment from 'moment';
import PropTypes, { array } from 'prop-types';
import React, { useState } from 'react';
import { Accordion, Card, Col, Row, Table } from 'react-bootstrap';
import { FaUserTie } from 'react-icons/fa';
import AccordionHeader from '../../../components/common/AccordionHeader';
import { formatName } from '../../../helpers/dataFormatter';

function PendingApplication({ applicationRequests }) {
  const [activeKey, setActiveKey] = useState(1);

  const returnTagColor = (status) => {
    let color = 'default';
    if (status === 'APPROVED') color = 'geekblue';
    if (status === 'REJECTED') color = 'volcano';
    return color;
  };
  return (
    <Accordion defaultActiveKey="1" activeKey={activeKey}>
      {applicationRequests.map((request, index) => (
        <Accordion.Item eventKey={index + 1} className="mb-2" key={request.id}>
          <AccordionHeader
            activeKey={activeKey}
            eventKey={index + 1}
            setActiveKey={setActiveKey}
          >
            <FaUserTie className="me-2 mb-1" />
            {`ADMINISTRATIVE REGISTRATION APPLICATION, ${
              request.event?.semester?.semester?.metadata_value
            } - ${
              request.event.academicYear?.academicYear?.metadata_value
            } (${moment(request.created_at).format('LL')})`}
          </AccordionHeader>
          <Accordion.Body>
            <>
              <div
                className="text-uppercase text-primary text-md fw-bold mb-2 p-2"
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.61)' }}
              >
                APPLICATION DETAILS
              </div>
              <Row className="row-deck g-1">
                <Col className="col-12">
                  <div
                    className="w-100"
                    style={{ border: 'dashed 1px var(--bs-primary)' }}
                  >
                    <Table
                      size="lg"
                      className="m-0 text-sm"
                      style={{ fontSize: '10px !important' }}
                    >
                      <tbody>
                        <tr>
                          <td width="130" className="fw-bold">
                            FULL NAME:
                          </td>
                          <td colSpan={1}>
                            {formatName(
                              `${request.student?.surname} ${request.student?.other_names}`
                            )}
                          </td>
                          <td className="fw-bold">PROGRAMME:</td>
                          <td
                            colSpan={3}
                          >{`(${request.studentProgramme.programme?.programme_code}) ${request.studentProgramme.programme?.programme_title}`}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">STUDENT NO:</td>
                          <td colSpan={1}>
                            {request.studentProgramme?.old_student_number
                              ? request.studentProgramme?.old_student_number
                              : request.studentProgramme?.student_number}
                          </td>
                          <td className="fw-bold">REGISTRATION NO:</td>
                          <td colSpan={3}>
                            {request.studentProgramme?.registration_number}
                          </td>
                        </tr>
                        <tr className="my-1">
                          <td className="fw-bold">APPLICATION:</td>
                          <td colSpan={1}>ADMINISTRATIVE REGISTRATION</td>
                          <td className="fw-bold">APPLICATION DATE:</td>
                          <td>{moment(request.created_at).format('LL')}</td>
                        </tr>
                        <tr className="my-1">
                          <td className="fw-bold">SEMESTER:</td>
                          <td>
                            {request.event?.semester?.semester?.metadata_value}
                          </td>
                          <td className="fw-bold">ACADEMIC YEAR:</td>
                          <td>
                            {
                              request.event.academicYear?.academicYear
                                ?.metadata_value
                            }
                          </td>
                        </tr>
                        <tr className="my-1">
                          <td colSpan={2} className="fw-bold">
                            APPLICATION STATUS:
                          </td>
                          <td colSpan={2}>
                            <Tag
                              color={request.is_active ? 'green' : 'volcano'}
                            >
                              {request.is_active ? 'ACTIVE' : 'INACTIVE'}
                            </Tag>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="fw-bold">
                            APPLICATION COMMENT:
                          </td>
                          <td colSpan={2}>{request.comment}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="fw-bold">
                            REVIEW STATUS:
                          </td>
                          <td colSpan={2}>
                            <Tag color={returnTagColor(request.review_status)}>
                              {request.review_status}
                            </Tag>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="fw-bold">
                            REVIEWER COMMENT:
                          </td>
                          <td colSpan={2}>{request.reviewer_comment}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="fw-bold">
                            APPROVAL STATUS:
                          </td>
                          <td colSpan={2}>
                            <Tag
                              color={returnTagColor(request.approval_status)}
                            >
                              {request.approval_status}
                            </Tag>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="fw-bold">
                            APPROVER COMMENT:
                          </td>
                          <td colSpan={2}>{request.approver_comment}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              <div
                className="text-uppercase text-primary text-md fw-bold mt-4 mb-2 p-2"
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.61)' }}
              >
                COURSES/MODULES ON APPLICATION
              </div>
              <Card
                style={{ border: 'dashed 1px' }}
                className=" bg-transparent border-primary mb-3"
              >
                <Table size="sm" striped borderless className="text-sm m-0">
                  <thead className="border-bottom text-muted">
                    <tr>
                      <th>#</th>
                      <th>CODE</th>
                      <th>COURSE NAME</th>
                      <th className="text-start">STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderBy(request.courseUnits, ['course_unit_id']).map(
                      (course, line) => (
                        <tr key={course.course_unit_id}>
                          <td>{line + 1}</td>
                          <td>{course.courseUnit.course_unit_code}</td>
                          <td>{course.courseUnit.course_unit_name}</td>
                          <td>{course.courseUnitStatus.metadata_value}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </Card>
            </>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

PendingApplication.defaultProps = {
  applicationRequests: [],
};

PendingApplication.propTypes = {
  applicationRequests: PropTypes.oneOfType([array]),
};

export default PendingApplication;
