import { find, isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Collapse, Space, Tag, message } from 'antd';
import { FaDownload } from 'react-icons/fa';
import { timetableActions } from '../../config/actions';
import {
  DataNotFound,
  DataSpinner,
  ReloadButton,
  SubmitButton,
} from '../../components/common';
import ProgrammeTeachingTable from './ProgrammeTeachingTable';

function TeachingTimetable() {
  const { currentEvents } = useSelector((state) => state.enrollment);
  const { activeProgramme } = useSelector((state) => state.auth);
  const [semesterEvent, setSemesterEvent] = useState({});
  const [timetable, setTimetable] = useState([]);
  const dispatch = useDispatch();

  const {
    programmeTeachingTimetable,
    gettingProgrammeTeachingTimetable,
    downloadingProgrammeTimetable,
  } = useSelector((state) => state.timetable);

  const getTeachingTimetable = () => {
    dispatch(
      timetableActions.getProgrammeTeachingTimetable({
        academic_year_id:
          semesterEvent?.enrollmentEvent?.metadata_entry_academic_year_id,
        study_year_id: 'all',
        programme_type_id: 'all',
        semester_id: semesterEvent?.enrollmentEvent?.semester_id,
        programme_id: activeProgramme.programme_id,
        campus_id: semesterEvent?.enrollmentEvent?.campus_id,
        intake_id: semesterEvent?.enrollmentEvent?.intake_id,
        category: 'programme',
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(currentEvents)) {
      const findEvent = currentEvents.find(
        (event) =>
          parseInt(event.programme_id, 10) === parseInt(activeProgramme.id, 10)
      );
      setSemesterEvent(findEvent ? findEvent.event : {});
    }
  }, [currentEvents, activeProgramme]);

  useEffect(() => {
    if (!isEmpty(semesterEvent) && isEmpty(programmeTeachingTimetable)) {
      getTeachingTimetable();
    }
  }, [semesterEvent]);

  useEffect(() => {
    if (!isEmpty(programmeTeachingTimetable)) {
      const findContext = find(
        programmeTeachingTimetable,
        (context) =>
          context.programme_id === activeProgramme.programme_id &&
          context.campus_id === semesterEvent?.enrollmentEvent?.campus_id &&
          context.intake_id === semesterEvent?.enrollmentEvent?.intake_id &&
          context.semester_id === semesterEvent?.enrollmentEvent?.semester_id &&
          context.academic_year_id ===
            semesterEvent?.enrollmentEvent?.metadata_entry_academic_year_id
      );

      setTimetable(findContext?.data || []);
    }
  }, [programmeTeachingTimetable, semesterEvent]);

  const downloadingTimetable = (assignment) => {
    message.loading(
      `Downloading ${assignment.programme_title || ''} Teaching Timetable`,
      3
    );
    dispatch(
      timetableActions.downloadTeachingTimetable({
        academic_year_id:
          semesterEvent?.enrollmentEvent?.metadata_entry_academic_year_id,
        campus_id: semesterEvent?.enrollmentEvent?.campus_id,
        intake_id: semesterEvent?.enrollmentEvent?.intake_id,
        semester_id: semesterEvent?.enrollmentEvent?.semester_id,
        programme_id: activeProgramme.programme_id,
        programme_type_id: assignment.programme_type_id,
        study_year_id: assignment.study_year_id,
        programme_name: assignment.programme_title || '',
        category: 'programme',
        download_source: 'students',
      })
    );
  };

  return (
    <div>
      <Card>
        <Card.Header className="py-2">
          <div className="font600 text-uppercase text-sm">
            {activeProgramme?.programme_title || ''}{' '}
            <span className="text-primary">
              {semesterEvent?.enrollmentEvent?.academic_year || ''}
            </span>{' '}
            <span className="text-primary">
              {semesterEvent?.enrollmentEvent?.semester || ''}
            </span>{' '}
            TEACHING TIMETABLE
          </div>
          <div className="card-options">
            <ReloadButton
              loading={
                gettingProgrammeTeachingTimetable ||
                downloadingProgrammeTimetable
              }
              onClick={getTeachingTimetable}
            />
          </div>
        </Card.Header>
        <Card.Body>
          {gettingProgrammeTeachingTimetable && isEmpty(timetable) ? (
            <DataSpinner />
          ) : (
            <>
              {isEmpty(timetable) ? (
                <DataNotFound message="No Timetable has been created" />
              ) : (
                <Collapse
                  ghost
                  items={map(timetable, (assignment, index) => ({
                    key: `${assignment.programme_code}-${assignment.study_year_id}-${assignment.programme_type_id}`,
                    children: (
                      <ProgrammeTeachingTable
                        data={assignment.assignments}
                        loading={gettingProgrammeTeachingTimetable}
                      />
                    ),
                    className:
                      index + 1 !== timetable.length ? 'border-bottom' : '',
                    label: (
                      <div className=" gap-2">
                        <span className="fw-bold mx-2 text-primary">
                          {assignment.study_year}
                        </span>
                        <Tag>{assignment.programme_type}</Tag>
                      </div>
                    ),
                    extra: (
                      <Space size="small">
                        <SubmitButton
                          text="DOWNLOAD"
                          type="default"
                          iconBefore={<FaDownload />}
                          onClick={() => downloadingTimetable(assignment)}
                        />
                      </Space>
                    ),
                  }))}
                />
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default TeachingTimetable;
