import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  authActions,
  paymentActions,
  paymentReferenceActions,
} from '../../actions';

function* getPaymentReferences(actions) {
  try {
    const response = yield axios({
      url: '/student-portal/payment-references/history',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: paymentReferenceActions.GETTING_PAYMENT_REFERENCES_SUCCESS,
      data: response.paymentReferences,
    });
    yield put({
      type: authActions.GET_AUTH_USER_ACCOUNT_BALANCE_REQUEST,
    });
    yield put({
      type: paymentActions.GET_MY_TRANSACTIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: paymentReferenceActions.GETTING_PAYMENT_REFERENCES_ERROR,
      error: error.data,
    });
  }
}

function* generatePaymentReference(actions) {
  const { studentProgrammeId, data, referenceType } = actions;
  let url = '/student-portal/payment-references';
  if (referenceType === 'bulk')
    url = '/student-portal/payment-references/select-unpaid-invoices';
  else if (referenceType === 'future')
    url = `/student-portal/payment-references/future-payments/${studentProgrammeId}`;
  else if (referenceType === 'all')
    url = '/student-portal/payment-references/all-unpaid-invoices';

  try {
    const response = yield axios({
      url,
      method: 'POST',
      data,
    });
    yield put({
      type: paymentReferenceActions.GENERATE_PAYMENT_REFERENCE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: paymentReferenceActions.GETTING_PAYMENT_REFERENCES_REQUEST,
    });
  } catch (error) {
    yield put({
      type: paymentReferenceActions.GENERATE_PAYMENT_REFERENCE_ERROR,
      error: error.data,
    });
  }
}

function* generateGraduationPaymentReference(actions) {
  try {
    const response = yield axios({
      url: `/student-portal/payment-references/graduation-fees-invoice-prn/${actions.id}`,
      method: 'POST',
    });
    yield put({
      type: paymentReferenceActions.GENERATE_GRADUATION_INVOICE_PRN_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SET_SHOW_PRN_MODAL',
      payload: false,
    });
    yield put({
      type: 'SET_SHOW_MODAL',
      payload: true,
    });
  } catch (error) {
    yield put({
      type: paymentReferenceActions.GENERATE_GRADUATION_INVOICE_PRN_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPaymentReferences() {
  yield takeLatest(
    paymentReferenceActions.GETTING_PAYMENT_REFERENCES_REQUEST,
    getPaymentReferences
  );
}

function* watchGeneratePaymentReference() {
  yield takeLatest(
    paymentReferenceActions.GENERATE_PAYMENT_REFERENCE_REQUEST,
    generatePaymentReference
  );
}

function* watchGenerateGraduationPaymentReference() {
  yield takeLatest(
    paymentReferenceActions.GENERATE_GRADUATION_INVOICE_PRN_REQUEST,
    generateGraduationPaymentReference
  );
}

const forkFunctions = [
  fork(watchGetPaymentReferences),
  fork(watchGeneratePaymentReference),
  fork(watchGenerateGraduationPaymentReference),
];

export default forkFunctions;
