import React, { useEffect } from 'react';
import { FaSearchMinus } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import RoutePaths from '../../config/routes/RoutePaths';

function PageNotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(RoutePaths.dashboard.path);
    }, [1000]);
  }, []);

  return (
    <div className="d-flex vh-100" style={{ overflow: 'hidden' }}>
      <div className="mx-auto my-auto p-4 text-center">
        <FaSearchMinus className="text-danger mx-auto h1 d-block mb-3" />
        <div className="font600 text-danger fs-3">RESOURCE NOT FOUND</div>
      </div>
    </div>
  );
}

export default PageNotFound;
