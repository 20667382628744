import { isEmpty } from 'lodash';
import PropTypes, { any } from 'prop-types';
import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { AlertMessage } from '../../components/common';
import RegistrationCard from './RegistrationCard';
import SingleRegistrationHistory from './SingleRegistrationHistory';

function RegisteredStudent({
  enrollmentEvent,
  enrollmentHistory,
  registrationHistory,
}) {
  const [selectedHistory, setSelectedHistory] = useState({});
  const { showModal } = useSelector((state) => state.setting);

  return (
    <>
      <AlertMessage
        icon={<FaCheckCircle className="me-1" />}
        variant="success"
        className="font600 py-1 text-uppercase text-xs"
        message={
          <>
            You have been registered for
            <strong className="mx-1">
              {`${enrollmentHistory?.studyYear?.programme_study_years}, `}
            </strong>
            <strong className="me-1">{`${enrollmentEvent?.semester} -`}</strong>
            {enrollmentEvent?.academic_year}
          </>
        }
      />

      <SingleRegistrationHistory
        registrationHistory={registrationHistory}
        setSelectedHistory={setSelectedHistory}
      />

      {showModal && !isEmpty(selectedHistory) && (
        <RegistrationCard selectedRegistration={selectedHistory} />
      )}
    </>
  );
}

RegisteredStudent.defaultProps = {
  registrationHistory: {},
};

RegisteredStudent.propTypes = {
  registrationHistory: PropTypes.oneOfType([any]),
  enrollmentHistory: PropTypes.oneOfType([any]).isRequired,
  enrollmentEvent: PropTypes.oneOfType([any]).isRequired,
};

export default RegisteredStudent;
