import { Alert, Modal } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessage } from '../../components/common';
import { settingActions } from '../../config/actions';
import usePrevious from '../Hooks/usePrevious';

function ReferenceModal({ switchTab }) {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [generatedReference, setGeneratedReference] = useState({});
  const { generateSuccess, generateError } = useSelector(
    (state) => state.paymentReference
  );
  const showModal = useSelector((state) => state.setting.showModal);
  const prevState = usePrevious({ generateSuccess, generateError });

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(generateSuccess) &&
      generateSuccess !== prevState.generateSuccess
    ) {
      setGeneratedReference(generateSuccess);
      dispatch(settingActions.setShowModal(true));
      if (switchTab) switchTab();
    }
    if (
      !isEmpty(prevState) &&
      !isEmpty(generateError) &&
      generateError !== prevState.generateError
    ) {
      setErrorMessage(
        generateError?.error?.message || generateError?.server?.message
      );
    }
  }, [generateSuccess, generateError]);

  return (
    <>
      {errorMessage && <AlertMessage message={errorMessage} />}
      {!isEmpty(generatedReference) && showModal && (
        <Modal
          centered
          closable
          keyboard={false}
          maskClosable={false}
          open={showModal}
          title="PAYMENT REFERENCE GENERATED SUCCESSFULLY"
          onOk={() => dispatch(settingActions.setShowModal(false))}
          onCancel={() => dispatch(settingActions.setShowModal(false))}
        >
          <div className="text-md font600 text-center">
            <FaCheckCircle className="text-success me-1" />
            PAYMENT REFERENCE NO.:
            <span className="text-sm font600 text-success mx-1">
              {generatedReference.ura_prn}
            </span>
          </div>

          <div className="text-sm my-2 text-center font600">
            TOTAL AMOUNT TO PAY:
            <span className="text-sm font600 text-success mx-1">
              {`${parseInt(
                generatedReference.amount,
                10
              ).toLocaleString()} UGX`}
            </span>
          </div>

          <div className="text-xs text-center font600 my-3">
            DATE OF EXPIRY:
            <span className="text-xs font600 text-danger mx-2">
              {generatedReference.expiry_date}
            </span>
          </div>

          <Alert
            message="Copy this Payment Reference Number and go to your Nearest Bank to make your payments."
            type="warning"
            showIcon
          />
        </Modal>
      )}
    </>
  );
}

ReferenceModal.defaultProps = {
  switchTab: null,
};

ReferenceModal.propTypes = {
  switchTab: PropTypes.func,
};

export default ReferenceModal;
