import { isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import { FaReply, FaSave } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitButton } from '../../../components/common';
import { serviceActions } from '../../../config/actions';

function PreviewChange({
  data,
  setShowPreview,
  isEditing,
  dataToEdit,
  currentTab,
  currentEvent,
}) {
  const { activeProgramme } = useSelector((state) => state.auth);
  const { submittingApplication, updatingApplication } = useSelector(
    (state) => state.service
  );
  const dispatch = useDispatch();

  const submitFormHandler = () => {
    if (!isEmpty(data) && !submittingApplication) {
      dispatch(
        serviceActions[isEditing ? 'updateApplication' : 'submitApplication'](
          {
            event_id: currentEvent.id,
            new_programme_id: data?.new_programme_id?.value,
            new_programme_type_id: data?.new_programme_type_id?.value,
            new_study_year_id: data?.new_study_year_id?.value,
            new_campus_id: data?.new_campus_id?.value,
            academic_year_id: data?.academic_year_id?.value,
            new_subject_comb_id: data?.new_subject_comb_id?.value,
            reason: data?.reason,
            service: currentTab,
          },
          'change-of-programme',
          activeProgramme?.id,
          dataToEdit?.id
        )
      );
    }
  };

  return (
    <>
      <div className="fs-5 fw-bold text-muted text-center mb-4 text-uppercase">
        CONFIRM YOUR CHANGE OF PROGRAMME DATA AND CLICK{' '}
        {isEditing ? 'Update Application' : 'Submit Application'} BUTTON
      </div>

      <Table
        responsive
        striped
        bordered
        size="lg"
        className="mb-4 table-responsive"
      >
        <tbody>
          <tr>
            <td />
            <td className="fw-bold text-sm">CURRENT PROGRAMME</td>
            <td className="fw-bold text-sm">NEW PROGRAMME</td>
          </tr>
          <tr>
            <td width={150}>Title</td>
            <td>{activeProgramme?.programme_title}</td>
            <td>{data?.new_programme_id?.label}</td>
          </tr>
          <tr>
            <td>Study Time</td>
            <td>{activeProgramme?.programme_type}</td>
            <td>{data?.new_programme_type_id?.label}</td>
          </tr>
          <tr>
            <td>Study Year</td>
            <td>{activeProgramme?.current_study_year}</td>
            <td>{data?.new_study_year_id?.label}</td>
          </tr>
          <tr>
            <td>Campus</td>
            <td>{activeProgramme?.campus}</td>
            <td>{data?.new_campus_id?.label}</td>
          </tr>
          <tr>
            <td>Entry Academic Year</td>
            <td>{activeProgramme?.entry_academic_year}</td>
            <td>{data?.academic_year_id?.label}</td>
          </tr>
          <tr>
            <td>Subject Combination</td>
            <td>N/A</td>
            <td>{data?.new_subject_comb_id?.label || 'N/A'}</td>
          </tr>
          <tr>
            <td>Reason</td>
            <td>N/A</td>
            <td>{data?.reason}</td>
          </tr>
        </tbody>
      </Table>

      <div className="text-center d-block">
        <SubmitButton
          size="small"
          text={isEditing ? 'Update Application' : 'Submit Application'}
          loadingText={isEditing ? 'Updating...' : 'Submitting Application...'}
          className="float-end text-uppercase mx-2"
          loading={submittingApplication || updatingApplication}
          onClick={submitFormHandler}
          iconBefore={<FaSave className="me-1" />}
        />
        <SubmitButton
          size="small"
          text="Edit Application"
          className="float-end text-uppercase"
          danger
          iconBefore={<FaReply className="me-1" />}
          onClick={() => setShowPreview(false)}
        />
      </div>
    </>
  );
}

PreviewChange.defaultProps = {
  dataToEdit: {},
  isEditing: false,
};

PreviewChange.propTypes = {
  setShowPreview: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  data: PropTypes.oneOfType([object]).isRequired,
  dataToEdit: PropTypes.oneOfType([object]),
  currentEvent: PropTypes.oneOfType([object]).isRequired,
};

export default PreviewChange;
