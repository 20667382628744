import PropTypes from 'prop-types';
import React from 'react';
import { DataNotFound } from '../../components/common';
import ApplyForID from './ApplyForID';
import ChangeOfProgramme from './ChangeOfProgramme';
import ChangeOfStudyTime from './ChangeOfStudyTime';
import ChangeOfSubjectComb from './ChangeOfSubjectComb';
import Refund from './Refund/Index';
import AdministrativeRegistration from './AdministrativeRegistration';

function ServiceSideMenu({ setCurrentTab, currentTab, navigateUser }) {
  return (
    <>
      {(currentTab.includes('refund') && (
        <Refund setCurrentTab={setCurrentTab} />
      )) ||
        (currentTab.includes('change-of-programme') && (
          <ChangeOfProgramme
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            navigateUser={navigateUser}
          />
        )) ||
        (currentTab.includes('study-time') && (
          <ChangeOfStudyTime
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            navigateUser={navigateUser}
          />
        )) ||
        (currentTab.includes('change-of-subject-comb') && (
          <ChangeOfSubjectComb
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            navigateUser={navigateUser}
          />
        )) ||
        (currentTab.includes('administrative-registration') && (
          <AdministrativeRegistration
            setCurrentTab={setCurrentTab}
            navigateUser={navigateUser}
          />
        )) ||
        (currentTab.includes('apply-for-id') && (
          <ApplyForID
            setCurrentTab={setCurrentTab}
            navigateUser={navigateUser}
          />
        )) || <DataNotFound message="Invalid Service Type" />}
    </>
  );
}

ServiceSideMenu.propTypes = {
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  navigateUser: PropTypes.func.isRequired,
};

export default ServiceSideMenu;
