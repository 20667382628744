const registration = {
  GET_REGISTRATION_EVENT_REQUEST: 'GET_REGISTRATION_EVENT_REQUEST',
  GET_REGISTRATION_EVENT_SUCCESS: 'GET_REGISTRATION_EVENT_SUCCESS',
  GET_REGISTRATION_EVENT_ERROR: 'GET_REGISTRATION_EVENT_ERROR',

  GET_REGISTRATION_HISTORY_REQUEST: 'GET_REGISTRATION_HISTORY_REQUEST',
  GET_REGISTRATION_HISTORY_SUCCESS: 'GET_REGISTRATION_HISTORY_SUCCESS',
  GET_REGISTRATION_HISTORY_ERROR: 'GET_REGISTRATION_HISTORY_ERROR',

  REGISTER_STUDENT_REQUEST: 'REGISTER_STUDENT_REQUEST',
  REGISTER_STUDENT_SUCCESS: 'REGISTER_STUDENT_SUCCESS',
  REGISTER_STUDENT_ERROR: 'REGISTER_STUDENT_ERROR',

  GET_COURSE_UNITS_REQUEST: 'GET_COURSE_UNITS_REQUEST',
  GET_COURSE_UNITS_SUCCESS: 'GET_COURSE_UNITS_SUCCESS',
  GET_COURSE_UNITS_ERROR: 'GET_COURSE_UNITS_ERROR',

  getRegistrationEvent: (data) => ({
    type: registration.GET_REGISTRATION_EVENT_REQUEST,
    data,
  }),

  getRegistrationHistory: (studentProgrammeId) => ({
    type: registration.GET_REGISTRATION_HISTORY_REQUEST,
    studentProgrammeId,
  }),

  registerStudent: (data, currentStudentProgrammeId) => ({
    type: registration.REGISTER_STUDENT_REQUEST,
    data,
    currentStudentProgrammeId,
  }),

  getCourseUnits: (programmeId) => ({
    type: registration.GET_COURSE_UNITS_REQUEST,
    programmeId,
  }),
};

export default registration;
