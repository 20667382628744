import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import FileSaver from 'file-saver';
import { timetableActions } from '../../actions';

function* getProgrammeTeachingTimetable(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: `/student-portal/students-timetable/programme-teaching-timetable`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: timetableActions.GET_PROGRAMME_TEACHING_TIMETABLE_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: timetableActions.GET_PROGRAMME_TEACHING_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* downloadProgrammeTeachingTimetable(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/student-portal/students-timetable/download-programme-teaching-timetable',
      method: 'GET',
      params: context,
      responseType: 'blob',
    });
    FileSaver.saveAs(
      new Blob([response.data], {
        type: 'pdf',
      }),
      `${process.env.REACT_APP_INSTITUTION_NAME}-${
        context?.programme_name || ''
      }-PROGRAMME-TEACHING-TIMETABLE.pdf`
    );
    yield put({
      type: timetableActions.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: timetableActions.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}
function* watchDownloadTeachingTimetableTemplate() {
  yield takeLatest(
    timetableActions.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_REQUEST,
    downloadProgrammeTeachingTimetable
  );
}
function* watchGetProgrammeTeachingTimetable() {
  yield takeLatest(
    timetableActions.GET_PROGRAMME_TEACHING_TIMETABLE_REQUEST,
    getProgrammeTeachingTimetable
  );
}

const forkFunctions = [
  fork(watchGetProgrammeTeachingTimetable),
  fork(watchDownloadTeachingTimetableTemplate),
];

export default forkFunctions;
