import { isArray, isEmpty, merge, sumBy } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlertMessage,
  CustomModal,
  SubmitButton,
} from '../../../components/common';
import { paymentActions } from '../../../config/actions';
import usePrevious from '../../Hooks/usePrevious';
import InvoiceFormTable from './InvoiceFormTable';

function AllocationModal({ selectedAmount }) {
  const dispatch = useDispatch();
  const { invoices } = useSelector((state) => state.myInvoice);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { allocating, allocationError } = useSelector(
    (state) => state.myTransaction
  );
  const [unclearedInvoices, setUnclearedInvoices] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const prevState = usePrevious({ allocationError });
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    dispatch(
      paymentActions.getMyInvoices({
        student_programme_id: currentStudentProgramme?.student_programme_id,
      })
    );
  }, []);

  useEffect(() => {
    if (
      prevState &&
      !isEmpty(prevState) &&
      !isEmpty(allocationError) &&
      prevState.allocationError !== allocationError
    ) {
      setErrorMessage(allocationError.server?.message);
    }
  }, [allocationError]);

  useEffect(() => {
    if (invoices) {
      let invoiceList = [];

      if (isArray(invoices)) {
        invoices.forEach((invoice) => {
          const myInvoices = [
            ...invoice.functional_fees_invoices.filter(
              (fInvoice) => fInvoice.amount_due > 0
            ),
            ...invoice.other_fees_invoices.filter(
              (oInvoice) => oInvoice.amount_due > 0
            ),
            ...invoice.tuition_invoices.filter(
              (tInvoice) => tInvoice.amount_due > 0
            ),
            ...invoice.manual_invoices.filter(
              (mInvoice) => mInvoice.amount_due > 0
            ),
          ];

          invoiceList = merge(invoiceList, myInvoices);
        });
      }
      setUnclearedInvoices(invoiceList);
    }
  }, [invoices]);

  const allocateTransaction = () => {
    if (!isEmpty(selectedRows)) {
      const formData = selectedRows.map((row) => ({
        invoice_id: row.id,
        invoice_number: row.invoice_number,
        amount: row.amountToPay,
      }));
      dispatch(
        paymentActions.allocateTransactions(selectedAmount.id, {
          invoices: formData,
        })
      );
      setErrorMessage(null);
    }
  };

  const amountToPay = sumBy(selectedRows, 'amountToPay');
  const totalAmountDue = sumBy(selectedRows, 'amount_due');

  return (
    <CustomModal title="ALLOCATE PAYMENT TO INVOICES" size="lg">
      <Modal.Body>
        <Card.Header className="border p-3 fw-bold mb-3">
          TOTAL AMOUNT TO ALLOCATE:{' '}
          {parseInt(selectedAmount?.unallocated_amount, 10).toLocaleString()}
          {selectedAmount.currency}
        </Card.Header>

        {errorMessage && (
          <AlertMessage className="p-2 fw-bold" message={errorMessage} />
        )}

        <InvoiceFormTable
          invoices={unclearedInvoices}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />

        {!isEmpty(selectedRows) &&
          amountToPay <= selectedAmount?.unallocated_amount && (
            <Card body className="bg-light fw-bold text-md text-uppercase">
              {`TOTAL AMOUNT TO PAY: ${parseInt(
                amountToPay,
                10
              ).toLocaleString()} ${selectedAmount.currency}`}
            </Card>
          )}
        {amountToPay > selectedAmount?.unallocated_amount &&
          amountToPay <= totalAmountDue && (
            <AlertMessage
              message={`You Maximum amount to allocate is ${parseInt(
                selectedAmount?.unallocated_amount,
                10
              ).toLocaleString()} ${
                selectedAmount.currency
              }. YOU EXCEEDED BY ${parseInt(
                amountToPay - selectedAmount.unallocated_amount,
                10
              ).toLocaleString()} ${selectedAmount.currency}`}
              className="text-uppercase"
            />
          )}
        {amountToPay > totalAmountDue && (
          <AlertMessage
            message={`You Maximum amount to pay is ${parseInt(
              totalAmountDue,
              10
            ).toLocaleString()} ${
              selectedAmount.currency
            }. YOU EXCEEDED BY ${parseInt(
              amountToPay - totalAmountDue,
              10
            ).toLocaleString()} ${selectedAmount.currency}`}
            className="text-uppercase"
          />
        )}
      </Modal.Body>
      {selectedAmount?.unallocated_amount >= 0 && (
        <Modal.Footer className="py-1 bg-light">
          <SubmitButton
            text="Allocate Amount"
            size="sm"
            onClick={allocateTransaction}
            loading={allocating}
            loadingText="Allocating"
            disabled={
              allocating ||
              isEmpty(selectedRows) ||
              amountToPay > selectedAmount?.unallocated_amount ||
              amountToPay > totalAmountDue
            }
          />
        </Modal.Footer>
      )}
    </CustomModal>
  );
}

AllocationModal.propTypes = {
  selectedAmount: PropTypes.oneOfType([object]).isRequired,
};

export default AllocationModal;
