import { BlockOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes, { array } from 'prop-types';
import React, { useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { DataNotFound } from '../../components/common';
import CourseUnitsTable from './CourseUnitsTable';

function SelectedCourseUnits({
  selectedCourseUnits,
  setSelectedCourseUnits,
  setDataAndOpenPopUp,
  enrollWithRetakes,
}) {
  const findCourseUnit = (rowData) =>
    selectedCourseUnits.find(
      (courseUnit) => courseUnit.course_unit_id === rowData.course_unit_id
    );

  const removeSelectedRowData = (data) => {
    const courseExists = findCourseUnit(data);
    if (courseExists) {
      setSelectedCourseUnits(
        selectedCourseUnits.filter(
          (courseUnit) =>
            courseUnit.course_unit_id !== courseExists.course_unit_id
        )
      );
    }
  };

  const actionButton = useCallback((rowData) => (
    <Button
      size="small"
      danger
      icon={<BlockOutlined />}
      onClick={() => removeSelectedRowData(rowData)}
    >
      Remove
    </Button>
  ));

  return (
    <>
      {isEmpty(selectedCourseUnits) ? (
        <Card>
          <DataNotFound
            message={`SELECT COURSES/MODULES TO ${
              enrollWithRetakes ? 'RETAKE' : 'REGISTER'
            }`}
          />
        </Card>
      ) : (
        <Card>
          <Card.Header className="py-2 text-xs font600 text-info">
            {enrollWithRetakes
              ? 'SELECTED RETAKES TO ENROLL'
              : 'COURSES/MODULES TO BE REGISTERED'}
            <div className="card-options">
              <Button
                size="small"
                danger
                type="primary"
                icon={<DeleteOutlined />}
                onClick={() => setSelectedCourseUnits([])}
                className="text-xs text-uppercase"
              >
                Clear
              </Button>
            </div>
          </Card.Header>
          <Card.Body className="p-0">
            <CourseUnitsTable
              courseUnits={selectedCourseUnits}
              onRowClicked={setDataAndOpenPopUp}
              columnsBefore={[
                {
                  name: '#',
                  cell: (rowData, index) => index + 1,
                  width: '30px',
                },
                {
                  name: 'ACTION',
                  width: '100px',
                  cell: actionButton,
                },
              ]}
              columnsAfter={[
                {
                  name: 'STATUS',
                  selector: (row) =>
                    row.course_unit_status || row.registrationStatus?.label,
                  width: '200px',
                },
                {
                  name: 'STUDY YR',
                  selector: (row) => row.study_year,
                  width: '100px',
                },
              ]}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

SelectedCourseUnits.defaultProps = {
  selectedCourseUnits: [],
  enrollWithRetakes: false,
};

SelectedCourseUnits.propTypes = {
  selectedCourseUnits: PropTypes.oneOfType([array]),
  setSelectedCourseUnits: PropTypes.func.isRequired,
  setDataAndOpenPopUp: PropTypes.func.isRequired,
  enrollWithRetakes: PropTypes.bool,
};

export default SelectedCourseUnits;
