import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes, { object } from 'prop-types';
import { InputText } from '../../../components/common';

function ApplicationStatus({ application }) {
  return (
    <Row>
      <Col md={12}>
        <InputText
          label="Application ID"
          defaultValue={application?.id}
          disabled
          inline
        />
        <InputText
          label="Refund Amount"
          defaultValue={`UGX: ${parseInt(
            application?.amount,
            10
          ).toLocaleString()}/=`}
          disabled
          inline
        />
        <InputText
          label="Application Date"
          defaultValue={application?.date}
          disabled
          inline
        />
        <InputText
          label="Application Status"
          defaultValue="Pending"
          disabled
          inline
        />
      </Col>
    </Row>
  );
}

ApplicationStatus.propTypes = {
  application: PropTypes.oneOfType([object]).isRequired,
};

export default ApplicationStatus;
