import { Modal } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes, { any } from 'prop-types';
import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { settingActions } from '../../config/actions';

function DeletePopUp({
  handleConfirm,
  deleteData,
  deleting,
  itemName,
  deleteError,
}) {
  const dispatch = useDispatch();
  const { showModal } = useSelector((state) => state.setting);

  const onClickConfirm = () => handleConfirm(deleteData);

  return (
    <Modal
      width={500}
      open={showModal}
      onCancel={() => dispatch(settingActions.setShowModal(false))}
      onOk={onClickConfirm}
      okButtonProps={{
        loading: deleting,
        title: 'YES, DELETE',
      }}
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="rounded"
      contentClassName="rounded"
    >
      <div>
        {deleting ? (
          <div className="text-muted fs-6 fw-bolder my-4">
            Deleting in progress....
          </div>
        ) : (
          <span className="text-danger text-sm font600 text-uppercase">
            {!isEmpty(deleteError) ? (
              <div className="text-danger fs-6 fw-bold my-4">{deleteError}</div>
            ) : (
              <div className="font600 d-block">
                <div>
                  <FaExclamationCircle
                    size="4rem"
                    className="mb-3 text-center"
                  />
                </div>
                <div className="fs-5 text-muted fw-bold">{`YOU ARE ABOUT TO DELETE THIS ${itemName}`}</div>
                <div className="text-danger fs-6 fw-bolder my-4">
                  Are you sure you want to proceed?
                </div>
              </div>
            )}
          </span>
        )}
      </div>
    </Modal>
  );
}

DeletePopUp.defaultProps = {
  deleting: false,
  deleteData: null,
  deleteError: null,
};

DeletePopUp.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  deleteData: PropTypes.oneOfType([any]),
  deleting: PropTypes.bool,
  itemName: PropTypes.string.isRequired,
  deleteError: PropTypes.string,
};

export default DeletePopUp;
