import { InputNumber } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import PropTypes, { array } from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DataNotFound, DataSpinner } from '../../../components/common';

function InvoiceFormTable({ invoices, selectedRows, setSelectedRows }) {
  const { loading } = useSelector((state) => state.myInvoice);

  const updateCellAmount = (amountToPay, row) => {
    setSelectedRows(
      uniqBy(
        [
          {
            ...row,
            amountToPay,
          },
          ...selectedRows,
        ],
        'invoice_number'
      )
    );
  };

  return (
    <>
      {loading ? (
        <DataSpinner text="Loading your invoices" />
      ) : (
        <>
          {isEmpty(invoices) ? (
            <DataNotFound message="You have no unpaid invoices" />
          ) : (
            <Table
              responsive
              hover
              striped
              size="sm"
              bordered
              className="w-100"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th style={{ width: '200px' }}>INVOICE NO.</th>
                  <th style={{ width: '200px' }}>DESCRIPTION</th>
                  <th>AMOUNT</th>
                  <th>CURRENCY</th>
                  <th style={{ width: '100px' }}>AMOUNT DUE</th>
                  <th style={{ width: '200px' }}>AMOUNT-TO-PAY</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice, index) => {
                  const findInvoice = selectedRows.find(
                    (selectedInvoice) =>
                      selectedInvoice.invoice_number === invoice.invoice_number
                  );

                  return (
                    <tr key={invoice.invoice_number}>
                      <td>{index + 1}</td>
                      <td>{invoice.invoice_number}</td>
                      <td>{invoice.description}</td>
                      <td>
                        {parseInt(invoice.invoice_amount, 10).toLocaleString()}
                      </td>
                      <td>{invoice.currency}</td>
                      <td>
                        {parseInt(invoice.amount_due, 10).toLocaleString()}
                      </td>
                      <td style={{ width: '200px' }}>
                        <InputNumber
                          min={1}
                          max={9000000000000}
                          className="w-100"
                          value={findInvoice?.amountToPay}
                          onChange={(amountToPay) =>
                            updateCellAmount(amountToPay, invoice)
                          }
                          key={invoice.invoice_number}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </>
      )}
    </>
  );
}

InvoiceFormTable.defaultProps = {
  invoices: [],
  selectedRows: [],
};

InvoiceFormTable.propTypes = {
  invoices: PropTypes.oneOfType([array]),
  selectedRows: PropTypes.oneOfType([array]),
  setSelectedRows: PropTypes.func.isRequired,
};

export default InvoiceFormTable;
