import { Button, Popconfirm } from 'antd';
import { isEmpty, map } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitButton } from '../../../components/common';
import { serviceActions } from '../../../config/actions';
import { getPaymentStatus } from '../../../helpers/dataFormatter';

function ApplicationStatus({ application, currentTab, setIsEditing }) {
  const { generatingPRN, deletingService } = useSelector(
    (state) => state.service
  );
  const { activeProgramme } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const generatePRN = () => {
    dispatch(
      serviceActions.generateServicePRN(
        'change-of-programme',
        application?.id,
        application?.student_programme_id,
        currentTab
      )
    );
  };

  const deletePendingApplication = () => {
    dispatch(
      serviceActions.deletePendingService(
        'change-of-programme',
        application?.id,
        activeProgramme?.id,
        currentTab
      )
    );
  };

  return (
    <>
      {application.ura_prn && application.amount !== application.paid && (
        <Alert variant="success" className="text-center p-3 rounded">
          <Alert.Heading className="text-uppercase fs-5">
            Use this Reference Number{' '}
            <strong className="text-danger fs-4">{application.ura_prn}</strong>{' '}
            to Pay for Change of Study Time at any our Banks.
          </Alert.Heading>

          <div className="fs-5">Expiry date: {application.expiry_date}</div>
        </Alert>
      )}
      <Row>
        <Col md={12}>
          <Table bordered responsive>
            <tbody>
              <tr>
                <td width={200}>PROGRAMME</td>
                <td>
                  {application?.studentProgramme?.programme?.programme_title}
                </td>
              </tr>
              {!isEmpty(application.newSubjectComb) && (
                <tr>
                  <td>NEW SUBJECT COMBINATIONS</td>
                  <td>
                    {map(
                      application.newSubjectComb?.subjects,
                      (e) => e?.subject?.subject_code
                    ).join('/')}
                  </td>
                </tr>
              )}
              <tr>
                <td>STUDY YEAR</td>
                <td>{application.studyYear?.programme_study_years}</td>
              </tr>
              <tr>
                <td>CAMPUS</td>
                <td>{application.newCampus?.metadata_value}</td>
              </tr>
              <tr>
                <td>NEW STUDY TIME</td>
                <td>
                  {application.newProgrammeType?.programmeType
                    ?.metadata_value || 'N/A'}
                </td>
              </tr>
              <tr>
                <td>ACADEMIC YEAR</td>
                <td>{application.academicYear?.metadata_value}</td>
              </tr>
              <tr>
                <td>REASON FOR CHANGE</td>
                <td>{application?.reason || 'N/A'}</td>
              </tr>
              <tr>
                <td>APPLICATION DATE</td>
                <td>{moment(application.created_at).format('LLLL')}</td>
              </tr>
              <tr>
                <td>APPLICATION STATUS</td>
                <td>{application.request_status}</td>
              </tr>
              <tr>
                <td>AMOUNT BILLED</td>
                <td>{`${parseInt(application?.amount, 10).toLocaleString()} ${
                  application.currency
                }`}</td>
              </tr>
              <tr>
                <td>AMOUNT PAID</td>
                <td>
                  {`${parseInt(application?.paid, 10).toLocaleString()} ${
                    application.currency
                  }`}
                </td>
              </tr>
              <tr>
                <td>PAYMENT STATUS</td>
                <td>{getPaymentStatus(application?.payment_status)}</td>
              </tr>
              <tr>
                <td>PAYMENT REFERENCE</td>
                <td>{application?.ura_prn || 'NOT GENERATED'}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col md={12} className="text-end">
          <SubmitButton
            text="GENERATE PAYMENT REFERENCE"
            loading={generatingPRN}
            loadingText="Generating..."
            onClick={generatePRN}
            size="small"
            className="me-2 fw-bold"
          />
          {application.request_status === 'PENDING' && (
            <>
              <SubmitButton
                text="Edit Application"
                size="small"
                className="text-xs me-2 fw-bold text-uppercase"
                onClick={() => setIsEditing(true)}
                iconBefore={<FaPencilAlt className="mx-1" />}
              />
              <Popconfirm
                title="Delete Application"
                description="Are you sure you want to delete Change of Study Time Request?"
                onConfirm={deletePendingApplication}
                okButtonProps={{
                  loading: deletingService,
                }}
              >
                <Button
                  size="small"
                  danger
                  type="primary"
                  loading={deletingService}
                  icon={<FaTrash />}
                >
                  DELETE
                </Button>
              </Popconfirm>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

ApplicationStatus.propTypes = {
  application: PropTypes.oneOfType([object]).isRequired,
  setIsEditing: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
};

export default ApplicationStatus;
