import { find, get, isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaExternalLinkAlt, FaReply } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  InputSelectField,
  InputText,
  SubmitButton,
} from '../../../components/common';
import { appActions } from '../../../config/actions';
import {
  formatMetadata,
  generalFormatter,
} from '../../../helpers/dataFormatter';
import usePrevious from '../../Hooks/usePrevious';
import PreviewChange from './PreviewChange';

function EditApplicationForm({
  dataToEdit,
  currentEvent,
  setCurrentTab,
  currentTab,
  setIsEditing,
  navigateUser,
}) {
  const { metadata } = useSelector((state) => state.app);
  const { activeProgramme } = useSelector((state) => state.auth);
  const { submittingApplication, programmes, updatedApplication } = useSelector(
    (state) => state.service
  );
  const dispatch = useDispatch();
  const { register, control, reset, handleSubmit, errors } = useForm();
  const [academicYear, setAcademicYear] = useState({});
  const [studyTimeOptions, setStudyTimeOptions] = useState([]);
  const [currentProgramme, setCurrentProgramme] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const prevState = usePrevious({ updatedApplication });

  useEffect(() => {
    if (isEmpty(metadata)) {
      dispatch(appActions.getMetadata());
    }
  }, []);

  useEffect(() => {
    if (metadata) {
      const findAcademicYear = find(
        formatMetadata(metadata, 'ACADEMIC YEARS', 'id', 'desc'),
        (e) => e.label === currentEvent.academic_year
      );
      setAcademicYear(findAcademicYear);
    }
    const data = {
      reason: dataToEdit?.reason,
      new_programme_type_id: {
        value: dataToEdit?.new_programme_type_id,
        label: dataToEdit?.newProgrammeType?.programmeType?.metadata_value,
      },
    };
    setSelectedData(data);
  }, [currentEvent, metadata]);

  useEffect(() => {
    reset(selectedData);
    setTimeout(() => {
      reset(selectedData);
    });

    if (
      !isEmpty(updatedApplication) &&
      !isEmpty(prevState) &&
      prevState.updatedApplication !== updatedApplication
    ) {
      setIsEditing(false);
    }
  }, [selectedData, showPreview, updatedApplication]);

  useEffect(() => {
    const findProgramme = find(
      programmes,
      (programme) =>
        parseInt(programme.id, 10) ===
        parseInt(dataToEdit?.new_programme_id, 10)
    );

    let studyTimes = [];

    if (findProgramme) {
      studyTimes = generalFormatter(
        findProgramme?.programme_types,
        'programme_type',
        'programme_type_id'
      );
    }

    setStudyTimeOptions(studyTimes);

    setCurrentProgramme(findProgramme || {});
  }, [programmes, activeProgramme]);

  const submitFormHandler = (data) => {
    if (currentProgramme) {
      data.new_programme_id = currentProgramme.id;

      const findStudyTime = find(
        currentProgramme.programme_study_years,
        (e) => e.programme_study_year === activeProgramme.current_study_year
      );

      if (findStudyTime) {
        data.new_study_year_id = findStudyTime?.context_id;
      }
    }

    if (!isEmpty(data) && !submittingApplication) {
      const applicationData = {
        ...data,
        event_id: currentEvent?.id,
        new_programme_version_id: activeProgramme?.version_id,
        new_campus_id: activeProgramme?.campus_id,
        academic_year_id: academicYear,
      };

      setSelectedData(applicationData);
      setShowPreview(true);
    }
  };

  const handleClick = () => {
    setCurrentTab('service-dashboard');
    navigateUser();
  };

  return (
    <>
      {isEmpty(currentEvent) ? (
        <DataNotFound message="THERE IS NO ACTIVE CHANGE OF PROGRAMME EVENT FOR YOUR PROGRAMME, PLEASE CONTACT YOUR ACADEMIC REGISTRAR FOR HELP">
          <div className="text-center my-3">
            <Button
              size="sm"
              onClick={handleClick}
              className="text-sm font600 text-uppercase ms-1"
              variant="warning"
            >
              <FaReply className="me-1" />
              Back
            </Button>
          </div>
        </DataNotFound>
      ) : (
        <>
          {showPreview && !isEmpty(selectedData) ? (
            <PreviewChange
              data={selectedData}
              isEditing
              setIsEditing={setIsEditing}
              dataToEdit={dataToEdit}
              setShowPreview={setShowPreview}
              currentTab={currentTab}
              selectedProgramme={currentProgramme}
            />
          ) : (
            <Form onSubmit={handleSubmit(submitFormHandler)}>
              <InputText
                name="current_programme"
                label="CURRENT PROGRAMME"
                inline
                defaultValue={activeProgramme.programme_title}
                requiredField
                readOnly
              />
              <InputText
                name="new_study_year_id"
                label="STUDY YEAR:"
                inline
                readOnly
                defaultValue={activeProgramme?.current_study_year}
              />
              <InputText
                name="new_campus_id"
                label="CAMPUS:"
                inline
                readOnly
                defaultValue={activeProgramme?.campus || 'N/A'}
              />
              <InputText
                name="academic_year"
                label="ACADEMIC YEAR:"
                inline
                readOnly
                key={academicYear?.value}
                defaultValue={academicYear?.label || 'N/A'}
              />
              <InputText
                name="current_study_time"
                label="CURRENT STUDY TIME:"
                inline
                readOnly
                defaultValue={activeProgramme?.programme_type || 'N/A'}
              />
              <Fragment key={currentProgramme?.value}>
                <InputSelectField
                  name="new_programme_type_id"
                  label="NEW STUDY TIME:"
                  requiredField
                  inline
                  selectOptions={studyTimeOptions}
                  control={control}
                  rules={{
                    required: 'Select Programme Study Type',
                  }}
                  error={get(errors, 'new_programme_type_id.message')}
                />
              </Fragment>
              <InputText
                name="reason"
                label="REASON FOR CHANGE:"
                type="textarea"
                requiredField
                inline
                rows={5}
                register={register({
                  required: 'Include Reason for Program Change',
                })}
                error={get(errors, 'reason.message')}
              />
              <SubmitButton
                text="Preview and Submit"
                size="small"
                loadingText="Submitting Application..."
                className="float-end text-uppercase"
                iconBefore={<FaExternalLinkAlt className="mx-1" />}
                loading={submittingApplication}
              />
              <SubmitButton
                text="Go Back"
                className="float-end text-uppercase me-1"
                danger
                size="small"
                iconBefore={<FaReply className="mx-1" />}
                onClick={() => setIsEditing(false)}
              />
            </Form>
          )}
        </>
      )}
    </>
  );
}

EditApplicationForm.defaultProps = {
  currentEvent: {},
};

EditApplicationForm.propTypes = {
  currentEvent: PropTypes.oneOfType([object]),
  dataToEdit: PropTypes.oneOfType([object]).isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  navigateUser: PropTypes.func.isRequired,
};

export default EditApplicationForm;
