import { Image } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLogo from '../../assets/img/default.png';
import { appActions } from '../../config/actions';
import AcademicDetail from './AcademicDetail';
import BioData from './BioData';
import GuardianDetail from './GuardianDetail';
import Avatar from './Avatar';

function PrintBioData({ authUser }) {
  const { institutionStructure } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(institutionStructure)) {
      dispatch(appActions.getInstitutionStructure());
    }
  }, []);

  const printColumns = [
    {
      Component: BioData,
      title: 'PERSONAL DETAILS',
      id: 1,
    },
    {
      Component: AcademicDetail,
      title: 'ACADEMIC DETAILS',
      id: 2,
    },
    {
      Component: GuardianDetail,
      title: 'GUARDIAN/PARENT DETAILS',
      id: 3,
    },
  ];
  return (
    <>
      <Card body className="border-0 mb-2">
        <Row className="row-deck text-sm mb-3">
          <Col md={5} xs={5}>
            <Card className="border-0 px-3 my-auto text-end">
              <div className="fs-2 font600 text-uppercase">
                {institutionStructure.institution_name.split(' ')[0]}
              </div>
            </Card>
          </Col>
          <Col md={2} xs={2} className="d-flex justify-content-center">
            <Image
              fallback={DefaultLogo}
              width="100px"
              height="100px"
              src={`${process.env.REACT_APP_INSTITUTION_LOGO_PATH}/${institutionStructure?.institution_logo}`}
              alt="Logo"
            />
          </Col>
          <Col md={5} xs={5}>
            <Card className="border-0 px-3 my-auto text-start">
              <div className="fs-2 font600 text-uppercase">
                {institutionStructure.institution_name.split(' ')[1]}
              </div>
            </Card>
          </Col>
        </Row>
        <div className="text-center text-md position-relative">
          <i className="d-block fs-4 fw-bold">{`Office of the Academic Registrar `}</i>
          <div className="my-2">{`${institutionStructure?.institution_address}`}</div>
          <div className="my-2">{`Tel: ${institutionStructure?.telephone_1}${
            institutionStructure?.telephone_2
              ? `/ ${institutionStructure?.telephone_2}`
              : ''
          }`}</div>
          <div>
            Email: {institutionStructure?.institution_email || ''}, Website:{' '}
            {institutionStructure?.institution_website || ''}
          </div>
          <div
            className="position-absolute"
            style={{ top: 0, right: '50px !important' }}
          >
            <Avatar height={100} width={100} />
          </div>
        </div>
      </Card>

      <div className="my-4 text-center">
        <p className="fw-bold">STUDENT BIO-DATA</p>
        <p className="pb-1 text-xs text-muted">
          {`Print Date: ${moment(new Date()).format('llll')}`}
        </p>
      </div>

      <hr className="border border-2 border-primary" />

      {printColumns.map((Context) => (
        <div key={Context.id}>
          <div className="py-2 px-3 border-bottom border-2 border-primary bg-white mb-1 fw-bold w-100">
            <div className="text-primary">{Context.title}</div>
          </div>
          <Context.Component authUser={authUser} key={Context.id} />
        </div>
      ))}
    </>
  );
}

PrintBioData.propTypes = {
  authUser: PropTypes.oneOfType([object]).isRequired,
};

export default PrintBioData;
