const paymentStatuses = [
  {
    key: 'T',
    meaning: 'PAID',
  },
  {
    key: 'R',
    meaning: 'RECEIVED BUT NOT CREDITED',
  },
  {
    key: 'D',
    meaning: 'RECEIVED BUT DISHONORED',
  },
  {
    key: 'A',
    meaning: 'AVAILABLE BUT NOT PAID',
  },
  {
    key: 'C',
    meaning: 'CANCELLED',
  },
  {
    key: 'X',
    meaning: 'CANCELLED',
  },
];

export default paymentStatuses;
