import { isFunction, toLower } from 'lodash';
import PropTypes, { any, array } from 'prop-types';
import React, { Fragment } from 'react';
import { Card, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function TabMenu({
  menus,
  currentMenu,
  setCurrentMenu,
  children,
  disabled,
  navigateUser,
  ...props
}) {
  const { appNavigation } = useSelector((state) => state.setting);

  const handleClick = (menu) => {
    const { menuLevel_2: activeTab } = appNavigation;
    if (isFunction(navigateUser)) {
      if (toLower(activeTab) === 'my payment refs') {
        navigateUser(activeTab, menu.title);
      } else {
        navigateUser(menu.title);
      }
    }
    setCurrentMenu(menu.action);
  };
  return (
    <Nav
      variant="tabs"
      as={Card.Header}
      disabled={disabled}
      className="w-100 m-0 pb-0 border-bottom pt-2 text-sm font500 text-uppercase"
      {...props}
    >
      {menus.map((menu) => {
        if (menu.hidden !== true) {
          return (
            <Fragment key={menu.action}>
              <Nav.Item>
                <Nav.Link
                  active={currentMenu === menu.action}
                  onClick={() => handleClick(menu)}
                  disabled={menu.disabled}
                  className="font600 text-sm py-3"
                >
                  {menu.icon && menu.icon}
                  {menu.title}
                </Nav.Link>
              </Nav.Item>
            </Fragment>
          );
        }
        return null;
      })}
      {children}
    </Nav>
  );
}

TabMenu.defaultProps = {
  children: null,
  disabled: false,
};

TabMenu.propTypes = {
  menus: PropTypes.oneOfType([array]).isRequired,
  children: PropTypes.oneOfType([any]),
  currentMenu: PropTypes.string.isRequired,
  setCurrentMenu: PropTypes.func.isRequired,
  navigateUser: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default TabMenu;
