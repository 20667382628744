import { Image, Space } from 'antd';
import { isEmpty, isEqual, orderBy, sumBy, toUpper } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import ARsignature from '../../../assets/img/ARsignature.png';
import DefaultLogo from '../../../assets/img/default.png';
import { formatName, whichInstitution } from '../../../helpers/dataFormatter';
import Avatar from '../../MyProfile/Avatar';

function ExamPermit({ selectedRegistration }) {
  const { authUser, currentStudentProgramme } = useSelector(
    (state) => state.auth
  );
  const { institutionStructure } = useSelector((state) => state.app);
  const { invoiceTotalDue } = useSelector((state) => state.myInvoice);
  const institutionLogo = `${process.env.REACT_APP_INSTITUTION_LOGO_PATH}/${institutionStructure?.institution_logo}`;

  const {
    registration_study_year: studyYear,
    event_semester: semester,
    academic_year: academicYear,
    courseUnits,
  } = selectedRegistration;

  const isMubs = whichInstitution('MUBS', institutionStructure);

  return (
    <div
      style={{
        backgroundImage: `url(${institutionLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '50%',
        lineHeight: 1,
      }}
    >
      <div
        className="border border-primary p-3"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.835)',
        }}
      >
        <Row>
          <Col className="col-2 d-flex align-self-center justify-content-center">
            <Image
              src={institutionLogo}
              fallback={DefaultLogo}
              alt="Logo"
              preview={false}
              style={{ maxWidth: '100px' }}
            />
          </Col>
          <Col className="col-8 text-uppercase fw-bold">
            <h5 className="text-uppercase fs-4 fw-bold">
              {institutionStructure?.institution_name}
            </h5>
            <div className="fw-bold my-2 text-md">
              {` Office of the ${isMubs ? 'School' : 'Academic'} Registrar`}
            </div>
            <div className="mb-2">STUDENT EXAMINATION PERMIT</div>
            <div className="text-muted">
              <small>
                PRINT DATE: {moment().format('dddd, MMMM Do YYYY, h:mm:ss a')}
              </small>
            </div>
          </Col>
          <Col className="col-2d-flex align-self-center justify-content-end">
            <QRCode
              size={80}
              value={`${process.env.REACT_APP_DOCUMENT_VERIFICATION_LINK}?sN=${authUser?.student_number}&cat=exam_permit`}
            />
          </Col>
        </Row>

        <hr className="mb-4 border border-primary border-4" />

        <div
          className={`${
            isEqual(
              toUpper(selectedRegistration?.registration_type),
              'FULL REGISTRATION'
            )
              ? 'bg-primary'
              : 'bg-warning'
          } p-2 text-md mb-4 text-center fw-bold text-uppercase text-white`}
        >
          EXAMINATION PERMIT
        </div>
        <Row className="row-deck g-1">
          <Col className="col-2">
            <Card
              className="p-0 bg-transparent d-flex"
              style={{ border: 'dashed 1px var(--bs-primary)' }}
            >
              <Avatar
                src={`${process.env.REACT_APP_STUDENTS_PHOTO_URL}/${authUser?.avatar}`}
                height={120}
                width={120}
                className="my-auto"
              />
            </Card>
          </Col>
          <Col className="col-10">
            <div
              className="w-100"
              style={{ border: 'dashed 1px var(--bs-primary)' }}
            >
              <Table
                size="sm"
                striped
                borderless
                className="m-0 text-sm"
                style={{ fontSize: '10px !important' }}
              >
                <tbody>
                  <tr>
                    <td width="130" className="fw-bold">
                      FULL NAME:
                    </td>
                    <td colSpan={2}>
                      {formatName(
                        `${authUser?.surname} ${authUser?.other_names}`
                      )}
                    </td>
                    <td colSpan={1}>
                      <strong>FEES BAL DUE: </strong>
                      {`${parseInt(invoiceTotalDue, 10).toLocaleString()} UGX`}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">GENDER:</td>
                    <td colSpan={3}>{toUpper(authUser?.gender)}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">STUDENT NO.:</td>
                    <td colSpan={3}>
                      {isMubs &&
                      !isEmpty(currentStudentProgramme?.old_student_number)
                        ? currentStudentProgramme?.old_student_number
                        : currentStudentProgramme?.student_number}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">REGISTRATION NO.:</td>
                    <td>{authUser?.registration_number}</td>
                    {isMubs &&
                      !isEqual(
                        toUpper(selectedRegistration?.registration_type),
                        'FULL REGISTRATION'
                      ) && (
                        <>
                          <td className="fw-bold">REGISTRATION:</td>
                          <td>{selectedRegistration?.registration_type}</td>
                        </>
                      )}
                  </tr>
                  <tr>
                    <td className="fw-bold">PROGRAMME:</td>
                    <td
                      colSpan={3}
                    >{`(${selectedRegistration?.programme_code}) ${selectedRegistration?.programme_title}`}</td>
                  </tr>
                  <tr className="my-1">
                    <td className="fw-bold">STUDY YEAR:</td>
                    <td>{studyYear}</td>
                    <td className="fw-bold">CAMPUS:</td>
                    <td>{selectedRegistration?.campus || ''}</td>
                  </tr>
                  <tr className="my-1">
                    <td className="fw-bold">SEMESTER:</td>
                    <td>{semester}</td>
                    <td className="fw-bold">ACADEMIC YEAR:</td>
                    <td>{academicYear}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <div
          className="text-uppercase text-primary text-md fw-bold mt-4 mb-2 p-2 text-center"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.61)' }}
        >
          COURSES/MODULES REGISTERED
        </div>
        <Card
          style={{ border: 'dashed 1px' }}
          className=" bg-transparent border-primary mb-3"
        >
          <Table size="sm" striped borderless className="text-sm m-0">
            <thead className="border-bottom text-muted">
              <tr>
                <th>#</th>
                <th>COURSE NAME</th>
                <th className="text-center">CREDIT UNITS</th>
                <th className="text-start">CATEGORY</th>
                <th className="text-start">STATUS</th>
              </tr>
            </thead>
            <tbody>
              {orderBy(courseUnits, ['course_unit_code']).map(
                (course, index) => (
                  <tr key={course.course_unit_id}>
                    <td>{index + 1}</td>
                    <td>
                      <strong className="me-1">
                        {`${course.course_unit_code}:`}
                      </strong>
                      {course.course_unit_name}
                    </td>
                    <td className="text-center">{course.credit_units}</td>
                    <td>{course.category}</td>
                    <td>{course.course_unit_status}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Card>

        <footer>
          {isMubs ? (
            <div className="fst-italic">
              <div className="fw-bold">
                Total Credits Registered for <strong>{academicYear}</strong>{' '}
                <strong>{semester}</strong> is{' '}
                <strong>{sumBy(courseUnits, 'credit_units')}</strong>.
              </div>
              <p className="mt-2">
                <strong className="text-decoration-underline me-1">
                  NOTES:
                </strong>
                This is to certify that the above named has been authorized to
                sit the stated examination(s). This Examination Permit is
                confidential and must be produced to the invigilator when
                requireed at each examination. You are cautioned against
                contravening any of the University/School rules and Regulations.
                Please do not write anything on this Examination Permit.{' '}
                <strong>
                  Always refer to the notice boards for the latest timetable
                  updates
                </strong>
              </p>
            </div>
          ) : (
            <>
              <div className="text-decoration-underline mb-2">
                <strong>NOTES:</strong>
              </div>
              <ol className="fst-italic">
                <li className="fw-bold">
                  Total Credits Registered for <strong>{academicYear}</strong>{' '}
                  <strong>{semester}</strong> is{' '}
                  <strong>{sumBy(courseUnits, 'credit_units')}</strong>.
                </li>
                <li>
                  This card is confidential and must be produced to the
                  invigilator when required at each examination.
                </li>
                <li>
                  Your <strong>STUDENT NUMBER</strong> and not your name must
                  appear on every answer book or supplementary sheet.
                </li>
                <li>
                  Unauthorized materials/notes should <strong>NOT</strong> be
                  carried into the examination room.
                </li>
                <li>
                  Mobile phones, Ipads and Tablets should <strong>NOT</strong>{' '}
                  be brought close to the examination room.
                </li>
                <li>
                  Students shall abide by the University Examination
                  Regulations.
                </li>
              </ol>
            </>
          )}
        </footer>

        <Row className="mt-3">
          {isMubs && (
            <Col className="d-flex align-items-end">
              <Space>
                <div className="mt-100 fw-bold">REGISTERED BY:</div>
                {selectedRegistration?.registered_by === 'STAFF' ? (
                  <span>STAFF ( {formatName(selectedRegistration)} )</span>
                ) : (
                  <span>{selectedRegistration?.registered_by}</span>
                )}
              </Space>
            </Col>
          )}
          <Col className={`col-3 ${!isMubs ? 'offset-9' : ''} text-center`}>
            <Image
              src={ARsignature}
              alt="Signature"
              preview={false}
              draggable={false}
              style={{ maxHeight: '80px' }}
            />
            <div>
              {isMubs && <span>Eldred Kyomuhangi Manyindo</span>}
              <div className="fw-bold text-sm mt-1">{`${
                isMubs ? 'SCHOOL' : 'ACADEMIC'
              } REGISTRAR`}</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

ExamPermit.propTypes = {
  selectedRegistration: PropTypes.oneOfType([object]).isRequired,
};

export default ExamPermit;
