import React, { useEffect, useState } from 'react';
import { isEmpty, orderBy } from 'lodash';
import { Accordion } from 'react-bootstrap';
import { FaPaperclip } from 'react-icons/fa';
import PropTypes, { array, number, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SinglePaymentReference from './SinglePaymentReference';
import AccordionHeader from '../../components/common/AccordionHeader';
import {
  DataNotFound,
  DataSpinner,
  ReloadButton,
  TabMenu,
} from '../../components/common';
import { paymentReferenceActions, serviceActions } from '../../config/actions';
import { formatName } from '../../helpers/dataFormatter';

function PaymentReferencesDetails({
  referenceTab,
  activeKey,
  setActiveKey,
  setReferenceTab,
  navigateUser,
  paymentData,
  usage,
  ...props
}) {
  const dispatch = useDispatch();
  const [currentPaymentRef, setCurrentPaymentRef] = useState([]);
  const { paymentReferences, gettingPaymentReferences } = useSelector(
    (state) => state.paymentReference
  );
  const { gettingServiceHistory } = useSelector((state) => state.service);
  const { authUser } = useSelector((state) => state.auth);

  const checkIsStudent = ['STUDENT', `${formatName(authUser)}`];
  const isCop = usage === 'COP';

  const reloadPaymentReferences = () => {
    if (isCop) {
      dispatch(serviceActions.getServiceHistory('change-of-programme'));
    } else {
      dispatch(paymentReferenceActions.getPaymentReferences());
    }
  };

  useEffect(() => {
    if (isEmpty(paymentReferences)) reloadPaymentReferences();
  }, []);

  useEffect(() => {
    if (isCop) {
      setCurrentPaymentRef(paymentData);
    } else {
      setCurrentPaymentRef(paymentReferences[referenceTab]);
    }
  }, [referenceTab]);

  const menus = [
    {
      title: `Active References (${paymentReferences?.active?.length || 0})`,
      action: 'active',
      hidden: isCop,
    },
    {
      title: !isCop
        ? `Expired References (${paymentReferences?.inactive?.length || 0})`
        : 'Expired Reference',
      action: 'inactive',
      hidden: false,
    },
  ].filter((ref) => ref.hidden !== true);

  return (
    <>
      <TabMenu
        className="bg-white border"
        variant="pills"
        currentMenu={referenceTab}
        setCurrentMenu={setReferenceTab}
        navigateUser={navigateUser}
        menus={menus}
      >
        <div className="card-options">
          <ReloadButton
            loading={gettingPaymentReferences || gettingServiceHistory}
            onClick={reloadPaymentReferences}
          />
        </div>
      </TabMenu>
      {gettingPaymentReferences && isEmpty(currentPaymentRef) ? (
        <DataSpinner />
      ) : (
        <>
          {isEmpty(currentPaymentRef) ? (
            <DataNotFound
              message={`You have No ${
                isCop ? 'Change of programme' : referenceTab
              } Payment References`}
            />
          ) : (
            <Accordion defaultActiveKey="1" activeKey={activeKey} {...props}>
              {orderBy(currentPaymentRef, ['ura_prn'], 'desc').map(
                (paymentReference) => (
                  <Accordion.Item
                    className="mb-0"
                    key={paymentReference.id}
                    eventKey={paymentReference?.id}
                  >
                    <AccordionHeader
                      activeKey={activeKey}
                      eventKey={paymentReference?.id}
                      setActiveKey={setActiveKey}
                    >
                      <FaPaperclip className="me-2" />
                      REFERENCE:
                      <span className="text-danger ms-1">
                        {paymentReference.ura_prn}
                      </span>
                      , AMOUNT TO PAY:
                      <strong className="text-danger mx-1">
                        {parseInt(paymentReference.amount, 10).toLocaleString()}
                      </strong>
                      UGX, EXPIRY DATE:
                      <span className="mx-1 text-danger">
                        {`${paymentReference.expiry_date}`}
                      </span>
                      , GENERATED BY:
                      <span className="ms-1 text-danger">
                        {`${
                          checkIsStudent.includes(paymentReference.generated_by)
                            ? 'SELF'
                            : paymentReference.generated_by
                        }`}
                      </span>
                    </AccordionHeader>
                    <Accordion.Body>
                      <SinglePaymentReference
                        paymentReference={paymentReference}
                        key={paymentReference.id}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                )
              )}
            </Accordion>
          )}
        </>
      )}
    </>
  );
}
PaymentReferencesDetails.defaultProps = {
  activeKey: 1,
  usage: 'PRN',
  referenceTab: 'active',
  setReferenceTab: () => {},
  navigateUser: () => {},
  paymentData: [],
};

PaymentReferencesDetails.propTypes = {
  usage: PropTypes.string,
  activeKey: PropTypes.oneOfType([number, string]),
  referenceTab: PropTypes.string,
  setActiveKey: PropTypes.func.isRequired,
  setReferenceTab: PropTypes.func,
  navigateUser: PropTypes.func,
  paymentData: PropTypes.oneOfType([array]),
};

export default PaymentReferencesDetails;
