import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Accordion, Card, Col, Row, Table } from 'react-bootstrap';
import { FaUserGraduate } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { DataNotFound, ReloadButton } from '../../components/common';
import { enrollmentActions } from '../../config/actions';

function MigratedEnrollment() {
  const dispatch = useDispatch();
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { gettingMigratedRecords, migratedRecords } = useSelector(
    (state) => state.enrollment
  );

  const getMigratedEnrollments = () =>
    dispatch(
      enrollmentActions.getMigratedEnrollments(currentStudentProgramme?.id)
    );
  useEffect(() => {
    if (isEmpty(migratedRecords)) getMigratedEnrollments();
  }, []);
  return (
    <>
      <Card.Header className="py-2 px-3 text-secondary text-sm mb-2 font600">
        MY MIGRATED ENROLLMENTS/REGISTRATIONS
        {` (${!isEmpty(migratedRecords) ? migratedRecords.length : 0})`}
        <div className="card-options">
          <ReloadButton
            loading={gettingMigratedRecords}
            onClick={getMigratedEnrollments}
          />
        </div>
      </Card.Header>
      {isEmpty(migratedRecords) ? (
        <>
          <DataNotFound message="You have No Migrated Enrollment/Registration Records." />
          <div className="text-center">
            <ReloadButton
              loading={gettingMigratedRecords}
              onClick={getMigratedEnrollments}
            />
          </div>
        </>
      ) : (
        <Accordion defaultActiveKey={1}>
          {migratedRecords.map((registrationHistory, index) => (
            <Accordion.Item
              eventKey={index + 1}
              className="mb-2"
              key={`${registrationHistory?.study_year}-${registrationHistory.semester_id}-${registrationHistory.academic_year_id}`}
            >
              <Accordion.Header>
                <FaUserGraduate className="me-2" />
                {`${registrationHistory?.study_year}, ${registrationHistory.semester} - ${registrationHistory.academic_year}`}
              </Accordion.Header>
              <Accordion.Body>
                <>
                  <Row className="row-deck text-muted text-uppercase">
                    <Col md={6} className="mb-3">
                      <Card body className="py-1 bg-light">
                        <p className="text-sm">
                          <span className="font600 text-uppercase me-1">
                            Academic Year:
                          </span>
                          {registrationHistory.academic_year}
                        </p>
                        <p className="text-sm">
                          <span className="font600 text-uppercase me-1">
                            SEMESTER:
                          </span>
                          {registrationHistory.semester}
                        </p>
                        <p className="text-sm">
                          <span className="font600 text-uppercase me-1">
                            STUDY YEAR:
                          </span>
                          {registrationHistory.study_year}
                        </p>
                      </Card>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Card body className="py-1 bg-light">
                        <p className="text-sm">
                          <span className="font600 text-uppercase me-1">
                            Enrollment Token:
                          </span>
                          {registrationHistory.enrollments[0]?.enrollment_token}
                        </p>
                        <p className="text-sm">
                          <span className="font600 text-uppercase me-1">
                            Registration Token:
                          </span>
                          {
                            registrationHistory.enrollments[0]
                              ?.registration_token
                          }
                        </p>
                        <p className="text-sm mb-0">
                          <span className="font600 text-uppercase me-1">
                            ENROLLED ON:
                          </span>
                          {moment(
                            registrationHistory.enrollments[0]
                              ?.registration_date
                          ).format('ddd, MMM Do YYYY')}
                          ,
                          <span className="font600 text-uppercase mx-1">
                            REGISTERED ON:
                          </span>
                          {moment(
                            registrationHistory.enrollments[0]?.enrollment_date
                          ).format('ddd, MMM Do YYYY')}
                        </p>
                      </Card>
                    </Col>
                    <Col className="d-flex py-2">
                      <div className="my-auto ms-2 w-100">
                        <Card.Header className="text-primary py-2 bg-gray font600">
                          Record Details
                        </Card.Header>
                        <Table
                          size="sm"
                          responsive
                          bordered
                          striped
                          className="mb-0 font500 text-sm"
                        >
                          <tbody>
                            <tr>
                              <td>Registration Status:</td>
                              <td>{`${registrationHistory.enrollments[0]?.registration_status}`}</td>
                            </tr>
                            <tr>
                              <td>Enrollment Status:</td>
                              <td>{`${registrationHistory.enrollments[0]?.enrollment_status}`}</td>
                            </tr>
                            <tr>
                              <td>Tuition Invoice No:</td>
                              <td>{`${registrationHistory.enrollments[0]?.tuition_invoice_no}`}</td>
                            </tr>
                            <tr>
                              <td>Functional Invoice No:</td>
                              <td>{`${registrationHistory.enrollments[0]?.functional_invoice_no}`}</td>
                            </tr>
                            <tr>
                              <td>Total Bill:</td>
                              <td className="text-primary">
                                {`${registrationHistory.enrollments[0]?.total_bill.toLocaleString()} .UGX`}
                              </td>
                            </tr>
                            <tr>
                              <td>Amount Paid:</td>
                              <td className="text-success">
                                {`${registrationHistory.enrollments[0]?.total_paid.toLocaleString()} .UGX`}
                              </td>
                            </tr>
                            <tr>
                              <td>Amount Due:</td>
                              <td className="text-danger">
                                {`${registrationHistory.enrollments[0]?.total_due.toLocaleString()} .UGX`}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </>
  );
}

export default MigratedEnrollment;
