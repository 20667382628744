import { get } from 'lodash';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InputText, SubmitButton } from '../../../components/common';
import { serviceActions } from '../../../config/actions';

function ApplicationForm() {
  const { register, handleSubmit, errors } = useForm();
  const { submittingApplication } = useSelector((state) => state.service);
  const dispatch = useDispatch();

  const submitFormHandler = (data) => {
    if (data?.file && data?.description) {
      const applicationData = {
        id: 'IdForm101',
        date: new Date().toDateString(),
        ...data,
      };
      dispatch(
        serviceActions.submitApplication(applicationData, 'id-application')
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit(submitFormHandler)}>
      <InputText
        name="file"
        label="Police Letter"
        type="file"
        inline
        register={register({
          required: 'Attach Police Letter',
        })}
        error={get(errors, 'file.message')}
      />
      <InputText
        name="description"
        label="Description"
        type="textarea"
        rows={5}
        inline
        register={register({
          required: 'Include a description',
          minLength: {
            value: 20,
            message: 'Minimum length is 20 characters',
          },
        })}
        error={get(errors, 'description.message')}
      />
      <SubmitButton
        size="sm"
        text="Submit Application"
        loading={submittingApplication}
        loadingText="Submitting Application..."
        className="float-end text-uppercase"
      />
    </Form>
  );
}

ApplicationForm.propTypes = {};

export default ApplicationForm;
