import { Card as AntDCard, Image } from 'antd';
import { isEmpty, map } from 'lodash';
import React, { useMemo } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { FaPen } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import avatar from '../../../assets/img/avatar.png';
import { formatName, whichInstitution } from '../../../helpers/dataFormatter';

const { Meta } = AntDCard;

function StudentCardDetails() {
  const { studentIdTracker } = useSelector((state) => state.service);
  const { authUser, currentStudentProgramme } = useSelector(
    (state) => state.auth
  );
  const { institutionStructure } = useSelector((state) => state.app);
  const isMubs = whichInstitution('MUBS', institutionStructure) === 'MUBS';

  const checkBoolValue = (value) => (value ? 'YES' : 'NO');

  const noValue = '---';

  const dataTemplate = useMemo(() => [
    {
      key: 'serial-number',
      label: 'SERIAL NUMBER',
      value: studentIdTracker?.serial_number || noValue,
    },
    {
      key: 'card-number',
      label: 'CARD NUMBER',
      value: studentIdTracker?.card_number || noValue,
    },
    {
      key: 'approved-by',
      label: 'STUDENT APPROVED',
      value:
        checkBoolValue(studentIdTracker?.acknowledged_by_student) || noValue,
    },
    {
      key: 'is-first',
      label: 'IS FIRST',
      value: checkBoolValue(studentIdTracker?.is_first_card) || noValue,
    },
    {
      key: 'is-active',
      label: 'IS ACTIVE',
      value: checkBoolValue(studentIdTracker?.card_active) || noValue,
    },
    {
      key: 'printed',
      label: 'PRINTED',
      value: checkBoolValue(studentIdTracker?.id_printed) || noValue,
    },
    {
      key: 'issued',
      label: 'ISSUED',
      value: checkBoolValue(studentIdTracker?.card_issued) || noValue,
    },
    {
      key: 'bio-metric-enabled',
      label: 'BIO-METRIC ENABLED',
      value: checkBoolValue(studentIdTracker?.biometric_enable) || noValue,
    },
    {
      key: 'print-date',
      label: 'PRINT DATE',
      value: studentIdTracker?.print_date || noValue,
    },
    {
      key: 'expiry-date',
      label: 'EXPIRE DATE',
      value: studentIdTracker?.expiry_date || noValue,
    },
  ]);
  return (
    <div>
      <Card.Header className="py-2 px-3 bg-primary mb-2">
        <span className="font500 text-uppercase text-white">
          MY CARD DETAILS
        </span>
      </Card.Header>
      <Card className="border-0">
        <Row className="g-1">
          <Col md="auto" className="text-center">
            <AntDCard
              style={{ minWidth: '100%' }}
              bodyStyle={{ padding: 20 }}
              cover={
                <Image
                  style={{
                    width: 100,
                    display: 'flex',
                    margin: '15px auto',
                  }}
                  alt="Profile Photo"
                  fallback={avatar}
                  preview={false}
                  src={`${process.env.REACT_APP_STUDENTS_PHOTOS_URL}/${authUser?.avatar}`}
                />
              }
              actions={[<FaPen key="edit" />]}
            >
              <Meta
                title={formatName(authUser)}
                description={
                  <span className="text-primary fw-bold">
                    {isMubs && !isEmpty(currentStudentProgramme?.student_number)
                      ? currentStudentProgramme?.old_student_number
                      : currentStudentProgramme?.student_number}
                  </span>
                }
                className="text-center"
              />
            </AntDCard>
          </Col>
          <Col className="">
            <Table responsive="md" bordered striped>
              <tbody>
                {map(dataTemplate, (data) => (
                  <tr key={data.key}>
                    <td width="45%" className="fw-bold">
                      {data?.label}:
                    </td>
                    <td className="fw-bold text-primary">{data?.value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default StudentCardDetails;
