import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { CloseCircleOutlined } from '@ant-design/icons';
import StudentAcademicDetails from './StudentAcademicDetails';
import FinanceClearanceDetails from './FinanceClearanceDetails';
import usePrevious from '../Hooks/usePrevious';
import { paymentActions } from '../../config/actions';
import {
  AlertMessage,
  DataNotFound,
  DataSpinner,
  ReloadButton,
} from '../../components/common';

function FinanceClearance() {
  const dispatch = useDispatch();
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { financeClearance, financeClearanceError, gettingFinanceClearance } =
    useSelector((state) => state.myTransaction);
  const [errorMessage, setErrorMessage] = useState(null);
  const prevState = usePrevious({ financeClearance, financeClearanceError });

  const getFinanceClearance = () =>
    dispatch(
      paymentActions.getStudentFinanceClearance(currentStudentProgramme?.id)
    );

  useEffect(() => {
    if (isEmpty(financeClearance)) getFinanceClearance();
  }, [financeClearance]);

  useEffect(() => {
    if (
      prevState &&
      !isEmpty(financeClearanceError) &&
      prevState.financeClearanceError !== financeClearanceError
    ) {
      setErrorMessage(financeClearanceError?.server?.message);
    } else {
      setErrorMessage(null);
    }
  }, [financeClearanceError]);

  return (
    <>
      <Card>
        <Card.Header className="py-2 mb-2 text-primary fw-bold">
          <div>MY FINANCE CLEARANCE</div>
          <div className="card-options">
            <ReloadButton
              loading={gettingFinanceClearance}
              onClick={() => getFinanceClearance()}
            />
          </div>
        </Card.Header>
        <div className="p-2">
          {errorMessage && (
            <AlertMessage
              icon={<CloseCircleOutlined className="me-2" />}
              message={errorMessage}
            />
          )}
          {gettingFinanceClearance && isEmpty(financeClearance) ? (
            <DataSpinner />
          ) : (
            <>
              {isEmpty(financeClearance) ? (
                <DataNotFound message="NO FINANCE CLEARANCE DATA FOUND" />
              ) : (
                <>
                  <Card className="border-0">
                    <div>
                      <StudentAcademicDetails />
                    </div>
                    <div>
                      <FinanceClearanceDetails />
                    </div>
                  </Card>
                </>
              )}
            </>
          )}
        </div>
      </Card>
    </>
  );
}

export default FinanceClearance;
