import { find, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TabMenu } from '../../components/common';
import { paymentReferenceActions, settingActions } from '../../config/actions';
import MenuList from '../../config/routes/MenuList';
import GeneratePaymentReference from './GeneratePaymentReference';
import ReferenceModal from './ReferenceModal';
import PaymentReferencesDetails from './PaymentReferencesDetails';

function PaymentReferences() {
  const dispatch = useDispatch();
  const { paymentReferences } = useSelector((state) => state.paymentReference);
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);
  const [activeKey, setActiveKey] = useState(1);
  const [activeTab, setActiveTab] = useState('generate-reference');
  const [referenceTab, setReferenceTab] = useState('active');

  const menuItems = [
    {
      title: 'Generate New PRN',
      action: 'generate-reference',
    },
    {
      title: 'My Payment REFs',
      action: 'references',
    },
  ];

  const reloadPaymentReferences = () => {
    dispatch(paymentReferenceActions.getPaymentReferences());
  };

  useEffect(() => {
    if (isEmpty(paymentReferences)) reloadPaymentReferences();
  }, []);

  const navigateUser = (route, route2 = null) => {
    const {
      PaymentReference: { label },
    } = MenuList;

    if (!isEmpty(label)) {
      dispatch(
        settingActions.setNavigation({
          ...appNavigation,
          menuLevel_1: label,
          menuLevel_2: route,
          menuLevel_3: route2,
        })
      );
    }
  };

  useEffect(() => {
    const { key } = selectedMenu;
    const { title, action } = find(
      menuItems,
      (item) => item.action === activeTab
    );
    const activeSubmmenu =
      action === 'references' ? `${referenceTab} reference(s)` : null;

    if (key === 'payment-references') {
      navigateUser(title, activeSubmmenu);
    }
  }, [activeTab]);

  return (
    <>
      <Card>
        <TabMenu
          currentMenu={activeTab}
          setCurrentMenu={setActiveTab}
          navigateUser={navigateUser}
          menus={menuItems}
        />
        <Card.Body className="overflow-auto">
          {activeTab === 'references' && (
            <Card className="border-0">
              <PaymentReferencesDetails
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                referenceTab={referenceTab}
                setReferenceTab={setReferenceTab}
                navigateUser={navigateUser}
              />
            </Card>
          )}
          {activeTab === 'generate-reference' && (
            <GeneratePaymentReference
              activeTab={activeTab}
              navigateUser={navigateUser}
            />
          )}
        </Card.Body>
      </Card>
      <ReferenceModal switchTab={() => setActiveTab('references')} />
    </>
  );
}

export default PaymentReferences;
