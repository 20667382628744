import { calendarActions } from '../../actions';
import initialState from '../../initialState';

function calendar(state = initialState.academicCalendar, actions) {
  switch (actions.type) {
    case calendarActions.GET_ACADEMIC_YEAR_EVENTS_REQUEST:
      return {
        ...state,
        gettingCalendarEvents: true,
        calendarEventsError: {},
      };
    case calendarActions.GET_ACADEMIC_YEAR_EVENTS_SUCCESS:
      return {
        ...state,
        gettingCalendarEvents: false,
        calendarYearEvents: actions.data,
      };
    case calendarActions.GET_ACADEMIC_YEAR_EVENTS_ERROR:
      return {
        ...state,
        gettingCalendarEvents: false,
        calendarEventsError: actions.error,
      };

    default:
      return state;
  }
}

export default calendar;
