import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { FaUserGraduate } from 'react-icons/fa';

function GuardianDetail({ authUser }) {
  return (
    <>
      <Card.Header className="text-primary font600 py-3 text-sm bioData-header">
        <FaUserGraduate className="me-1" />
        GUARDIAN DETAILS
      </Card.Header>
      <Card.Body>
        <Table
          striped
          size="md"
          borderless
          responsive
          cellPadding={4}
          className="border"
        >
          <tbody>
            <tr>
              <td width={200} className="text-uppercase">
                FULL NAME
              </td>
              <td>:{authUser.guardian_name}</td>
              <td width={200} className="text-uppercase">
                Relationship
              </td>
              <td>:{authUser.guardian_relationship}</td>
            </tr>
            <tr>
              <td className="text-uppercase">Email</td>
              <td>:{authUser.guardian_email}</td>
              <td className="text-uppercase">Address</td>
              <td>:{authUser.guardian_address}</td>
            </tr>
            <tr>
              <td className="text-uppercase">TELEPHONE PHONE</td>
              <td colSpan={3}>:{authUser.guardian_phone}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </>
  );
}

GuardianDetail.propTypes = {
  authUser: PropTypes.oneOfType([object]).isRequired,
};

export default GuardianDetail;
