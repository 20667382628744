import update from 'immutability-helper';
import { resultActions } from '../../actions';
import initialState from '../../initialState';

function result(state = initialState.result, actions) {
  switch (actions.type) {
    case resultActions.GET_MY_RESULT_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case resultActions.GET_MY_RESULT_SUCCESS: {
      const { studentProgrammeId, data } = actions;
      const { results } = state;

      const findContextIndex = results.findIndex(
        (currentContext) =>
          studentProgrammeId === currentContext.studentProgrammeId
      );

      const contextData = { studentProgrammeId, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          results: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          results: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        loading: false,
      };
    }

    case resultActions.GET_MY_RESULT_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    default:
      return state;
  }
}

export default result;
