import { Alert, Image } from 'antd';
import { isEmpty, sum, sumBy, toUpper } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import ARsignature from '../../assets/img/ARsignature.png';
import DefaultLogo from '../../assets/img/default.png';
import { formatName, whichInstitution } from '../../helpers/dataFormatter';
import Avatar from '../MyProfile/Avatar';

function EnrollmentCard({ record }) {
  const { institutionStructure } = useSelector((state) => state.app);
  const { currentStudentProgramme, authUser } = useSelector(
    (state) => state.auth
  );
  const isMubs = whichInstitution('MUBS', institutionStructure);
  const institutionLogo = `${process.env.REACT_APP_INSTITUTION_LOGO_PATH}/${institutionStructure?.institution_logo}`;

  return (
    <>
      <div
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: '50%',
          backgroundColor: '#fff',
          lineHeight: 1,
        }}
      >
        <div
          className="border border-3 border-danger p-3 text-sm"
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.835)',
          }}
        >
          <Row>
            <Col
              sm={2}
              lg={2}
              md={2}
              className="col-2 d-flex align-self-center text-center justify-content-center"
            >
              <Image
                src={institutionLogo}
                fallback={DefaultLogo}
                alt="Logo"
                preview={false}
                className="mx-auto bg-white"
                style={{ maxWidth: '100px' }}
              />
            </Col>
            <Col sm={8} lg={8} md={8} className="col-8 text-uppercase fw-bold">
              <h5 className="text-uppercase fs-4 fw-bold">
                {institutionStructure?.institution_name}
              </h5>
              <div className="fw-bold my-2 text-md">
                {`Office of the ${isMubs ? 'School' : 'Academic'} Registrar`}
              </div>
              <div className="mb-2">STUDENT PROOF OF ENROLLMENT</div>
              <div className="text-muted">
                <small>
                  PRINT DATE: {moment().format('dddd, MMMM Do YYYY, h:mm:ss a')}
                </small>
              </div>
            </Col>
            <Col
              sm={2}
              lg={2}
              md={2}
              className="col-2 d-flex align-self-center justify-content-end"
            >
              <QRCode
                size={80}
                value={`${process.env.REACT_APP_DOCUMENT_VERIFICATION_LINK}?sN=${currentStudentProgramme?.student_number}&cat=enroll_card`}
              />
            </Col>
          </Row>

          <hr className="mb-4 border border-danger border-4" />

          <div className="bg-danger p-2 text-md mb-4 text-center fw-bold text-uppercase text-white">
            PROOF OF ENROLLMENT
          </div>
          <Row className="row-deck g-1">
            <Col className="col-2">
              <Card
                className="p-0 bg-transparent d-flex"
                style={{ border: 'dashed 1px var(--bs-primary)' }}
              >
                <Avatar
                  src={`${process.env.REACT_APP_STUDENTS_PHOTO_URL}/${authUser?.avatar}`}
                  height={120}
                  width={120}
                  className="my-auto"
                />
              </Card>
            </Col>
            <Col className="col-10">
              <div
                className="w-100"
                style={{ border: 'dashed 1px var(--bs-primary)' }}
              >
                <Table
                  size="sm"
                  striped
                  borderless
                  className="m-0 table-striped"
                  style={{ fontSize: '9px !important' }}
                >
                  <tbody>
                    <tr>
                      <td width="130" className="fw-bold">
                        FULL NAME:
                      </td>
                      <td colSpan={3}>
                        {formatName(
                          `${authUser?.surname} ${authUser?.other_names}`
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold">SEX:</td>
                      <td colSpan={3}>{toUpper(authUser?.gender)}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">STUDENT NO:</td>
                      <td colSpan={3}>
                        {isMubs &&
                        !isEmpty(currentStudentProgramme?.old_student_number)
                          ? currentStudentProgramme?.old_student_number
                          : currentStudentProgramme?.student_number}
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold">REGISTRATION NO:</td>
                      <td colSpan={3}>{authUser?.registration_number}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">PROGRAMME:</td>
                      <td
                        colSpan={3}
                      >{`(${currentStudentProgramme?.programme_code}) ${currentStudentProgramme?.programme_title}`}</td>
                    </tr>
                    <tr className="my-1">
                      <td className="fw-bold">CAMPUS:</td>
                      <td>{currentStudentProgramme?.campus || ''}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col className="col-12">
              <Alert
                type="info"
                showIcon
                className="mt-2 w-100"
                message={
                  <div>
                    <span className="me-3">ENROLLMENT:</span>
                    <span className="me-2 fw-bold">
                      {record?.enrollment?.studyYear?.programme_study_years}
                    </span>
                    <span className="me-2 fw-bold">
                      {record?.event?.semester} -
                    </span>
                    <span className="fw-bold">
                      {record?.event?.academic_year}
                    </span>
                  </div>
                }
              />
            </Col>
            <Col className="col-12">
              <div
                className="w-100 mt-3"
                style={{ border: 'dashed 1px var(--bs-primary)' }}
              >
                <Table
                  size="sm"
                  striped
                  borderless
                  className="m-0 table-striped"
                  style={{ fontSize: '9px !important' }}
                >
                  <tbody>
                    <tr>
                      <td className="text-uppercase fw-bold">
                        Enrollment Date:
                      </td>
                      <td>
                        {moment(record?.enrollment?.created_at).format('LLL')}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-uppercase fw-bold">
                        Enrollment Token:
                      </td>
                      <td className="text-danger">{` ${record?.enrollment?.enrollment_token}`}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">ENROLLED AS:</td>
                      <td>{`${record?.enrollment?.enrollmentStatus?.metadata_value}`}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">ENROLLED BY:</td>
                      <td>{record?.enrollment?.enrolled_by}</td>
                    </tr>
                    {record?.enrollment?.enrolled_by === 'STAFF' && (
                      <tr>
                        <td className="fw-bold">STAFF NAME:</td>
                        <td>{formatName(record?.enrollment?.createdBy)}</td>
                      </tr>
                    )}
                    <tr>
                      <td className="fw-bold">TOTAL BILL:</td>
                      <td className="text-primary">
                        {sum([
                          sumBy(
                            record?.enrollment?.otherFeesInvoice,
                            'invoice_amount'
                          ),
                          sumBy(
                            record?.enrollment?.tuitionInvoice,
                            'invoice_amount'
                          ),
                          record?.enrollment?.functionalInvoice?.invoice_amount,
                        ]).toLocaleString()}
                        .UGX
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold">AMOUNT PAID:</td>
                      <td className="text-success">
                        {sum([
                          sumBy(
                            record?.enrollment?.otherFeesInvoice,
                            'amount_paid'
                          ),
                          sumBy(
                            record?.enrollment?.tuitionInvoice,
                            'amount_paid'
                          ),
                          record?.enrollment?.functionalInvoice?.amount_paid,
                        ]).toLocaleString()}
                        .UGX
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold">AMOUNT DUE:</td>
                      <td className="text-danger">
                        {sum([
                          sumBy(
                            record?.enrollment?.otherFeesInvoice,
                            'amount_due'
                          ),
                          sumBy(
                            record?.enrollment?.tuitionInvoice,
                            'amount_due'
                          ),
                          record?.enrollment?.functionalInvoice?.amount_due,
                        ]).toLocaleString()}
                        .UGX
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-md-9 mb-0 pt-3">
              <div>Holder&apos;s Signature</div>
              <div className="d-block mt-4">______________________</div>
            </Col>
            <Col className=" col-3 text-center">
              <Image
                src={ARsignature}
                alt="Signature"
                preview={false}
                draggable={false}
                style={{ maxHeight: '80px' }}
              />
              <div>
                {isMubs && <span>Eldred Kyomuhangi Manyindo</span>}
                <div className="fw-bold text-sm mt-1">{`${
                  isMubs ? 'SCHOOL' : 'ACADEMIC'
                } REGISTRAR`}</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

EnrollmentCard.propTypes = {
  record: PropTypes.oneOfType([object]).isRequired,
};

export default EnrollmentCard;
