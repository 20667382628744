import { LockOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { AntDInputText, SubmitButton } from '../../components/common';

function LoginForm({ onSubmit }) {
  const { loginIn } = useSelector((state) => state.auth);

  return (
    <Card className="border shadow-sm p-0">
      <div className="fw-bold text-center text-dark text-lg mb-4">
        LOGIN TO YOUR ACCOUNT
      </div>
      <Form onFinish={onSubmit} layout="vertical" className="mt-3">
        <AntDInputText
          name="username"
          inputAttributes={{
            prefix: <UserOutlined />,
            placeholder: 'Student Number or Reg. Number',
          }}
          itemAttributes={{ className: 'mb-4' }}
          rules={[
            {
              required: true,
              message: 'Your Student Number or Reg. Number field is required',
            },
            {
              min: 7,
              message: 'Student/Reg. No is too short',
            },
            { max: 15, message: 'Student/Reg. No  is too long' },
          ]}
        />
        <AntDInputText
          type="password"
          name="password"
          itemAttributes={{ className: 'mb-4' }}
          inputAttributes={{
            prefix: <LockOutlined />,
            placeholder: 'Password',
          }}
          rules={[
            {
              required: true,
              message: 'Your Password is required',
            },
            { min: 6, message: 'Password is too short' },
            { value: 16, message: 'Password is too long' },
          ]}
        />

        <SubmitButton
          text="SIGN IN"
          loading={loginIn}
          iconBefore={<LoginOutlined className="me-1" />}
          loadingText="Signing in..."
          block
        />
      </Form>
    </Card>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
