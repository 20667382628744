import { isEmpty, orderBy } from 'lodash';
import PropTypes, { array } from 'prop-types';
import React from 'react';
import DataTable from 'react-data-table-component';
import { DataNotFound } from '../../components/common';
import darkHeader from '../../helpers/dataTableCustomStyle';

function CourseUnitsTable({
  courseUnits,
  customColumns,
  columnsBefore,
  columnsAfter,
  ...props
}) {
  const tableColumns = [
    ...columnsBefore,
    {
      name: 'CODE',
      selector: (row) => row.course_unit_code,
      width: '100px',
    },
    {
      name: 'COURSE TITLE',
      selector: (row) => row.course_unit_name,
      wrap: true,
      width: '300px',
    },
    {
      name: 'CATEGORY',
      selector: (row) => row.category,
    },
    {
      name: 'C. UNITS',
      selector: (row) => row.credit_units || row.credit_unit,
      width: '100px',
      center: true,
      right: true,
    },
    ...columnsAfter,
  ];

  return (
    <DataTable
      data={orderBy(courseUnits, ['study_year'], 'asc')}
      columns={!isEmpty(customColumns) ? customColumns : tableColumns}
      dense
      striped
      pointerOnHover
      noHeader
      highlightOnHover
      fixedHeader
      fixedHeaderScrollHeight="300px"
      noDataComponent={<DataNotFound message="NO COURSES/MODULES AVAILABLE" />}
      customStyles={darkHeader}
      {...props}
    />
  );
}

CourseUnitsTable.defaultProps = {
  columnsBefore: [],
  columnsAfter: [],
  customColumns: [],
  courseUnits: [],
};

CourseUnitsTable.propTypes = {
  columnsAfter: PropTypes.oneOfType([array]),
  columnsBefore: PropTypes.oneOfType([array]),
  customColumns: PropTypes.oneOfType([array]),
  courseUnits: PropTypes.oneOfType([array]),
};

export default CourseUnitsTable;
