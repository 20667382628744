import { Tag } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../config/actions';

function ProgrammeTab() {
  const dispatch = useDispatch();
  const { authUser, currentStudentProgramme } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    dispatch(authActions.getStudentProgrammes());
  }, []);

  const setCurrentStudentProgramme = (programme) => {
    dispatch(authActions.setCurrentStudentProgramme(programme, 'programme_id'));
  };

  useEffect(() => {
    if (authUser && !isEmpty(authUser.academic_records)) {
      const findActiveProgramme = authUser.academic_records.find(
        (programme) => programme.is_current_programme === true
      );

      const [firstProgramme] = authUser.academic_records;

      setCurrentStudentProgramme(findActiveProgramme || firstProgramme);
    }
  }, [authUser]);

  return (
    <Card.Header className="py-2 px-3 border-0 fw-normal bg-light">
      <div
        color={currentStudentProgramme.is_current_programme ? 'green' : 'red'}
      >
        <span className="text-primary me-1">PROGRAMME:</span>
        {`${currentStudentProgramme.programme_title} - (${currentStudentProgramme.programme_code})`}
        <span
          className={`badge bg-dark rounded-2 ms-2 fs-6 ${
            currentStudentProgramme.is_current_programme
              ? 'bg-success'
              : 'bg-warning text-dark'
          }`}
        >
          {currentStudentProgramme.is_current_programme ? 'ACTIVE' : 'INACTIVE'}
        </span>
      </div>
      <div className="card-options">
        <span className="text-primary">
          ACADEMIC STATUS:
          <Tag color="volcano" className="ms-2">
            {currentStudentProgramme?.student_academic_status || ''}
          </Tag>
        </span>
      </div>
    </Card.Header>
  );
}

export default ProgrammeTab;
