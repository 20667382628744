import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import {
  authActions,
  paymentActions,
  paymentReferenceActions,
} from '../../actions';

function* getMyInvoices(actions) {
  try {
    const response = yield axios({
      url: '/student-portal/invoices',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: paymentActions.GET_MY_INVOICES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: authActions.GET_AUTH_USER_ACCOUNT_BALANCE_REQUEST,
    });
  } catch (error) {
    yield put({
      type: paymentActions.GET_MY_INVOICES_ERROR,
      error: error.data,
    });
  }
}

function* getMyGraduationInvoices(actions) {
  try {
    const { studentProgrammeId } = actions;
    const response = yield axios({
      url: `/student-portal/invoices/graduation/${studentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: paymentActions.GET_MY_GRADUATION_INVOICES_SUCCESS,
      data: response.element,
      studentProgrammeId,
    });
    yield put({
      type: authActions.GET_AUTH_USER_ACCOUNT_BALANCE_REQUEST,
    });
  } catch (error) {
    yield put({
      type: paymentActions.GET_MY_GRADUATION_INVOICES_ERROR,
      error: error.data,
    });
  }
}

function* getMyTransactions(actions) {
  try {
    const response = yield axios({
      url: '/student-portal/payment-transactions/history',
      method: 'GET',
      params: actions.data,
    });
    delete response.server;
    yield put({
      type: paymentActions.GET_MY_TRANSACTIONS_SUCCESS,
      data: response,
    });
    yield put({
      type: authActions.GET_AUTH_USER_ACCOUNT_BALANCE_REQUEST,
    });
  } catch (error) {
    yield put({
      type: paymentActions.GET_MY_TRANSACTIONS_ERROR,
      error: error.data,
    });
  }
}

function* getPreviousTransactions(actions) {
  try {
    const response = yield axios({
      url: `student-portal/previous-transactions/${actions.category}/${actions.studentProgrammeId}`,
      method: 'GET',
      params: actions.data,
    });

    yield put({
      type: paymentActions.GET_PREVIOUS_TRANSACTIONS_SUCCESS,
      data: response.data,
      category: actions.category,
    });
  } catch (error) {
    yield put({
      type: paymentActions.GET_PREVIOUS_TRANSACTIONS_ERROR,
      error: error.data,
    });
  }
}

function* searchPayment(actions) {
  try {
    const response = yield axios({
      url: `/student-portal/payment-references/search-prn/${actions.prn}`,
      method: 'GET',
    });
    yield put({
      type: paymentActions.SEARCH_PAYMENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: authActions.GET_AUTH_USER_ACCOUNT_BALANCE_REQUEST,
    });
    yield put({
      type: paymentReferenceActions.GETTING_PAYMENT_REFERENCES_REQUEST,
    });
  } catch (error) {
    yield put({
      type: paymentActions.SEARCH_PAYMENT_ERROR,
      error: error.data,
    });
  }
}

function* offsetStudentInvoice(actions) {
  try {
    const { data, invoiceId } = actions;
    const response = yield axios({
      url: `/student-portal/invoices/allocate-to-one-invoice/${invoiceId}`,
      method: 'POST',
      data,
    });
    yield put({
      type: paymentActions.OFFSET_STUDENT_INVOICE_SUCCESS,
      data: response,
    });
    yield put({
      type: paymentActions.GET_MY_INVOICES_REQUEST,
    });
    yield put({
      type: 'SHOW_OFFSET_INVOICE_MODAL',
      show: false,
      invoiceData: {},
    });
  } catch (error) {
    yield put({
      type: paymentActions.OFFSET_STUDENT_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* allocateTransactions(actions) {
  try {
    const { data, transactionId } = actions;
    const response = yield axios({
      url: `/student-portal/invoices/allocate-money-to-invoice/${transactionId}`,
      method: 'POST',
      data,
    });
    yield put({
      type: paymentActions.ALLOCATE_TRANSACTION_SUCCESS,
      data: response.data,
    });
    yield put({
      type: authActions.GET_AUTH_USER_ACCOUNT_BALANCE_REQUEST,
    });
    yield put({
      type: paymentActions.GET_MY_TRANSACTIONS_REQUEST,
    });
    yield put({
      type: paymentActions.GET_MY_INVOICES_REQUEST,
    });
    yield put({
      type: 'SET_SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: paymentActions.ALLOCATE_TRANSACTION_ERROR,
      error: error.data,
    });
  }
}

function* studentLedger(actions) {
  try {
    const { studentProgrammeId } = actions;
    const response = yield axios({
      url: `/student-portal/payment-transactions/financial-statement/${studentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: paymentActions.GET_MY_LEDGER_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: paymentActions.GET_MY_LEDGER_ERROR,
      error: error.data,
    });
  }
}

function* getFeesStructure(actions) {
  try {
    const { studentProgrammeId } = actions;

    const response = yield axios({
      url: `/student-portal/payment-transactions/fees-structure/${studentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: paymentActions.GET_FEES_STRUCTURE_SUCCESS,
      data: response.feesStructure,
      studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: paymentActions.GET_FEES_STRUCTURE_ERROR,
      error: error.data,
    });
  }
}

function* offsetInvoice(actions) {
  try {
    const response = yield axios({
      url: `/student-portal/invoices/allocate-to-one-invoice/${actions.invoiceId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: paymentActions.OFFSET_INVOICE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SET_SHOW_ALLOCATE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: paymentActions.OFFSET_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* generateGraduationInvoice(actions) {
  try {
    const { studentProgrammeId } = actions;
    const response = yield axios({
      url: `/student-portal/invoices/generate-grad-invoice/${studentProgrammeId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: paymentActions.GENERATE_GRADUATION_INVOICE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: paymentActions.GET_MY_GRADUATION_INVOICES_REQUEST,
      studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: paymentActions.GENERATE_GRADUATION_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* getFinanceClearance(actions) {
  try {
    const { studentProgrammeId } = actions;
    const response = yield axios({
      url: `/student-portal/payment-transactions/finance-clearance/${studentProgrammeId}`,
      method: 'GET',
      data: actions.data,
    });

    yield put({
      type: paymentActions.GET_MY_FINANCE_CLEARANCE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: paymentActions.GET_MY_FINANCE_CLEARANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMyInvoices() {
  yield takeLatest(paymentActions.GET_MY_INVOICES_REQUEST, getMyInvoices);
}

function* watchGetMyGraduationInvoices() {
  yield takeLatest(
    paymentActions.GET_MY_GRADUATION_INVOICES_REQUEST,
    getMyGraduationInvoices
  );
}

function* watchGetMyTransactions() {
  yield takeLatest(
    paymentActions.GET_MY_TRANSACTIONS_REQUEST,
    getMyTransactions
  );
}

function* watchOffsetStudentInvoice() {
  yield takeLatest(
    paymentActions.OFFSET_STUDENT_INVOICE_REQUEST,
    offsetStudentInvoice
  );
}

function* watchGetPreviousTransactions() {
  yield takeLatest(
    paymentActions.GET_PREVIOUS_TRANSACTIONS_REQUEST,
    getPreviousTransactions
  );
}

function* watchGetSearchReference() {
  yield takeLatest(paymentActions.SEARCH_PAYMENT_REQUEST, searchPayment);
}

function* watchAllocateTransaction() {
  yield takeLatest(
    paymentActions.ALLOCATE_TRANSACTION_REQUEST,
    allocateTransactions
  );
}

function* watchStudentLedger() {
  yield takeLatest(paymentActions.GET_MY_LEDGER_REQUEST, studentLedger);
}

function* watchGetFeesStructure() {
  yield takeLatest(paymentActions.GET_FEES_STRUCTURE_REQUEST, getFeesStructure);
}

function* watchOffsetInvoice() {
  yield takeLatest(paymentActions.OFFSET_INVOICE_REQUEST, offsetInvoice);
}
function* watchGenerateGraduationInvoice() {
  yield takeLatest(
    paymentActions.GENERATE_GRADUATION_INVOICE_REQUEST,
    generateGraduationInvoice
  );
}

function* watchGetFinanceClearance() {
  yield takeLatest(
    paymentActions.GET_MY_FINANCE_CLEARANCE_REQUEST,
    getFinanceClearance
  );
}

const forkFunctions = [
  fork(watchGetMyInvoices),
  fork(watchGetMyTransactions),
  fork(watchGetPreviousTransactions),
  fork(watchOffsetStudentInvoice),
  fork(watchGetSearchReference),
  fork(watchAllocateTransaction),
  fork(watchStudentLedger),
  fork(watchGetFeesStructure),
  fork(watchGetMyGraduationInvoices),
  fork(watchOffsetInvoice),
  fork(watchGenerateGraduationInvoice),
  fork(watchGetFinanceClearance),
];

export default forkFunctions;
