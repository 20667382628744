import { SwapOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import {
  filter,
  find,
  flatten,
  isArray,
  isEmpty,
  join,
  map,
  orderBy,
} from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaExternalLinkAlt, FaReply } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  AntDInputText,
  DataNotFound,
  SubmitButton,
} from '../../../components/common';
import { appActions } from '../../../config/actions';
import {
  formatMetadata,
  formatProgrammes,
  generalFormatter,
} from '../../../helpers/dataFormatter';
import PreviewChange from './PreviewChange';

function ApplicationForm({
  currentEvent,
  currentTab,
  dataToEdit,
  setCurrentTab,
  navigateUser,
}) {
  const { metadata } = useSelector((state) => state.app);
  const { submittingApplication, programmes } = useSelector(
    (state) => state.service
  );
  const dispatch = useDispatch();

  const [programmeOptions, setProgrammeOptions] = useState([]);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [studyYearOptions, setStudyYearOptions] = useState([]);
  const [studyTypeOptions, setStudyTypeOptions] = useState([]);
  const [campusOptions, setCampusOptions] = useState([]);
  const [subjectCombOptions, setSubjectCombOptions] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const [form] = Form.useForm();
  const watchNewProgramme = Form.useWatch('new_programme_id', form);

  useEffect(() => {
    if (isEmpty(metadata)) {
      dispatch(appActions.getMetadata());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(dataToEdit)) {
      const formData = {
        new_programme_id: {
          label: `${dataToEdit?.newProgramme?.programme_title} (${dataToEdit?.newProgramme?.programme_code})`,
          value: dataToEdit?.newProgramme?.id,
        },
        reason: dataToEdit?.reason,
        new_campus_id: find(
          campusOptions,
          (t) => toString(t.value) === toString(dataToEdit?.new_campus_id)
        ),
        new_programme_type_id: find(
          studyTypeOptions,
          (t) =>
            toString(t.value) === toString(dataToEdit?.new_programme_type_id)
        ),
        new_study_year_id: find(
          studyYearOptions,
          (t) => toString(t.value) === toString(dataToEdit?.new_study_year_id)
        ),
        academic_year_id: find(
          academicYearOptions,
          (t) => toString(t.value) === toString(dataToEdit?.academic_year_id)
        ),
        new_subject_comb_id: find(
          subjectCombOptions,
          (t) => toString(t.value) === toString(dataToEdit?.new_subject_comb_id)
        ),
      };

      form.setFieldsValue(formData);
      setSelectedData(formData);
    }
  }, [dataToEdit, form]);

  useEffect(() => {
    if (isArray(programmes)) {
      setProgrammeOptions(formatProgrammes(programmes));
    }

    setAcademicYearOptions(
      orderBy(
        filter(
          formatMetadata(metadata, 'ACADEMIC YEARS', 'id', 'desc'),
          (e) => e.label === currentEvent.academic_year
        ),
        ['label'],
        'desc'
      )
    );
  }, [currentEvent, metadata, programmes]);

  useEffect(() => {
    form.resetFields([
      'new_campus_id',
      'new_study_year_id',
      'new_programme_type_id',
      'new_subject_comb_id',
      'academic_year_id',
    ]);
  }, [watchNewProgramme]);

  useEffect(() => {
    let programmeStudyYears = [];

    let subjectCombinations = [];

    let programmeTypes = [];

    let campuses = [];

    const findProgramme = find(
      programmes,
      (programme) => programme.id === watchNewProgramme?.value
    );

    if (findProgramme && findProgramme.programme_study_years) {
      programmeStudyYears = generalFormatter(
        findProgramme.programme_study_years,
        'programme_study_year',
        'context_id'
      );
    }

    if (findProgramme && !isEmpty(findProgramme.programme_types)) {
      programmeTypes = generalFormatter(
        findProgramme.programme_types,
        'programme_type',
        'programme_type_id'
      );
    }

    if (
      findProgramme &&
      !isEmpty(findProgramme.subject_combinations_categories)
    ) {
      const combinationsSubjects = orderBy(
        flatten(
          map(
            findProgramme.subject_combinations_categories,
            'subject_combinations'
          )
        ),
        ['subject_combination_code']
      );
      subjectCombinations = map(combinationsSubjects, (e) => ({
        value: e.subject_combination_id,
        label: `${e.subject_combination_code} - ${join(
          map(e.subjects, 'subject_code'),
          '/'
        )}`,
      }));
    }

    if (findProgramme && !isEmpty(findProgramme.campuses)) {
      campuses = generalFormatter(
        findProgramme.campuses,
        'campus',
        'campus_id'
      );
    }

    setCampusOptions(campuses);
    setStudyTypeOptions(programmeTypes);
    setSubjectCombOptions(subjectCombinations);
    setStudyYearOptions(programmeStudyYears);
  }, [watchNewProgramme]);

  const submitFormHandler = async () => {
    await form.validateFields().then(() => {
      setShowPreview(true);
    });
  };

  const handleClick = () => {
    setCurrentTab('service-dashboard');
    navigateUser();
  };

  return (
    <>
      {isEmpty(currentEvent) ? (
        <DataNotFound message="CHANGE OF PROGRAMME HAS NOT BEEN OPENED FOR YOUR PROGRAMME, PLEASE CONTACT YOUR ACADEMIC REGISTRAR FOR HELP">
          <div className="text-center my-3">
            <Button
              size="sm"
              onClick={handleClick}
              className="text-sm font600 text-uppercase ms-1"
              variant="warning"
            >
              <FaReply className="me-1" />
              Back
            </Button>
          </div>
        </DataNotFound>
      ) : (
        <Form
          layout="vertical"
          onFinish={submitFormHandler}
          form={form}
          size="small"
          onValuesChange={(_, data) => setSelectedData(data)}
        >
          {showPreview && !isEmpty(selectedData) ? (
            <PreviewChange
              data={selectedData}
              setShowPreview={setShowPreview}
              currentTab={currentTab}
              currentEvent={currentEvent}
              dataToEdit={dataToEdit}
              isEditing={!isEmpty(dataToEdit)}
            />
          ) : (
            <>
              <AntDInputText
                label="NEW PROGRAMME"
                rules={[{ required: true, message: 'This Field is required' }]}
                inputAttributes={{
                  placeholder: 'Select programme...',
                  labelInValue: true,
                  prefix: <SwapOutlined />,
                }}
                options={programmeOptions}
                type="select"
                name="new_programme_id"
              />
              {!isEmpty(subjectCombOptions) && (
                <AntDInputText
                  name="new_subject_comb_id"
                  label="NEW SUBJECT COMBINATION:"
                  type="select"
                  inputAttributes={{
                    prefix: <SwapOutlined />,
                    labelInValue: true,
                  }}
                  options={subjectCombOptions}
                  rules={[
                    {
                      required: true,
                      message: 'Select Subject Combination',
                    },
                  ]}
                />
              )}
              <Row>
                <Col md={6}>
                  <AntDInputText
                    name="new_study_year_id"
                    label="ENTRY STUDY YEAR:"
                    rules={[
                      { required: true, message: 'This Field is required' },
                    ]}
                    type="select"
                    inputAttributes={{ labelInValue: true }}
                    options={studyYearOptions}
                  />
                </Col>
                <Col md={6}>
                  <AntDInputText
                    name="new_programme_type_id"
                    label="NEW STUDY TIME:"
                    rules={[
                      { required: true, message: 'This Field is required' },
                    ]}
                    type="select"
                    inputAttributes={{ labelInValue: true }}
                    options={studyTypeOptions}
                  />
                </Col>
                <Col md={6}>
                  <AntDInputText
                    name="new_campus_id"
                    label="NEW CAMPUS:"
                    type="select"
                    inputAttributes={{ labelInValue: true }}
                    rules={[
                      { required: true, message: 'This Field is required' },
                    ]}
                    options={campusOptions}
                  />
                </Col>
                <Col md={6}>
                  <AntDInputText
                    name="academic_year_id"
                    label="ENTRY ACADEMIC YEAR:"
                    type="select"
                    options={academicYearOptions}
                    inputAttributes={{ labelInValue: true }}
                    rules={[
                      {
                        required: true,
                        message: 'Select Entry Academic Year',
                      },
                    ]}
                  />
                </Col>
              </Row>
              <AntDInputText
                name="reason"
                label="REASON FOR CHANGE:"
                type="textarea"
                rules={[
                  {
                    required: true,
                    message: 'Enter Reason for Change of Programme',
                  },
                ]}
                inputAttributes={{ rows: 3 }}
              />
              <SubmitButton
                size="small"
                htmlType="submit"
                text="Preview and Submit"
                iconBefore={<FaExternalLinkAlt className="mx-1" />}
                loadingText="Submitting Application..."
                className="float-end text-uppercase"
                loading={submittingApplication}
              />
            </>
          )}
        </Form>
      )}
    </>
  );
}

ApplicationForm.defaultProps = {
  currentEvent: {},
  dataToEdit: {},
};

ApplicationForm.propTypes = {
  currentEvent: PropTypes.oneOfType([object]),
  dataToEdit: PropTypes.oneOfType([object]),
  setCurrentTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  navigateUser: PropTypes.func.isRequired,
};

export default ApplicationForm;
