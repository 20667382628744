import { Result } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaSearchDollar } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessage, InputText, SubmitButton } from '../../components/common';
import { paymentActions } from '../../config/actions';
import usePrevious from '../Hooks/usePrevious';

function SearchPayment() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const { searchingPayment, searchPayment, searchPaymentError } = useSelector(
    (state) => state.myTransaction
  );
  const [searchSuccess, setSearchSuccess] = useState({});
  const [searchError, setSearchError] = useState(null);
  const prevState = usePrevious({ searchPayment, searchPaymentError });

  useEffect(() => {
    if (!isEmpty(prevState)) {
      if (
        !isEmpty(searchPayment) &&
        searchPayment !== prevState.searchPayment
      ) {
        setSearchSuccess(searchPayment);
      }
      if (
        !isEmpty(searchPaymentError) &&
        searchPaymentError !== prevState.searchPaymentError
      ) {
        setSearchError(
          searchPaymentError?.error?.message ||
            searchPaymentError?.server?.message
        );
      }
    }
  }, [searchPayment, searchPaymentError]);

  const onSearchPayment = (data) => {
    setSearchError(null);
    setSearchSuccess({});
    if (data?.searchKey) {
      dispatch(paymentActions.searchPayment(data.searchKey));
    }
  };

  return (
    <Card>
      <Card.Header className="py-3 font600 text-sm text-uppercase text-muted">
        CHECK PAYMENT REFERENCE NUMBER STATUS
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSearchPayment)}>
          <Row className="g-0">
            <Col md={{ offset: 3, span: 4 }}>
              <InputText
                name="searchKey"
                type="search"
                placeholder="Enter Payment Reference Number"
                register={register({
                  required: 'Enter Payment Reference',
                  minLength: {
                    value: 13,
                    message: 'Invalid Reference Number',
                  },
                  maxLength: {
                    value: 13,
                    message: 'Invalid Reference Number',
                  },
                })}
                error={get(errors, 'searchKey.message')}
              />
            </Col>
            <Col md={2}>
              <SubmitButton
                className="text-uppercase text-sm font600 text-white w-100"
                text="Search"
                loadingText="Searching..."
                loading={searchingPayment}
                iconBefore={<FaSearchDollar className="me-2" />}
              />
            </Col>
          </Row>

          {searchError && <Result status="error" title={searchError} />}

          {!isEmpty(searchSuccess) && (
            <Card body className="shadow-none rounded my-4">
              <AlertMessage
                type={searchSuccess.code === 'T' ? 'success' : 'info'}
                message={`Status: ${searchSuccess?.description}`}
                className="font600 text-sm"
              />

              <Table
                size="md"
                bordered
                striped
                responsive
                className="text-sm text-muted font600"
              >
                <tbody>
                  <tr>
                    <td>PRN</td>
                    <td>{searchSuccess.PRN}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>
                      {`${parseInt(
                        searchSuccess.AmountPaid,
                        10
                      ).toLocaleString()} ${searchSuccess?.Currency}`}
                    </td>
                  </tr>
                  <tr>
                    <td>PAYMENT STATUS</td>
                    <td>{searchSuccess.StatusDesc}</td>
                  </tr>
                  <tr>
                    <td>Generated By</td>
                    <td>{searchSuccess.TaxPayerName}</td>
                  </tr>
                  <tr>
                    <td>Expiry Date</td>
                    <td>{searchSuccess.PaymentExpiryDate}</td>
                  </tr>
                  <tr>
                    <td>Payment Date</td>
                    <td>{searchSuccess.DatePaid || '-- -- ----'}</td>
                  </tr>
                  <tr>
                    <td>Payment Bank</td>
                    <td>{searchSuccess.PaymentBank || '---------'}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
}

export default SearchPayment;
