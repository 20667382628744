import React, { useEffect, useState } from 'react';
import { pick, isArray, isEmpty, toLower } from 'lodash';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadButton, TabMenu } from '../../../components/common';
import {
  paymentReferenceActions,
  serviceActions,
  settingActions,
} from '../../../config/actions';
import ChangeOfProgrammeHistory from './ChangeOfProgrammeHistory';
import PaymentReferencesDetails from '../../PaymentReferences/PaymentReferencesDetails';

function ServiceHistory() {
  const dispatch = useDispatch();
  const [currentMenu, setCurrentMenu] = useState('change-of-programme');
  const [activeKey, setActiveKey] = useState(1);
  const [paymentReferenceData, setPaymentReferenceData] = useState([]);
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);
  const { paymentReferences } = useSelector((state) => state.paymentReference);
  const { gettingServiceHistory, serviceHistories } = useSelector(
    (state) => state.service
  );
  const [changeOfProgrammes, setChangeOfProgrammes] = useState([]);

  const reloadPaymentReferences = () => {
    dispatch(paymentReferenceActions.getPaymentReferences());
  };

  useEffect(() => {
    if (isArray(serviceHistories)) {
      const paymentReference = [];
      const getCOP = serviceHistories.find(
        (service) => toLower(service?.category) === 'change-of-programme'
      );
      const data = getCOP?.data;

      if (!isEmpty(data)) {
        paymentReference.push({
          ...pick(data[0], [
            'id',
            'ura_prn',
            'amount',
            'expiry_date',
            'generated_by',
          ]),
        });
      }
      setChangeOfProgrammes(data || []);
      setPaymentReferenceData(paymentReference);
    } else setChangeOfProgrammes([]);
  }, [serviceHistories]);

  useEffect(() => {
    if (isEmpty(paymentReferences)) reloadPaymentReferences();
  }, []);

  const onClickReload = () => {
    dispatch(serviceActions.getServiceHistory(currentMenu));
  };

  const navigateUser = (route) => {
    dispatch(
      settingActions.setNavigation({
        ...appNavigation,
        menuLevel_1: 'services',
        menuLevel_2: selectedMenu?.label,
        menuLevel_3: route,
      })
    );
  };

  useEffect(() => {
    const { key } = selectedMenu;
    const activeServiceTab = currentMenu.split('-').join(' ');
    if (key === 'service-history') {
      navigateUser(activeServiceTab);
    }
  }, []);

  return (
    <Card>
      <TabMenu
        menus={[
          {
            title: 'CHANGE OF PROGRAMME',
            action: 'change-of-programme',
          },
          {
            title: 'PAYMENT REFERENCE',
            action: 'payment-reference',
            hidden: isEmpty(changeOfProgrammes),
          },
        ]}
        setCurrentMenu={setCurrentMenu}
        currentMenu={currentMenu}
        navigateUser={navigateUser}
      >
        <div className="card-options">
          {currentMenu === 'change-of-programme' && (
            <ReloadButton
              onClick={onClickReload}
              loading={gettingServiceHistory}
            />
          )}
        </div>
      </TabMenu>
      <Card.Body>
        {currentMenu === 'change-of-programme' && (
          <ChangeOfProgrammeHistory changeOfProgrammes={changeOfProgrammes} />
        )}
        {currentMenu === 'payment-reference' && (
          <PaymentReferencesDetails
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            paymentData={paymentReferenceData}
            referenceTab="inactive"
            usage="COP"
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default ServiceHistory;
