import { Alert, Modal } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessage } from '../../../components/common';
import { settingActions } from '../../../config/actions';

function GraduationReferenceModal() {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [generatedReference, setGeneratedReference] = useState({});
  const { graduationPaymentReference, generateGradPRNError } = useSelector(
    (state) => state.paymentReference
  );
  const showModal = useSelector((state) => state.setting.showModal);

  useEffect(() => {
    if (!isEmpty(graduationPaymentReference)) {
      setGeneratedReference(graduationPaymentReference);
    }
    if (!isEmpty(generateGradPRNError)) {
      setErrorMessage(
        generateGradPRNError?.error?.message ||
          generateGradPRNError?.server?.message
      );
    }
  }, [graduationPaymentReference, generateGradPRNError]);

  return (
    <>
      {errorMessage && <AlertMessage message={errorMessage} />}
      <Modal
        centered
        closable
        keyboard={false}
        maskClosable={false}
        open={showModal}
        title="PAYMENT REFERENCE GENERATED SUCCESSFULLY"
        onOk={() => dispatch(settingActions.setShowModal(false))}
        onCancel={() => dispatch(settingActions.setShowModal(false))}
      >
        <div className="text-md text-center font600er">
          <FaCheckCircle className="text-success me-1" />
          PAYMENT REFERENCE NO.:
          <span className="text-sm font600 text-success mx-1">
            {generatedReference.ura_prn}
          </span>
        </div>

        <div className="text-sm text-center font600 my-2">
          TOTAL AMOUNT TO PAY:
          <span className="text-sm font600 text-success mx-1">
            {`${parseInt(generatedReference.amount, 10).toLocaleString()} UGX`}
          </span>
        </div>

        <div className="text-xs text-center font600er my-3">
          DATE OF EXPIRY:
          <span className="text-xs font600 text-danger mx-2">
            {generatedReference.expiry_date}
          </span>
        </div>

        <Alert
          message="Copy this Payment Reference Number and go to your Nearest Bank to make your payments."
          type="warning"
          showIcon
        />
      </Modal>
    </>
  );
}

export default GraduationReferenceModal;
