import { isEmpty } from 'lodash';
import PropTypes, { array } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DataSpinner } from '../../components/common';
import {
  appActions,
  authActions,
  registrationActions,
} from '../../config/actions';
import { formatMetadata } from '../../helpers/dataFormatter';
import RegistrationForm from '../RegistrationHistory/RegistrationForm';

function RetakeEnrollment({
  setSelectedRows,
  selectedRows,
  enrollWithRetakes,
  setShowRetakesModal,
}) {
  const dispatch = useDispatch();
  const { authUser, currentStudentProgramme } = useSelector(
    (state) => state.auth
  );
  const { metadata } = useSelector((state) => state.app);
  const { courseUnitsByStudyYear, gettingCourseUnits, registeringStudent } =
    useSelector((state) => state.registration);
  const [registrationStatusOptions, setRegistrationStatusOptions] = useState(
    []
  );
  const [selectedCourseUnits, setSelectedCourseUnits] = useState(selectedRows);

  useEffect(() => {
    if (isEmpty(metadata)) dispatch(appActions.getMetadata());
    if (isEmpty(authUser)) dispatch(authActions.getAuthUser());
    if (isEmpty(courseUnitsByStudyYear))
      dispatch(registrationActions.getCourseUnits(currentStudentProgramme?.id));
  }, []);

  useEffect(() => {
    if (!isEmpty(metadata)) {
      const retakePaperStatus = formatMetadata(
        metadata,
        'REGISTRATION STATUSES'
      ).filter((status) => status.label === 'RETAKE PAPER');
      const missingPaperStatus = formatMetadata(
        metadata,
        'REGISTRATION STATUSES'
      ).filter((status) => status.label === 'MISSING PAPER');
      setRegistrationStatusOptions([
        ...retakePaperStatus,
        ...missingPaperStatus,
      ]);
    }
  }, [metadata]);

  const onClickAddCoursesButton = () => {
    if (!isEmpty(selectedCourseUnits)) {
      setSelectedRows(selectedCourseUnits);
    }
    setShowRetakesModal(false);
  };

  return (
    <div>
      <Card body className="border-0 p-0">
        <Card.Header className="rounded-0 font600 text-danger text-xs mb-3 border">
          SELECT COURSES TO RETAKE
        </Card.Header>
        {gettingCourseUnits && isEmpty(courseUnitsByStudyYear) ? (
          <DataSpinner />
        ) : (
          <RegistrationForm
            registrationStatusOptions={registrationStatusOptions}
            courseUnitsByStudyYear={courseUnitsByStudyYear}
            selectedCourseUnits={selectedCourseUnits}
            setSelectedCourseUnits={setSelectedCourseUnits}
            onClickRegisterButton={onClickAddCoursesButton}
            registeringStudent={registeringStudent}
            enrollWithRetakes={enrollWithRetakes}
          />
        )}
      </Card>
    </div>
  );
}

RetakeEnrollment.defaultProps = {
  selectedRows: [],
};

RetakeEnrollment.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  enrollWithRetakes: PropTypes.bool.isRequired,
  setShowRetakesModal: PropTypes.func.isRequired,
  selectedRows: PropTypes.oneOfType([array]),
};

export default RetakeEnrollment;
