import { Alert, Button, Form, Modal, Space } from 'antd';
import { isEmpty, trim } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AntDInputText } from '../../components/common';
import { authActions, settingActions } from '../../config/actions';

function ChangeContactModal({ bioData, ...props }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { showModal } = useSelector((state) => state.setting);
  const { updating } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isEmpty(bioData)) {
      const { phone, email } = bioData;
      form.setFieldsValue({
        email,
        phone,
      });
    }
  }, [bioData]);

  const handleUpdate = () => {
    form
      .validateFields()
      .then((data) => {
        dispatch(
          authActions.updateBioData({ ...data, phone: trim(data?.phone) })
        );
      })
      .catch(() => toast.error('Email and Tel. Number are required'));
  };
  return (
    <>
      <Modal
        centered
        closable
        keyboard={false}
        maskClosable={false}
        open={showModal}
        title={<div className="text-primary">UPDATE CONTACT DETAILS</div>}
        onCancel={() => dispatch(settingActions.setShowModal(false))}
        footer={null}
        {...props}
      >
        <Alert
          message="Note: Please use your own email and phone number(not for another person), 
              otherwise you will not get messages from the system."
          type="warning"
          showIcon
        />
        <Form
          form={form}
          onFinish={handleUpdate}
          className="my-3"
          labelCol={{ span: 5 }}
          disabled={updating}
        >
          <AntDInputText
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Email is required',
              },
            ]}
          />
          <AntDInputText
            name="phone"
            label="Tel.Number"
            itemAttributes={{
              extra: 'Eg. 256771600008',
            }}
            inputAttributes={{
              count: {
                show: true,
                max: 13,
                strategy: (txt) => txt?.split('').length,
                exceedFormatter: (txt, { max }) =>
                  txt?.split('').slice(0, max).join(''),
              },
            }}
            rules={[
              {
                required: true,
                message: 'Tel. Number is required',
              },
            ]}
          />
          <Space size="small" className="w-100 d-flex justify-content-end">
            <Button
              onClick={() => dispatch(settingActions.setShowModal(false))}
            >
              CANCEL
            </Button>
            <Button htmlType="submit" type="primary" loading={updating}>
              {updating ? 'UPDATING...' : 'UPDATE'}
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
}

ChangeContactModal.defaultProps = {
  bioData: {},
};

ChangeContactModal.propTypes = {
  bioData: PropTypes.oneOfType([object]),
};

export default ChangeContactModal;
