const timetable = {
  GET_PROGRAMME_TEACHING_TIMETABLE_REQUEST:
    'GET_PROGRAMME_TEACHING_TIMETBALE_REQUEST',
  GET_PROGRAMME_TEACHING_TIMETABLE_SUCCESS:
    'GET_PROGRAMME_TEACHING_TIMETBALE_SUCCESS',
  GET_PROGRAMME_TEACHING_TIMETABLE_ERROR:
    'GET_PROGRAMME_TEACHING_TIMETABLE_ERROR',

  DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_REQUEST:
    'DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_REQUEST',
  DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_SUCCESS:
    'DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_SUCCESS',
  DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_ERROR:
    'DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_ERROR',

  getProgrammeTeachingTimetable: (context) => ({
    type: timetable.GET_PROGRAMME_TEACHING_TIMETABLE_REQUEST,
    context,
  }),

  downloadTeachingTimetable: (context) => ({
    type: timetable.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_REQUEST,
    context,
  }),
};

export default timetable;
