const authInitialState = {
  auth: {
    isAuthenticated: false,

    loading: false,
    loginData: {},
    loginError: {},
    updating: false,
    updateError: {},
    updateSuccess: {},
    loggingOut: false,
    changingPassword: false,
    changePasswordSuccess: {},
    changePasswordError: {},
    requestingToken: false,
    requestTokenSuccess: {},
    requestTokenError: {},
    resettingPassword: false,
    resetPasswordSuccess: {},
    resetPasswordError: {},
    logoutData: {},
    logoutError: {},
    authUser: {},
    authUserError: {},
    gettingAuthUser: false,
    fetchUserError: {},
    requesting: false,
    currentStudentProgramme: {},
    studentProgrammes: [],
    activeProgramme: {},
    gettingStudentProgrammes: false,
    studentProgrammeError: {},

    gettingAccountBalance: false,
    accountBalance: 0,
    accountBalanceError: {},

    gettingAdmissionLetter: false,
    admissionLetter: {},
    admissionLetterError: {},

    downloadingAdmissionLetter: false,
    downloadAdmissionLetterError: {},
  },
};

export default authInitialState;
