/* eslint-disable no-undef */
import { SettingFilled, SettingOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  defaultThemeSettings,
  getThemeSettings,
  storeThemeSettings,
} from '../../helpers/themeSetting';
import ThemeSettingModal from '../../containers/MyProfile/ThemeSettingModal';

function ToggleTheme({ darkMode, setDarkMode }) {
  const settings = getThemeSettings();
  const { showThemeModal } = useSelector((state) => state.setting);

  useEffect(() => {
    if (isEmpty(settings)) {
      storeThemeSettings(defaultThemeSettings);
    }
  }, [settings, showThemeModal]);

  useEffect(() => {
    DarkReader.setFetchMethod(window.fetch);
    let isDarkMode = settings?.isDarkMode;

    if (darkMode) {
      DarkReader.enable(settings);
      isDarkMode = true;
    } else {
      DarkReader.disable();
      isDarkMode = false;
    }
    const themeToSave = { ...settings, isDarkMode };

    storeThemeSettings(themeToSave);
  }, [darkMode, settings, showThemeModal]);

  return (
    <>
      <FloatButton
        shape="square"
        type={darkMode ? 'primary' : 'default'}
        icon={darkMode ? <SettingOutlined /> : <SettingFilled />}
        onClick={() => setDarkMode(!darkMode)}
        tooltip={darkMode ? 'LIGHT MODE' : 'DARK MODE'}
      />

      {showThemeModal && (
        <ThemeSettingModal
          showThemeModal={showThemeModal}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
        />
      )}
    </>
  );
}

ToggleTheme.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  setDarkMode: PropTypes.func.isRequired,
};
export default ToggleTheme;
