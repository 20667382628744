import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TabMenu } from '../../../components/common';
import { settingActions } from '../../../config/actions';
import MenuList from '../../../config/routes/MenuList';
import GraduationInvoices from './GraduationInvoices';
import SemesterInvoices from './SemesterInvoices';

function MyInvoices() {
  const dispatch = useDispatch();
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const [currentMenu, setCurrentMenu] = useState('semester-invoices');

  const navigateUser = (route) => {
    const { MyInvoice } = MenuList;
    dispatch(
      settingActions.setNavigation({
        ...appNavigation,
        menuLevel_1: 'payments',
        menuLevel_2: MyInvoice?.label,
        menuLevel_3: route,
      })
    );
  };

  useEffect(() => {
    const { key } = selectedMenu;
    const activeInvoiceTab = currentMenu.split('-').join(' ');
    if (key === 'my-invoices') {
      navigateUser(activeInvoiceTab);
    }
  }, []);

  return (
    <>
      <Card>
        <TabMenu
          menus={[
            {
              title: 'SEMESTER INVOICES',
              action: 'semester-invoices',
            },
            {
              title: 'GRADUATION INVOICES',
              action: 'graduation-invoices',
              hidden: currentStudentProgramme?.on_provisional_list !== true,
            },
          ]}
          navigateUser={navigateUser}
          setCurrentMenu={setCurrentMenu}
          currentMenu={currentMenu}
        />
      </Card>
      <div className="mt-2">
        {currentMenu === 'semester-invoices' && <SemesterInvoices />}
        {currentMenu === 'graduation-invoices' &&
          currentStudentProgramme?.on_provisional_list === true && (
            <GraduationInvoices />
          )}
      </div>
    </>
  );
}

export default MyInvoices;
