const setting = {
  setting: {
    selectedMenu: {},
    appNavigation: {},
    sideMenuTab: 'bio-data',
    showModal: false,
    showEditModal: false,
    showDeleteModal: false,
    showCreateModal: false,
    showPRNModal: false,
    showThemeModal: false,
    showAllocateModal: false,
    isToggled: false,
    showOffsetInvoiceModal: { show: false, invoiceData: {} },
    submenuKeys: [
      'self-enrollment',
      'registration-and-enrollment',
      'payments',
      'my-programmes',
      'services',
    ],
    registrationCardType: 'proofOfRegistration',
  },
};

export default setting;
