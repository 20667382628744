import { isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Card, ModalBody, ModalFooter, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlertMessage,
  CustomModal,
  SubmitButton,
} from '../../../components/common';
import {
  paymentReferenceActions,
  settingActions,
} from '../../../config/actions';

function GraduationPaymentReference({ invoiceData }) {
  const {
    generatingGraduationPRN,
    graduationPaymentReference,
    generateGradPRNError,
  } = useSelector((state) => state.paymentReference);
  const { showPRNModal } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const onClickGeneratePRN = () => {
    dispatch(
      paymentReferenceActions.generateGraduationInvoicePRN(invoiceData.id)
    );
  };

  return (
    <>
      <CustomModal
        show={showPRNModal}
        size="xl"
        onCloseModal={() => dispatch(settingActions.setShowPRNModal(false))}
        backdrop="static"
        keyboard={false}
        title="GENERATE PAYMENT REFERENCE NUMBER"
      >
        <ModalBody className="text-center">
          {isEmpty(graduationPaymentReference) ||
          !isEmpty(generateGradPRNError) ? (
            <>
              {!isEmpty(invoiceData) && (
                <>
                  <AlertMessage
                    type="info"
                    message="Are you sure you want to generate
                   a payment reference number for this invoice ?"
                    className="px-4 text-uppercase"
                  />
                  <Card body className="border-0 p-4">
                    <Table
                      size="lg"
                      responsive
                      bordered
                      striped
                      className="mb-0 font500 text-sm"
                    >
                      <thead>
                        <tr>
                          <td>INVOICE NUMBER</td>
                          <td>INVOICE TYPE</td>
                          <td>AMOUNT BILLED</td>
                          <td>AMOUNT PAID</td>
                          <td>AMOUNT DUE</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{`${invoiceData.invoice_number}`}</td>
                          <td>{`${invoiceData.invoice_type}`}</td>
                          <td className="text-primary">
                            {`${invoiceData?.fees_elements[0]?.currency || ''}
                          ${parseInt(
                            invoiceData.invoice_amount,
                            10
                          ).toLocaleString()}`}
                          </td>
                          <td className="text-success">
                            {parseInt(
                              invoiceData.amount_paid,
                              10
                            ).toLocaleString()}
                          </td>
                          <td className="text-danger">
                            {`${invoiceData?.fees_elements[0]?.currency || ''}
                          ${parseInt(
                            invoiceData.amount_due,
                            10
                          ).toLocaleString()}`}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </ModalBody>
        <ModalFooter className="py-2 bg-light border-top">
          <SubmitButton
            size="small"
            loading={generatingGraduationPRN}
            text="Generate PRN"
            loadingText="Generating..."
            className="p-2 fw-bold text-uppercase"
            onClick={onClickGeneratePRN}
          />
        </ModalFooter>
      </CustomModal>
    </>
  );
}

GraduationPaymentReference.propTypes = {
  invoiceData: PropTypes.oneOfType([object]).isRequired,
};

export default GraduationPaymentReference;
